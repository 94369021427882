import { makeStyles } from '@material-ui/styles';
import gobrax from 'themes/gobrax';
import colors from '../../themes/gobrax'

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.h4.fontSize,
    color: gobrax.palette.text.title2,
    marginBottom: '8px'
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '16px',
    color: gobrax.palette.text.title2,
    marginBottom: '16px'
  },
  boxInput: {
    width: '48%',
    position: 'relative',
    minWidth: '224px',
    '& .MuiInput-underline:before': {
      border: 'none !important'
    },
    '& .MuiInput-underline:after': {
      border: 'none !important'
    },
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingRight: '0'
    },
    '& .MuiSelect-icon': {
      display: 'none'
    },
    '& .MuiMenu-paper': {
      top: '300px'
    },
    "& #custom-menu-item": {
      top: '50px',
      left: '20px'
    },
  },
  calendarIcon: {
    position: 'absolute',
    zIndex: 2,
    color: gobrax.palette.text.title2,
    top: '25%',
    left: '15px',
    cursor: 'pointer',
    marginTop: '2px'
  },
  divInput: {
    width: '100%'
  },
  arrowIcon: {
    position: 'absolute',
    right: '11px',
    display: 'flex',
    top: '12px',
    zIndex: 2,
    backgroundColor: gobrax.palette.white,
    color: '#CCCCCC',
    cursor: 'pointer',
    alignItems: 'center',
    "& hr": {
      width: '20px',
      borderTop: '0.5px solid #CCCCCC',
      transform: 'rotate(90deg)',
      marginRight: '-5px'
    }
  },
  inputCalendar: {
    fontSize: theme.typography.h4.fontSize,
    color: gobrax.palette.text.title2,
    height: '48px',
    width: '100%',
    '& .MuiInputBase-input': {
      textAlign: 'left',
      borderRadius: '32px',
      padding: '15px 15px 15px 45px',
      backgroundColor: 'white',
      fontWeight: 400,
      fontSize: '16px'
    },
    '&:before': {
      transition: 'none',
      border: 'transparent'
    },
    '&:after': {
      transition: 'none',
      border: 'transparent'
    },
    '& input': {
      cursor: 'pointer',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'transparent'
    }
  },
  notificationCollapseSubTitle: {
    fontSize: 16,
    color: colors.palette.text.title,
    display: 'flex',
    maxWidth: '220px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    flexWrap: 'inherit',
    margin: '15px 0 10px'
  },
  logoHeader: {
    width: '40px',
    marginLeft: '3px'
  },
  buttonNew: {
    padding: '8px 32px', 
    borderRadius: '32px', 
    backgroundColor: gobrax.palette.secondary.main, 
    textTransform: 'none', 
    fontWeight: 'bold', 
    fontSize: '16px',
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  searchBox: {
    borderRadius: 32,
    width: "100%",
    height: 'auto',
    margin: "0 auto",
    padding: "12px 24px",
  },
  btCreateNotification: { 
    height: 56,
    width: '100%',
    borderRadius: '32px',
    borderColor: gobrax.palette.secondary.main,
    fontSize: 18,
    fontWeight: 'bold',
    color: gobrax.palette.text.title2
  }
}))