import React from "react";
import PropTypes from "prop-types";
import "./statusEmpty.css";

const StatusEmpty = ({ title, description }) => {
  return (
    <div className={`StatusEmpty-container`}>
      <span className={`StatusEmpty-title`}>{title}</span>
      <span className={`StatusEmpty-description`}>{description}</span>
    </div>
  );
};

export default StatusEmpty;

StatusEmpty.propTypes = {
  /**
   * Titulo
   */
  title: PropTypes.string.isRequired,
  /**
   * Descrição
   */
  description: PropTypes.string.isRequired,
};

StatusEmpty.defaultProps = {
  props: null,
};
