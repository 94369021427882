import { useRef, useState } from "react";
import Supercluster from "supercluster";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { dequal } from "dequal";

function useCluster({ points, bounds, zoom, options }) {
  var superclusterRef = useRef();
  var pointsRef = useRef();

  var _useState = useState([]),
    clusters = _useState[0],
    setClusters = _useState[1];

  var zoomInt = Math.round(zoom);
  useDeepCompareEffectNoCheck(
    function () {
      if (
        !superclusterRef.current ||
        !dequal(pointsRef.current, points) ||
        !dequal(superclusterRef.current.options, options)
      ) {
        superclusterRef.current = new Supercluster(options);
        superclusterRef.current.load(points);
      }

      if (bounds) {
        setClusters(superclusterRef.current.getClusters(bounds, zoomInt));
      }

      pointsRef.current = points;
    },
    [points, bounds, zoomInt, options],
  );
  return {
    clusters: clusters,
    supercluster: superclusterRef.current,
  };
}

export default useCluster;
