import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowSize } from "../../hooks/useWindowsSize";

const DieselControlSkeleton = () => {
  const classes = useStyles();
  const size = useWindowSize();
  return (
    <Aux>
      <Grid container spacing={2}>
        <Grid
          item
          xl={size.grid12}
          lg={size.grid12}
          md={size.grid12}
          sm={size.grid12}
          xs={size.grid12}
        >
          <Skeleton variant={"rect"} height={40} className={classes.bordered} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid6}
          xs={size.grid6}
        >
          <Skeleton
            variant={"rect"}
            height={158}
            className={classes.bordered}
          />
        </Grid>
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid6}
          xs={size.grid6}
        >
          <Skeleton
            variant={"rect"}
            height={158}
            className={classes.bordered}
          />
        </Grid>
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid6}
          xs={size.grid6}
        >
          <Skeleton
            variant={"rect"}
            height={158}
            className={classes.bordered}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton variant="rect" height={500} className={classes.bordered} />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles((theme) => ({
  bordered: {
    borderRadius: "22px",
    margin: '5px 0px 5px 0px',
  },
}));

export default DieselControlSkeleton;
