import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Grid, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import TableResponsive from "../../../components/TableResponsive";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";

//services
import {
  getVehicles,
  unassignDriverFromVehicle,
} from "services/vehicle";

// components
import Aux from "hoc/auxiliar";
import LinkDriversSkeleton from "components/Skeletons/LinkDriversSkeleton";
import ModalUnassignDriverToVehicle from "components/ModalUnassignDriverToVehicle";
import PageToolbar from "components/PageToolbar";
import Widget from "components/Widget";
import NotFoundWithLottie from "components/NotFoundWithLottie";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { useAppSelector } from "redux/store";
import { useWindowSize } from "../../../hooks/useWindowsSize";
import { History, LinkOff } from "@material-ui/icons";
import { handleAmplitudeEvent } from "services/amplitude";

export default function LinkDrivers() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const size = useWindowSize()
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [rowData, setRowData] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [download, setDownload] = useState({
    link: "",
    fileName: ""
  });

  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const handleUnlink = (params) => {
    handleAmplitudeEvent('Unlink Driver Clicked');
    setRowData(params);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => setConfirmOpen(false);
  const handleHistoric = (params) => {
    handleAmplitudeEvent('Log Button Clicked');
    history.push({
      pathname: "/gofurther/maintenance/historicLinkDrivers",
      state: {
        params: params
      },
    });
  }

  const columns = makeColumns({
    handleHistory: handleHistoric,
    handleUnlink: handleUnlink,
    size
  });


  const fetchVehicles = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getVehicles(currentCustomer);
      if (response.status === 200 || response.status === 201) {
        setVehicles(response.data.customers)
      } else {
        toast.error('Um erro inexperado aocnteceu. Contate o suporte.')
      }
    } catch (error) {
      toast.error('Erro ao buscar veículos. Contate o suporte.')
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])


  const handleUnassignDriverToVehicle = async (data) => {
    try {
      setLoading(true)
      const response = await unassignDriverFromVehicle(data);
      if (response.status === 200) {
        toast.success("Desvinculado com sucesso!");
        fetchVehicles(currentCustomer);
        setRowData({});
        setSelectedRowData();
        setId();
        handleConfirmClose();
      } else {
        toast.error("Um erro inexperado aconteceu. Verifique com o suporte");
      }
    } catch (error) {
      toast.error("Erro ao desvincular veículo. Verifique com o suporte");
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_linkdrivers'] })) {
      fetchVehicles()
      setDownload({
        link: `/vehicles/exportVehiclesDrivers?customers=${currentCustomer}`,
        fileName: `vinculo-veiculo-motorista_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`
      });
    }
  }, [currentCustomer, fetchVehicles]);

  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);
    const formatData = [
      data[0].props.children,
      data[1].props.children === "Sem motorista vinculado" ? null : vehicles[data2.dataIndex].currentDriver,
      data[2],
      data[3].props ? null : vehicles[data2.dataIndex].currentDriver,
      vehicles[data2.dataIndex].id,
    ]
    setSelectedRowData(formatData)
  }

  if (loading) return <LinkDriversSkeleton />
  if (!loading && (!vehicles && !vehicles?.customers)) {
    return (
      <NotFoundWithLottie
        description={'Não foi encontrado vínculo para essa empresa.'}
        strongDescription={''}
      />
    )
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_linkdrivers']}>
        <ModalUnassignDriverToVehicle
          confirmOpen={confirmOpen}
          handleConfirmClose={handleConfirmClose}
          handleUnassignDriverToVehicle={handleUnassignDriverToVehicle}
          data={rowData}
        />
        <PageToolbar
          menuBreadcrumb
          onlyBreadcrumb
          breadcrumb="Manutenção"
          title="Vínculo de Motorista"
        />
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Widget disableWidgetMenu title="Vínculo veículo/motorista">
                <Grid item xl={12} xs={12} className={classes.table}>
                  <TableResponsive
                    pointer={true}
                    columns={columns}
                    data={vehicles}
                    download={download}
                    tableName="link-drivers"
                    setRowsPerPage={setRowsPerPage}
                    options={{
                      onRowClick: handleSelectRow,
                      rowsPerPage: rowsPerPage,
                      setRowProps: (row, index) => {
                        return index===id? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
                    }}
                  />
                  {/* botoes */}
                  {size.mobile && <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" spacing={1}>
                      <PermissionsGate scopes={['can_view_linkdrivershistory']}>
                        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                          <Box >
                            <Button
                              fullWidth
                              variant="contained"
                              color={'secondary'}
                              onClick={()=>handleHistoric(selectedRowData)}
                              disabled={!selectedRowData}
                              className={classes.buttonDetail}
                            >
                              Historico
                            </Button>
                          </Box>
                        </Grid>
                      </PermissionsGate>
                      <PermissionsGate scopes={['can_edit_linkdrivers']}>
                        {selectedRowData && !selectedRowData[1] ? null : 
                          <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                            <Box >
                              <Button
                                fullWidth
                                variant="contained"
                                color={'secondary'}
                                onClick={()=>handleUnlink(selectedRowData)}
                                disabled={!selectedRowData}
                                className={classes.buttonDetail}
                              >
                                Desvincular
                              </Button>
                            </Box>
                          </Grid>}
                      </PermissionsGate>
                    </Grid>
                  </Grid>}
                </Grid>
              </Widget>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
