import { makeStyles } from "@material-ui/styles";
import {alpha} from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  bgVideo: {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -10,
  },
  title1:{
    color: '#282828',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  title2:{
    color: '#595959',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '0.8rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  title3:{
    color: '#181714',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  loginForm: {
    borderRadius: '28px',
  },
  paper: {
    margin: theme.spacing(4, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logotype: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  forgotPass: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  logotypeIcon: {
    width: 230,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    height: '3rem',
    marginTop:'1rem',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '2rem',
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  cancel: {
    margin: theme.spacing(1, 0, 1, 3),
  },
  csrf: {
    visibility: 'hidden'
  },
  submit2: {
    height: '3rem',
    marginTop:'1rem',
    backgroundColor: 'transparent',
    borderRadius: '2rem',
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      fontWeight: 600,
    },
  },
}));
