import {
  mdiAccountLock,
  mdiAccountMultiple,
  mdiClipboardAccount,
  mdiContactlessPayment,
  mdiDotsGrid,
  mdiFactory,
  mdiTruckCheck,
  mdiTruckOutline,
  mdiPokeball,
  mdiCellphoneLock,
  mdiShieldLockOpenOutline
} from "@mdi/js";
import { hasPermission, getUserPermissions } from "components/PermissionsGate";

export function getMenuStructureAdmin() {
  const permissions = getUserPermissions();
  if (!permissions) {
    return [];
  }

  const adminMenu = [{
    id: "Customers",
    isMenuAdministrative: true,
    label: "Clientes",
    permission: "can_view_customers",
    link: "/gofurther/customer/customer-list",
    icon: mdiFactory,
  },
  {
    id: "Drivers",
    isMenuAdministrative: true,
    label: "Motoristas",
    permission: "can_view_drivers",
    link: "/gofurther/driver/all-drivers",
    icon: mdiClipboardAccount,
  },
  {
    id: "Vehicles",
    isMenuAdministrative: true,
    label: "Veículos",
    permission: "can_view_vehicles",
    link: "/gofurther/vehicle/list-vehicles",
    icon: mdiTruckCheck,
  },
  {
    id: "Profile",
    isMenuAdministrative: true,
    label: "Perfis",
    permission: "can_view_profiles",
    link: "/gofurther/profile/list-profiles",
    icon: mdiTruckOutline,
  },
  {
    id: "Users",
    isMenuAdministrative: true,
    label: "Usuários",
    permission: "can_view_users",
    link: "/gofurther/user/list-users",
    icon: mdiAccountMultiple,
  },
  {
    id: "Devices",
    isMenuAdministrative: true,
    label: "Unidades eletrônicas",
    permission: "can_view_devices",
    link: "/gofurther/device/list-devices",
    icon: mdiContactlessPayment,
  },
  {
    id: "RewardGroup",
    isMenuAdministrative: true,
    label: "Grupo de bonificação",
    permission: "can_view_rewardgroup",
    link: "/gofurther/rewardGroup/list-rewardGroup",
    icon: mdiDotsGrid,
  },
  {
    id: "TelemetryData",
    isMenuAdministrative: true,
    label: "Conectividade",
    permission: "can_view_telemetrydata",
    link: "/gofurther/telemetrydata",
    icon: mdiPokeball,
  }];

  const profileMenu = [{
    permission: "can_view_user_role",
    id: "Roles",
    isMenuAdministrative: true,
    label: "Perfis",
    link: "/gofurther/role/list-roles",
    icon: mdiShieldLockOpenOutline,
    isMain: true,
  }, {
    permission: "can_view_user_role",
    id: "UserRole",
    isMenuAdministrative: true,
    label: "Perfis da web",
    link: "/gofurther/role/list-roles",
    icon: mdiAccountLock,
  },
  {
    permission: "can_view_driver_role",
    id: "DriverRole",
    isMenuAdministrative: true,
    label: "Perfis do app",
    link: "/gofurther/role/list-roles-app",
    icon: mdiCellphoneLock,
  },]

  const countPermissions = (permissionsArray) => {
    let count = 0;
    permissions.forEach((scope) => {
      if (permissionsArray.includes(scope.permission)) {
        count++;
      }
    });
    return count;
  };

  const getChildrenMenu = (item) => {
    return {
      id: item.id,
      isMenuAdministrative: true,
      label: item.label,
      link: item.link,
      icon: item.icon,
      isChildren: true
    }
  }

  const getMainMenu = (item) => {
    return {
      id: item.id,
      isMenuAdministrative: true,
      label: item.label,
      link: item.link,
      icon: item.icon
    }
  }

  let structure = []

  adminMenu.forEach(item => {
    if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
      structure.push(getMainMenu(item))
    }
  })

  const profilePermissions = profileMenu.map(obj => obj.permission);

  if (hasPermission({ scopes: profilePermissions })) {
    const total = countPermissions(profilePermissions);
    // menu com filhos
    if (total > 1) {
      let structureWithChildren = []
      profileMenu.forEach(item => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structureWithChildren.push(getChildrenMenu(item))
        }
      })
      let mainItem = profileMenu.filter(elm => elm.isMain)[0];
      mainItem.children = structureWithChildren;
      structure.push(mainItem)
    } else {
      // menu sem filhos
      profileMenu.forEach(item => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structure.push(getMainMenu(item))
        }
      })
    }
  }

  return structure
}