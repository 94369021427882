import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core";

export default makeStyles(theme => ({
  table: {
    paddingTop: theme.spacing(2)
  },
  textBold: {
    fontWeight: 'bold'
  },
  textInfo: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  },
  containerInfobox: {
    height: 450, 
    display: 'flex', 
    alignItems: 'center',
  },
  paper: {
    borderRadius: 20,
    padding: theme.spacing(1),
    maxWidth: "850px",
    margin: "0 auto",
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
    marginBottom: 32,
    marginLeft: 32,
  },
  okButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
}));
