import React from "react";
import {
  Grid, 
  Box, 
  Typography,
  Button,
} from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// components
import DeviceForm from "components/DeviceForm";
import BackButton from "components/BackButton";
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formDeviceInitialValues";
import schemaValidation from "domain/validation/yup/formDeviceValidation";

// services
import { createDevice } from "services/device";

// data
import DeviceModel from "models/Device";

// styles
import useStyles from "./styles";

const AddDevice = () => {
  const classes = useStyles();
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const normalizeSimcard = `+55${data.simcard}`;
    const deviceModel = new DeviceModel(
      data.identification,
      data.brand.value,
      normalizeSimcard,
      data.status.value
    );
    const response = await createDevice(deviceModel);
    if (response.status === 200) {
      toast.success("Unidade eletrônica criada com sucesso!");
      methods.reset(defaultValues);
    } else {
      toast.error("Erro ao criar unidade eletrônica. Verifique com o suporte");
    }
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_devices']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12} className={classes.table}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Cadastro de unidade eletrônica</Typography>
                    <DeviceForm />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <BackButton className={classes.backButton} />
                          </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Button
                              fullWidth
                              variant="contained"
                              type="submit"
                              className={classes.btConfirm}
                            >
                              Salvar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                  </form>
                </FormProvider>
                <Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddDevice;