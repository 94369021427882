import React from 'react';

import { CardValue } from '../../atoms/cardValue/cardValue';
import { CardTitle } from '../../atoms/cardTitle/cardTitle';
import { CardDescription } from '../../atoms/cardDescription/cardDescription';
import './card.css';

const Card = ({ props, value, title, description, dataCy, style, valueStyle, titleStyle, cardDescriptionStyle }) => {
  return (
    <div
      className={`Card-container`}
      {...props}
      data-cy={dataCy}
      style={style}
    >
      <CardValue value={value} valueStyle={valueStyle} />
      <CardTitle title={title} titleStyle={titleStyle} />
      <CardDescription description={description} cardDescriptionStyle={cardDescriptionStyle} />
    </div>
  );
};

export default Card;

Card.defaultProps = {
  value: 0,
  title: '',
  description: '',
  dataCy: 'card'
};
