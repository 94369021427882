import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import AppProvider from "./context";
import { QueryClientProvider, QueryClient } from "react-query";
import { ptBR } from "date-fns/locale";
import { store, persistor } from "redux/store";
import * as amplitude from "@amplitude/analytics-browser";

require("dotenv").config({ debug: process.env.DEBUG });

const appRoot = document.getElementById(`root`);
appRoot.setAttribute(`notranslate`, true);

amplitude.init(process.env.REACT_APP_LINK_API_AMPLITUDE);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 2,
      staleTime: 5 * 60 * 1000,
    },
  },
});

export function ProviderClient(props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                {props.children}
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </AppProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(
  <ProviderClient>
    <App />
  </ProviderClient>,
  appRoot,
);

serviceWorker.unregister();
