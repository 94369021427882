import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  iconButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    color: theme.palette.grey[500],
    fill: '#FFFEFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.20)',
    width: 16,
    height: 16
  },
}));
