import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import ModalDevice from "components/ModalDevice";
import VehicleForm from "components/VehicleForm";
import PermissionsGate from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formVehicleInitialValues";
import schemaValidation from "domain/validation/yup/formVehicleValidation";

// services
import {
  createVehicle,
  getVehicleDeviceHistory,
  linkVehicleOperation,
} from "services/vehicle";

// data
import VehicleModel from "models/Vehicle";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const AddVehicle = () => {
  const [open, setOpen] = useState(false);
  const [currentDevice,] = useState({});
  const [vehicleId, setVehicleId] = useState();
  const [vehiclesDevices, setVehiclesDevices] = useState();
  const classes = useStyles();
  const theme = useTheme();

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const confirmAlertConfig = (title, text, confirmBt) => {
    return {
      title: title,
      text: text,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: confirmBt,
    };
  };

  const onSubmit = async (data) => {
    const alertConfig = confirmAlertConfig(
      "",
      "Gostaria de vincular uma unidade eletrônica a esse veículo?",
      "Sim, vincular!",
    );
    const vehicleModel = new VehicleModel(
      data.customer.value,
      data.operation.value,
      data.identification,
      data.plate,
      data.fueltype.value || null,
      data.wheeldriver.value || null,
      data.odomType.value,
      data.initialKm || 0,
      data.maxFuelCapacity,
      data.maxAcceleration,
      data.chassi || null,
      data.distanceCalcMethod.value,
      data.fuelCalcMethod.value,
      data.accelerationIdentificationMethod.value,
      data.hasEcoRoll,
      data.hasDafEcoRoll,
      data.maxSpeedAllowed,
      data.maxSpeedAllowedWithRain,
      data.leveragePeak,
      data.brand.value,
      data.model.value,
      data.observation,
      data.motor,
      data.truckModel,
      Number(data.fabricationYear)
    );
    try {
      const response = await createVehicle(vehicleModel);
      if (response.status === 200 && !response?.data?.err) {
        setVehicleId(response.data.id);
        toast.success("Veículo criado com sucesso!");
        if (data.operation.value) {
          const linkResponse = await linkVehicleOperation(data.operation.value, {
            vehicleId: response.data.id,
          });
          if (linkResponse.status !== 200) {
            toast.error(
              "Erro ao vincular Veículo com Operação. Verifique com o suporte",
            );
          } else {
            methods.reset(defaultValues);
          }
        } else {
          methods.reset(defaultValues);
        }
        Swal.fire(alertConfig).then((result) => {
          if (result.isConfirmed) {
            handleLinkDevices(response.data.id);
            setOpen(true);
          }
        });
      } else {
        throw new Error(response.err);
      }
    } catch (error) {
      toast.error("Erro ao criar Veículo. Verifique com o suporte");
    }
  };
  const handleCloseModal = () => setOpen(false);

  const handleLinkDevices = async (id) => {
    try {
      const response = await getVehicleDeviceHistory(id);
      if (!response) {
        toast.error(
          "Você não possui histórico ainda. Entre em contato com o suporte.",
        );
        setVehiclesDevices();
        return;
      }
      if (!response.data.data.vehiclesDevices) {
        setVehiclesDevices();
      } else {
        const newResponse = response.data.data.vehiclesDevices.map((devices) => {
          let id = devices.deviceId;
          return { ...devices, id };
        });
        setVehiclesDevices(newResponse);
      }
    } catch (error) {
      toast.error(
        "Você não possui histórico ainda. Entre em contato com o suporte.",
      );
      return;
    }
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_vehicles']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Cadastro de veículo</Typography>
                    <VehicleForm />
                    <Grid justifyContent="flex-end" container spacing={2}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                              >
                                Salvar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                  <ModalDevice
                    device={currentDevice}
                    open={open}
                    handleClose={handleCloseModal}
                    data={vehicleId}
                    vehiclesDevices={vehiclesDevices}
                    tableName="modal-add-vehicle"
                  />
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddVehicle;
