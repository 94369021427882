import { create } from "apisauce";
import { clearCustomerProfile } from "../redux/features/customerProfileSlice";
import { logout } from "../redux/globalSlice";
import { store } from "../redux/store";

const baseURL = `${process.env.REACT_APP_LINK_API}`;
const baseURLWS = `${process.env.REACT_APP_LINK_API_WS}`;

// define the api
const api = create({
  baseURL: baseURL,
  timeout: 240000,
});

export const apiExt = create({
  baseURL: baseURL,
  timeout: 240000,
});

export const apiWS = create({
  baseURL: baseURLWS,
  timeout: 240000,
});

api.axiosInstance.interceptors.request.use(
  (config) => {
    const { token, email } = store.getState().global.user;
    config.headers.OriginVersion = "WEB 3.0";

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.method !== 'get') {
      if (!config.data) {
        config.data = {};
      }

      config.data.sourceId = 1;

      if (email) {
        config.data.execEmail = email;
      }
    }
    return config;
  },
  (error) => {
    console.log("error", error);
    Promise.reject(error);
  },
);

api.axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this  to => trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response.data.code === 401) {
      localStorage.clear();
      store.dispatch(logout());
      store.dispatch(clearCustomerProfile());
      window.location.href = `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/browser/flows/logout`;
    }
    return Promise.reject(error);
  },
);

api.addResponseTransform((response) => {
  if (!response.ok) {
    throw response;
  }
});

export default api;
