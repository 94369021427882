import React, { useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Grid,
  Paper,
  Divider,
} from "@material-ui/core";
import Select from "react-select";
import {
  Business as BusinessIcon,
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  ExitToAppOutlined,
  Settings,
} from "@material-ui/icons";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import classNames from "classnames";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

// services
import { getCustomersById } from "../../services/customers";

// components
import { Typography } from "../Wrappers";
import PermissionsGate from "components/PermissionsGate";
import NotificationCollapse from "./components/notification-collapse";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useWindowSize } from "hooks/useWindowsSize";
import { hasPermission } from "components/PermissionsGate";

// styles
import useStyles from "./styles";
import { selectStyles } from "./selectStyles";
import GoBraxLogo from "../../images/gobrax-header.png";
import { useTheme } from "@material-ui/styles";
import { clearCustomerProfile } from "redux/features/customerProfileSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { logout, setGlobalUser } from "redux/globalSlice";
import { clearBonusFilters } from "redux/features/bonusFollowSlice";
import { clearVehicleFilters } from "redux/features/vehicleDashboardSlice";
import { handleAmplitudeEvent } from "services/amplitude";

export default function Header() {
  const { currentCustomer, listCustomers, notifications } = useAppSelector(
    (state) => state.global.user,
  );
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [customersOptions, setCustomersOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profileMenu, setProfileMenu] = useState(null);
  const [open, setOpen] = useState(false);

  const login_redirect = localStorage.getItem("login_redirect");
  const url =
    login_redirect && login_redirect !== "/bonus/follow"
      ? `/${login_redirect}`
      : "/";

  const size = useWindowSize();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const pathArray = window.location.pathname.split('/');

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChangeCustomer = (newCustomer) => {
    handleAmplitudeEvent('Selected Company Updated', { type: 'updated', lastCompanyId: selectedCustomer[0].value, lastCompanyLabel: selectedCustomer[0].label, newCompanyId: newCustomer.value, newCompanyLabel: newCustomer.label, description: 'User changed the company' });
    setIsLoading(true);
    dispatch(clearBonusFilters());
    dispatch(clearVehicleFilters());
    dispatch(setGlobalUser({ currentCustomer: newCustomer.value }));
    localStorage.setItem("currentCustomer", newCustomer.value);
    history.push("/gofurther/bonus/follow");
    setIsLoading(false);
  };

  const handleStorageChange = useCallback(
    (event) => {
      if (event.key === "currentCustomer") {
        history.push("/gofurther/bonus/follow");
      }
    },
    [history],
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [handleStorageChange]);

  const fetchCustomerById = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getCustomersById(listCustomers);
      if (response.status === 200 || response.status === 201) {
        const ordered = response.data.customers.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        );
        let optionsCustomersOrdered = ordered.map((customer) => {
          return {
            color: theme.palette.scrollBar,
            label: customer.name,
            value: customer.id,
          };
        });
        setCustomersOptions(optionsCustomersOrdered);
      }
    } catch (error) {
      toast.error("Algo inexperado aconteceu. Contate o suporte.");
    } finally {
      setIsLoading(false);
    }
  }, [listCustomers, theme]);

  const fetchCustomer = useCallback(async () => {
    try {
      setIsLoading(true);
      if (currentCustomer && listCustomers.length > 0) {
        const filteredCustomer = customersOptions.filter(
          (res) => Number(res.value) === Number(currentCustomer),
        );
        setSelectedCustomer(filteredCustomer);
      }
    } catch (error) {
      toast.error("Algo inexperado aconteceu. Contate o suporte.");
    } finally {
      setIsLoading(false);
    }
  }, [currentCustomer, customersOptions, listCustomers]);

  useEffect(() => {
    listCustomers && fetchCustomerById();
  }, [listCustomers, fetchCustomerById]);

  useEffect(() => {
    currentCustomer && fetchCustomer();
  }, [currentCustomer, fetchCustomer]);

  const SelectCompany = () => {
    // desabilita o select de empresas na página de conectividade quando tem a permissão can_view_telemetrydata_all
    const disableSelectOnTelemetry = pathArray.includes('telemetrydata') && hasPermission({ scopes: ['can_view_telemetrydata_all'] })
    return (
      <PermissionsGate scopes={["can_select_customers"]}>
        <Grid
          item
          xl={size.grid10}
          lg={size.grid10}
          md={size.grid10}
          sm={size.grid10}
          xs={size.grid10}
        >
          <Paper elevation={0} className={classes.paper}>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={2} className={classes.businessIcon}>
                <BusinessIcon />
              </Grid>
              <Grid item xs={10}>
                <h1 className={classes.titleABC}>Selecione a empresa</h1>
                <Select
                  className={classes.formControl}
                  placeholder="Digite o nome da empresa"
                  menuPortalTarget={document.body}
                  styles={{
                    ...selectStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={selectedCustomer}
                  isLoading={isLoading}
                  onChange={(e) => handleChangeCustomer(e)}
                  isSearchable={!size.mobile}
                  name="list-customers"
                  options={customersOptions}
                  isDisabled={disableSelectOnTelemetry}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </PermissionsGate>
    )
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item style={{ alignSelf: "center" }}>
              <IconButton
                color="inherit"
                onClick={() => toggleSidebar(layoutDispatch)}
                className={classNames(
                  classes.headerMenuButtonSandwich,
                  classes.headerMenuButtonCollapse,
                )}
                data-cy="iconButtonSiderBar"
              >
                {layoutState.isSidebarOpened ? (
                  <ArrowBackIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse,
                      ),
                    }}
                    data-cy="iconOpen"
                  />
                ) : (
                  <MenuIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse,
                      ),
                    }}
                    data-cy="iconClose"
                  />
                )}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => handleAmplitudeEvent('Logo Header Clicked', {type: 'click', description: 'User clicked in logo header' })}
                component={Link}
                to={url}
                className={classes.logoIconButton}
              >
                <img
                  src={GoBraxLogo}
                  alt="Logo Gobrax"
                  className={classes.logoHeader}
                  data-cy="logoGobrax"
                />
              </IconButton>
            </Grid>
            <Grid
              item
              style={{
                alignSelf: "center",
                minWidth: size.mobile ? "1vh" : "45vh",
              }}
            >
              <Grid
                container
                style={{
                  flexWrap: 'inherit'
                }}>
                {size.mobile ? null : <SelectCompany />}
                <PermissionsGate scopes={["can_view_notifications"]}>
                  <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.headerMenuButton}
                    aria-controls="profile-notifications"
                    onClick={() => {
                      handleToggle();
                      handleAmplitudeEvent('Notifications Header Button Clicked')
                    }}
                    data-cy="profileNotifications"
                  >
                    <NotificationsNoneIcon classes={{ root: classes.notificationIcon }} />
                    <Paper
                      style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        width: notifications?.newsNotifications?.length > 0 ? 15 : 0,
                        height: notifications?.newsNotifications?.length > 0 ? 15 : 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" style={{ color: 'white' }}>
                        {notifications?.newsNotifications?.length}
                      </Typography>
                    </Paper>
                  </IconButton>
                </PermissionsGate>
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  className={classes.headerMenuButton}
                  aria-controls="profile-menu"
                  onClick={(e) => setProfileMenu(e.currentTarget)}
                  data-cy="profileMenu"
                >
                  {size.mobile ? (
                    <Settings classes={{ root: classes.headerIcon }} />
                  ) : (
                    <AccountIcon classes={{ root: classes.headerIcon }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
          style={{ overflowX: "hidden", overflowY: "hidden" }}
        >
          {size.mobile ? <SelectCompany /> : null}
          <PermissionsGate scopes={["can_view_change_password"]}>
            <Divider style={{ marginTop: 20 }} />
            <div
              className={classes.profileMenuUser}
              data-cy="divChangePasswordButton"
            >
              <Typography
                className={classes.profileMenuLink}
                onClick={() => {
                  history.push("/gofurther/user/changePassword");
                  handleAmplitudeEvent('Change Password Clicked')
                }}
                data-cy="changePasswordButton"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 25"
                  fill="none"
                  style={{ marginRight: 5 }}
                >
                  <g clipPath="url(#clip0_701_175)">
                    <path
                      d="M18 8.5H20C20.2652 8.5 20.5196 8.60536 20.7071 8.79289C20.8946 8.98043 21 9.23478 21 9.5V21.5C21 21.7652 20.8946 22.0196 20.7071 22.2071C20.5196 22.3946 20.2652 22.5 20 22.5H4C3.73478 22.5 3.48043 22.3946 3.29289 22.2071C3.10536 22.0196 3 21.7652 3 21.5V9.5C3 9.23478 3.10536 8.98043 3.29289 8.79289C3.48043 8.60536 3.73478 8.5 4 8.5H6V7.5C6 5.9087 6.63214 4.38258 7.75736 3.25736C8.88258 2.13214 10.4087 1.5 12 1.5C13.5913 1.5 15.1174 2.13214 16.2426 3.25736C17.3679 4.38258 18 5.9087 18 7.5V8.5ZM5 10.5V20.5H19V10.5H5ZM11 14.5H13V16.5H11V14.5ZM7 14.5H9V16.5H7V14.5ZM15 14.5H17V16.5H15V14.5ZM16 8.5V7.5C16 6.43913 15.5786 5.42172 14.8284 4.67157C14.0783 3.92143 13.0609 3.5 12 3.5C10.9391 3.5 9.92172 3.92143 9.17157 4.67157C8.42143 5.42172 8 6.43913 8 7.5V8.5H16Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_701_175">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Alterar dados de acesso
              </Typography>
            </div>
          </PermissionsGate>
          <Divider style={{ marginTop: 5 }} />
          <div className={classes.profileMenuUser} data-cy="divLogoutButton">
            <Typography
              className={classes.profileMenuLink}
              onClick={() => {
                localStorage.clear();
                window.location.href = `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/browser/flows/logout`;
                dispatch(logout());
                dispatch(clearCustomerProfile());
                handleAmplitudeEvent('Logout')
              }}
              data-cy="logoutButton"
            >
              <ExitToAppOutlined style={{ marginRight: 5 }} /> Sair
            </Typography>
          </div>
        </Menu>
        <NotificationCollapse open={open} handleToggle={handleToggle} notificationsToShow={notifications?.newsNotifications} />
      </Toolbar>
    </AppBar>
  );
}
