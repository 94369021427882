import React from "react";
import { Grid, Button, Tooltip, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { valueToFloat, valueToPositiveFloat } from "helpers/functions";
import { format } from "date-fns";
import classNames from "classnames";
import makeStyles from "./styles";
import { handleAmplitudeEvent } from "services/amplitude";

export const makeColumns = (size, showDetails) => {
  const classes = makeStyles();
  return [
    {
      name: "event",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              className={classNames(
                classes.statusText,
                {
                  [classes.danger]: (value !== "Fill"),
                  [classes.success]: (value === "Fill")
                }
              )}
              noWrap={true}
            >
              {value !== "Fill" ? "Inconsistência" : "Abastecimento"}
            </Typography>
          );
        },
      },
    },
    {
      name: "identification",
      label: "Veículo",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "date",
      label: "Data/hora",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {format(new Date(value), "dd/MM/yy HH:mm")}
            </Typography>
          );
        },
      },
    },
    {
      name: "odometer",
      label: "Odômetro",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "previousVolume",
      label: "Volume anterior",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {valueToFloat(value?.value, 2)} litros ({valueToFloat(value?.percentage, 2)}%)
            </Typography>
          );
        },
      },
    },
    {
      name: "differenceVolume",
      label: "Diferença de litros",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              className={classNames(
                {
                  [classes.danger]: (value?.percentage < 0)
                }
              )}
              noWrap={true}>
              {valueToFloat(value?.value, 2)} litros ({valueToFloat(value?.percentage, 2)}%)
            </Typography>
          );
        },
      },
    },
    {
      name: "actualVolume",
      label: "Novo volume",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (
              <Typography noWrap={true}>
                {valueToFloat(value?.value, 2)} litros ({valueToFloat(value?.percentage, 2)}%)
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "id",
      label: "Ação",
      options: {
        sort: false,
        filter: false,
        viewColumns: true,
        customBodyRender: (value, data) => {
          return (
            <Grid container direction="column" alignItems="center">
              <Tooltip title="Visualizar detalhes" aria-label="visualizar-detalhes">
                <Button
                  onClick={() => {
                    const amplitudeModel = {
                      vehicle: data.rowData[1],
                      date: data.rowData[2],
                      odometer: data.rowData[3],
                      previous_volume: data.rowData[4]?.value,
                      difference_volume: data.rowData[5]?.value,
                      actual_volume: data.rowData[6]?.value
                    }
                    handleAmplitudeEvent('View Details Clicked', amplitudeModel)
                    showDetails(data.rowData[2], data.rowData[7], data.rowData[1])
                  }}
                  className={classes.btAction}
                  aria-label="visualizar-detalhes"
                >
                  Visualizar detalhes{' '}<OpenInNewIcon />
                </Button>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
  ];
};