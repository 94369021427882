import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import classNames from "classnames";

// components
import Aux from 'hoc/auxiliar';
import Table from 'components/Table';
import Widget from 'components/Widget/Widget';
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import { getGroupedRoles } from 'helpers/permissions';

// services
import { deleteRole, getRoles } from 'services/roles';

// styles
import useStyles from './styles';
import { makeColumns } from './tableColumns';
import TableResponsive from "../../../components/TableResponsive";
import {useWindowSize} from "../../../hooks/useWindowsSize";

const ListRoles = () => {
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();
  const size = useWindowSize()

  const fetchRoles = async () => {
    try {
      const response = await getRoles();
      const rolesArray = getGroupedRoles(response.data.data);
      setRoles(rolesArray);
    } catch (err) {
      console.log('err', err)
      toast.error('Erro ao carregar permissões. Entre em contato com o suporte');
    }
  };

  const handleClickAddRole = () => history.push('/gofurther/role/form-role');
  const handleEdit = (value) => history.push(`/gofurther/role/form-role/${value}`);
  const handleDelete = (value) => {
    Swal.fire({
      title: 'Tem certeza que deseja excluir?',
      text: 'Essa ação é irreversível!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteRole(value);
          if (response.status === 200) {
            Swal.fire(
              'Excluído!',
              'O registro foi excluído com sucesso',
              'success',
            );
            fetchRoles();
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            'Erro ao excluir perfil. Entre em contato com o suporte',
          );
        }
      }
    });
  };

  const columns = makeColumns({ onEdit: handleEdit, onDelete: handleDelete ,size});

  useEffect(() => {
    if (hasPermission({scopes: ['can_view_user_role']})) {
      fetchRoles();
    }
  }, []);

  const handleSelectRow = (data,data2) => {
    setId(data2.dataIndex);
    const id = roles[data2.dataIndex].role;
    setSelectedRowData(id)
  }

  const renderTable = useCallback(() => (
    size.mobile?
    <TableResponsive
      pointer={true}
      options={{
        onRowClick: handleSelectRow,
        rowsPerPage: rowsPerPage,
        setRowProps: (row, index) => {
          return index===id? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
      }}
      columns={columns}
      data={roles}
      tableName='list-roles'
      setRowsPerPage={setRowsPerPage}
    />:
      <Table
        columns={columns}
        data={roles}
        tableName='list-roles'
        options={{ rowsPerPage }}
        setRowsPerPage={setRowsPerPage}
      />
  ), [columns, roles, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_user_role']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_user_role']}>
              <Grid container justifyContent='flex-end'>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={classes.btnNew}
                    onClick={handleClickAddRole}
                  >
                    Novo Perfil
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title='Gerenciamento de perfis'>
              {renderTable()}
            </Widget>
          </Grid>
          {/* botoes */}
          {size.mobile?
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <PermissionsGate scopes={['can_edit_user_role']}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={()=>handleEdit(selectedRowData)}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Editar
                    </Button>
                  </Box>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_user_role']}>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Box >
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classNames(
                      classes.buttonDetail,
                      {[classes.buttonDelete]:selectedRowData}
                  )}
                    onClick={()=>handleDelete(selectedRowData)}
                    disabled={!selectedRowData}
                  >
                    Excluir
                  </Button>
                </Box>
              </Grid>
              </PermissionsGate>
              
            </Grid>
          </Grid>:null}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListRoles;
