import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import useStyles from './styles';
import Aux from 'hoc/auxiliar';
import Widget from 'components/Widget/Widget';
import { makeColumns } from './tableColumns';
import { useHistory, useLocation } from 'react-router-dom';
import { hasPermission } from 'components/PermissionsGate';
import { useAppDispatch } from '../../../redux/store'
import { setStepAdd } from "redux/features/driversMonitoringSlice";

// contexts 
import { useSelectDrivers } from 'context/useSelectDrivers';
import { useWindowSize } from "../../../hooks/useWindowsSize";
import TableResponsive from "../../../components/TableResponsive";
import { handleAmplitudeEvent } from 'services/amplitude';

const DriversMonitoringDetails = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const history = useHistory()
  const size = useWindowSize()
  const columns = makeColumns({ size })
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [detailsData, setDetailsData] = useState(null)
  const [id, setId] = useState();
  const [amplitudeEventData, setAmplitudeEventData] = useState();
  const {
    details,
    setTripListDetails,
    setHistoryGoBack
  } = useSelectDrivers()
  const classes = useStyles(size.mobile)

  const handleClickDetail = () => {
    if (!hasPermission({ scopes: ['can_view_drivers_follow_trip_list'] })) {
      return false;
    }
    dispatch(setStepAdd())
    //cadastrando header na versao mobile
    const newArray = selectedRowData.map((item) => ({
      ...item,
      header: {
        dateOrigin: item.origin.date ?? '',
        dateDestination: item.destination.date ?? ''
      }
    }));

    handleAmplitudeEvent('View Details Clicked', amplitudeEventData)

    history.push({
      pathname: '/gofurther/driversMonitoring/tripList',
      state: {
        tripList: newArray,
        name: state.name,
        vehicle: selectedRowData[0].vehicleIdentification + ' - ' +
          selectedRowData[0].chassis + ' - ' +
          selectedRowData[0].plate,
        driverId: state.driverId
      },
    });
  };

  useEffect(() => {
    setDetailsData(details?.lenght === 0 || state.data)
  }, [])

  useEffect(() => {
    handleAmplitudeEvent('Drivers Monitoring Details Screen Viewed');
  }, [])

  return (
    <Aux>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Box className={classes.marginTop}>
            <Widget
              disableWidgetMenu
              title='Relatório de viagens'>
              <Grid item xl={12} xs={12}>
                <Grid container>
                  <Grid item xl={4} lg={4} md={5} sm={8} xs={10}>
                    <Grid
                      container
                      component={Paper}
                      spacing={2}
                      className={classes.infoDriverContainer}
                    >
                      <Grid className={classes.contentDriver} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography className={classes.label}>Motorista: </Typography>
                        <strong className={classes.driverName}>{state.name}</strong>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} xs={12} className={classes.marginTop}>
                {detailsData && <TableResponsive
                  data={detailsData}
                  columns={columns}
                  tableBodyMaxHeight={size.mobile ? size.height - 430 + 'px' : size.height - 330 + 'px'}
                  options={{
                    rowsPerPage: 100,
                    setRowProps: (row, index) => {
                      return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                    },
                    onRowClick: (data, data2) => {
                      const newSelectedRowData = detailsData.find(
                        (_info, index) => index === data2.dataIndex,
                      );
                      setId(data2.dataIndex);
                      setTripListDetails(newSelectedRowData.tripsScores)
                      setSelectedRowData(newSelectedRowData.tripsScores)
                      const amplitudeData = {
                        driver_id: state.driverId,
                        driver_name: state.name,
                        vehicle_id: newSelectedRowData.vehicleId,
                        vehicle_name: newSelectedRowData.vehicleIdentification,
                        total_mileage: newSelectedRowData.achievedMileage,
                        total_consumption: newSelectedRowData.consumption,
                        avg_consumption: newSelectedRowData.consumptionAvg,
                        score: newSelectedRowData.score
                      }
                      setAmplitudeEventData(amplitudeData)
                      handleAmplitudeEvent('Vehicle Selected', amplitudeData)
                    },

                  }}
                  pointer={true}
                  tableName='drivers-monitoring-details'
                />}
              </Grid>
            </Widget>
            {/* botoes */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box marginTop={size.mobile ? "25px" : 0}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.buttonBack}
                      onClick={() => {
                        handleAmplitudeEvent('Backward Button Clicked')
                        setHistoryGoBack(true)
                        history.goBack()
                      }}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleClickDetail}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Ver detalhes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default DriversMonitoringDetails;
