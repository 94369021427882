import React, { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// styles
import useStyles from "./styles";
import { useWindowSize } from "../../hooks/useWindowsSize";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ImageNewFeature from '../../assets/images/controlDiesel.png';

export default function NewFeatureModal({ title, description, image, topics, handleClose, open }) {
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const classes = useStyles();
    const size = useWindowSize()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="new-feature-dialog-title"
            aria-describedby="new-feature-dialog-description"
            maxWidth={"lg"}
            fullWidth
            fullScreen={size.mobile}
            PaperProps={{ classes: { root: classes.dialogBase } }}
            scroll="paper"
        >
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
            <Box className={classes.contentBox}            >
                <DialogTitle disableTypography>
                    <Typography variant="h4" className={classes.textBold}>
                        {title}
                    </Typography>
                    <Typography variant="h5" className={classes.description}>
                        {description}
                    </Typography>
                </DialogTitle>
                <div className={classes.gridRoot}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={7}>
                            <img src={image} alt="Controle de diesel" style={{ maxWidth: '100%' }} />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <List>
                                {topics?.map((topic, index) => (
                                    <ListItem key={index}>
                                        <CheckCircleIcon className={classes.listIcon} />
                                        <Typography className={classes.listItem}>
                                            {topic.content}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Dialog>
    )
}