import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import classnames from 'classnames'

// hoc
import Aux from 'hoc/auxiliar'

// components
import PermissionsGate, { hasPermission } from 'components/PermissionsGate'
import ConsolidatedScore from './components/ConsolidatedScore'
import ConsolidatedScoreSkeleton from './components/ConsolidatedScore/Skeleton'
import ConsolidatedStatistics from './components/ConsolidatedStatistics'
import ConsolidatedStatisticsSkeleton from './components/ConsolidatedStatistics/Skeleton'
import ConsolidatedIndicators from './components/ConsolidatedIndicators'
import ConsolidatedIndicatorsSkeleton from './components/ConsolidatedIndicators/Skeleton'
import ConsolidatedBrand from './components/ConsolidatedBrand'
import ConsolidatedBrandSkeleton from './components/ConsolidatedBrand/Skeleton'
import ConsolidatedProductivity from './components/ConsolidatedProductivity'
import ConsolidatedProductivitySkeleton from './components/ConsolidatedProductivity/Skeleton'
import ConsolidatedRisk from './components/ConsolidatedRisk'
import ConsolidatedRiskSkeleton from './components/ConsolidatedRisk/Skeleton'
import ConsolidatedGroups from './components/ConsolidatedGroups'
import ConsolidatedGroupsSkeleton from './components/ConsolidatedGroups/Skeleton'
import ConsolidatedVehicles from './components/ConsolidatedVehicles'
import ConsolidatedVehiclesSkeleton from './components/ConsolidatedVehicles/Skeleton'

// services
import { getCustomersById } from 'services/customers'
import { getOperations } from 'services/operations'
import { formatVehicleGroups, generateRange, getYearsOptions } from 'helpers/operations'

// helpers
import { defaultOperation } from 'helpers/follow'
import { getCurrentMonth } from 'helpers/functions'

// context
import { useLayoutState } from 'context/LayoutContext'

// styles
import useStyles from './styles'
import { colourStyles } from './colourStyles'
import { store, useAppDispatch, useAppSelector } from 'redux/store'
import { getMyFleet } from 'services/consolidated'
import { clearConsolidatedFilters, setFilters } from 'redux/features/consolidatedSlice'
import { saveCustomerProfile } from 'redux/features/customerProfileSlice'
import DriversMonitoringSkeleton from 'components/Skeletons/DriversMonitoringSkeleton'
import Infobox from 'components/Infobox'
import { useWindowSize } from "../../hooks/useWindowsSize";
import { handleAmplitudeEvent } from 'services/amplitude'

export default function Consolidated() {
  const dispatch = useAppDispatch()
  const { currentCustomer } = useAppSelector((state) => state.global.user)
  const { filters } = useAppSelector((state) => state.consolidated);
  const { profile } = useAppSelector((state) => state.customerProfile);
  const classes = useStyles()
  const animatedComponents = makeAnimated()
  const yearsOptions = getYearsOptions()
  const layoutState = useLayoutState()

  const [cutOffDatesOptions, setCutOffDatesOptions] = useState(null)
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX"
  const today = new Date()
  const [accidentRisk, setAccidentRisk] = useState([])
  const [fleetBrand, setFleetBrand] = useState([])
  const [score, setScore] = useState(0)
  const [operationGroup, setOperationGroup] = useState([])
  /* const [fleetVehicle, setFleetVehicle] = useState([]) */
  const [fleetProductivity, setFleetProductivity] = useState([])

  const [operationsOptions, setOperationsOptions] = useState([])
  const [indicators, setIndicators] = useState([]);
  const [statistics, setStatistics] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(true)

  const handleCutOffDates = useCallback((customerOptions, year, isInitialRender = true) => {
    const { starting_day, period, finishing_day } = customerOptions
    const currentYear = year.value
    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(starting_day, finishing_day, period, currentYear);
      setCutOffDatesOptions(cutOffDate);
      const selectedMonth = getCurrentMonth(finishing_day);
      dispatch(setFilters({ selectedOption: isInitialRender ? cutOffDate[selectedMonth] : null, name: 'cutoffDate' }))
    } else {
      setCutOffDatesOptions(null);
      dispatch(setFilters({ selectedOption: null, name: 'cutoffDate' }))
    }
  }, [dispatch, today])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const operationsResponse = await getOperations(currentCustomer);
      const customersResponse = await getCustomersById(currentCustomer);

      if (operationsResponse.status !== 200) {
        throw new Error("Error fetching operations");
      }

      if (customersResponse.status !== 200) {
        throw new Error("Error fetching customers");
      }

      const operationData = operationsResponse?.data?.data?.operation;
      if (operationData) {
        const flatArrayAllVehicles = formatVehicleGroups(operationData)
          .flatMap(item => item.vehicles);
        const newOperation = { ...defaultOperation, vehicles: flatArrayAllVehicles };
        const newArrayGroups = [newOperation, ...formatVehicleGroups(operationData)];
        setOperationsOptions(newArrayGroups);
      }
      const customersOptions = customersResponse.data.customers[0];
      dispatch(saveCustomerProfile(customersOptions))
      handleCutOffDates(customersOptions, filters.year)
    } catch (err) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMyFleet = async (vehicleIds, startDate, finishDate) => {
    try {
      setLoadingData(true)
      const response = await getMyFleet(vehicleIds, startDate, finishDate)
      if (response.status === 200) {
        setAccidentRisk(response.data.data.byAccidentRisk)
        setFleetBrand(response.data.data.byBrand)
        setOperationGroup(response.data.data.byOperation)
        setFleetProductivity(response.data.data.byProductivity)
        /* setFleetVehicle(response.data.data.byVehicle) */
        setStatistics(response.data.data.statistics)
        setIndicators(response.data.data.indicators)
        setScore(response.data.data.score)
        handleAmplitudeEvent('My Fleet Loaded', {
          operation_id: response.data.data.byOperation[0].operation_id,
          operation_name: response.data.data.byOperation[0].operation_name,
          year: filters.year.label,
          period: `${startDate} - ${finishDate}`,
          score: response.data.data.score,
          total_mileage: response.data.data.statistics.totalMileage,
          total_consumption: response.data.data.statistics.totalConsumption,
          avg_consumption: response.data.data.statistics.consumptionAverage
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }
  const handleDataByOperation = async (operation, cutoffDate) => {
    const { startDate, finishDate } = JSON.parse(cutoffDate);
    const formattedStartDate = format(new Date(startDate), dateFormatTemplate);
    const formattedFinishDate = format(new Date(finishDate), dateFormatTemplate);
    const listVehicleByOperation = operation.vehicles?.map((elm) => elm?.vehicleId ?? null)
    const modelFormattedCutoffDates = {
      startDate: formattedStartDate,
      finishDate: formattedFinishDate
    }
    if (operation && cutoffDate) {
      fetchMyFleet(listVehicleByOperation, modelFormattedCutoffDates.startDate, modelFormattedCutoffDates.finishDate)
    }
  };

  const onChangeFilters = (selectedOption, name) => {
    handleAmplitudeEvent('Filter Updated', { label: name, value: selectedOption.label })
    try {
      setLoading(true)
      dispatch(setFilters({ selectedOption, name }))
      const updatedFilters = store.getState().consolidated.filters;
      if (name === 'year') {
        dispatch(setFilters({ selectedOption: null, name: 'cutoffDate' }))
        const isInitialRender = false
        handleCutOffDates(profile, updatedFilters.year, isInitialRender)
      } else if (!!updatedFilters.operation && !!updatedFilters.cutoffDate) {
        handleDataByOperation(updatedFilters.operation, updatedFilters.cutoffDate);
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  };

  const size = useWindowSize()

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_consolidated'] })) {
      fetchData()
      dispatch(clearConsolidatedFilters())
    }
  }, [currentCustomer])

  useEffect(() => {
    handleAmplitudeEvent('My Fleet Screen Viewed');
  }, [])


  if ((loading) && filters.operation?.id >= 0) return <DriversMonitoringSkeleton />;

  const Filters = () => (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.spacingContainer}>
      <Grid container justifyContent="space-around">

        {operationsOptions.lenght === 0 ? (
          <Grid
            item
            xl={size.grid4}
            lg={size.grid4}
            md={size.grid4}
            sm={size.grid4}
            xs={size.grid4}
            style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
          >
            <Paper elevation={0} className={classes.center}>
              <Typography variant="h5">
                Não foram encontradas operações disponíveis.
              </Typography>
            </Paper>
          </Grid>
        ) : (
          <Grid
            item
            xl={size.grid4}
            lg={size.grid4}
            md={size.grid4}
            sm={size.grid4}
            xs={size.grid4}
            style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
          >
            <Paper elevation={0} className={classes.paper}>
              <Select
                name="operation"
                placeholder="Selecione uma operação"
                options={operationsOptions}
                styles={colourStyles}
                onChange={(selectedOption) => onChangeFilters(selectedOption, "operation")}
                value={filters.operation}
                defaultValue={filters.operation}
                components={animatedComponents}
                noOptionsMessage={() => "Nenhum resultado encontrado!"}
              />
            </Paper>
          </Grid>
        )}
        <Grid
          item
          xl={size.grid4}
          lg={size.grid4}
          md={size.grid4}
          sm={size.grid4}
          xs={size.grid4}
          style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
        >
          <Paper elevation={0} className={classes.paperSelect}>
            <Select
              name="year"
              placeholder="Ano"
              options={yearsOptions.reverse()}
              styles={colourStyles}
              onChange={(selectedOption) => onChangeFilters(selectedOption, "year")}
              value={filters.year}
              defaultValue={filters.year}
              components={animatedComponents}
            />
          </Paper>
        </Grid>
        {cutOffDatesOptions === null ? (
          <Grid
            item
            xl={size.grid4}
            lg={size.grid4}
            md={size.grid4}
            sm={size.grid4}
            xs={size.grid4}
            style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
          >
            <Paper elevation={0} className={classes.center}>
              <Typography>Não foram encontradas data de corte.</Typography>
            </Paper>
          </Grid>
        ) : (
          <Grid
            item
            xl={size.grid4}
            lg={size.grid4}
            md={size.grid4}
            sm={size.grid4}
            xs={size.grid4}
            style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
          >
            <Paper elevation={0} className={classes.paper}>
              <Select
                name='cutoffDate'
                placeholder="Selecione a data de corte"
                options={cutOffDatesOptions}
                styles={colourStyles}
                components={animatedComponents}
                onChange={(selectedOption) => onChangeFilters(selectedOption, "cutoffDate")}
                value={JSON.parse(filters.cutoffDate)}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  )

  const Score = () => (
    <PermissionsGate scopes={['can_view_consolidated_score']}>
      <Grid
        item
        xl={size.grid4}
        lg={size.grid4}
        md={size.grid4}
        sm={size.grid6}
        xs={size.grid12}
        data-cy="consolidatedScore"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container alignItems="center">
            {loadingData ? <ConsolidatedScoreSkeleton /> :
              <ConsolidatedScore score={score} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Statistics = ({ data, selectedVehicles }) => {
    return (
      <PermissionsGate scopes={['can_view_consolidated_statistics']}>
        <Grid
          item
          xl={size.grid8}
          lg={size.grid8}
          md={size.grid8}
          sm={size.grid6}
          xs={size.grid12}
          data-cy="consolidatedStatistics"
        >
          <Paper elevation={0} className={classes.card}>
            <Grid container>
              {loadingData ? <ConsolidatedStatisticsSkeleton /> :
                <ConsolidatedStatistics data={data} selectedVehicles={selectedVehicles} />
              }
            </Grid>
          </Paper>
        </Grid>
      </PermissionsGate>
    )
  }

  const Indicators = ({ indicators, score }) => (
    <PermissionsGate scopes={['can_view_consolidated_indicators']}>
      <Grid
        item
        xs={size.grid12}
        data-cy="consolidatedIndicators"
      >
        <Grid container>
          {loadingData ? <ConsolidatedIndicatorsSkeleton /> :
            <ConsolidatedIndicators indicators={indicators} />
          }
        </Grid>
      </Grid>
    </PermissionsGate>
  )

  const Brand = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_brand']}>
      <Grid
        item
        xs={size.grid12}
        data-cy="consolidatedBrand"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData ? <ConsolidatedBrandSkeleton /> :
              <ConsolidatedBrand data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Productivity = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_productivity']}>
      <Grid
        item
        xs={size.grid12}
        data-cy="consolidatedProductivity"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData ? <ConsolidatedProductivitySkeleton /> :
              <ConsolidatedProductivity data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  const Risk = ({ data }) => {
    return (
      <PermissionsGate scopes={['can_view_consolidated_risk']}>
        <Grid
          item
          xs={size.grid12}
          data-cy="consolidatedRisk"
        >
          <Paper elevation={0} className={classes.card}>
            <Grid container>
              {loadingData ? <ConsolidatedRiskSkeleton /> :
                <ConsolidatedRisk data={data} />
              }
            </Grid>
          </Paper>
        </Grid>
      </PermissionsGate>
    )
  }

  const Groups = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_groups']}>
      <Grid
        item
        xs={size.grid12}
        data-cy="consolidatedOperation"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData ? <ConsolidatedGroupsSkeleton /> :
              <ConsolidatedGroups data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  )

  /* const Vehicles = (data) => (
    <PermissionsGate scopes={['can_view_consolidated_vehicles']}>
      <Grid
        item
        xs={size.grid12}
        data-cy="consolidatedVehicles"
      >
        <Paper elevation={0} className={classes.card}>
          <Grid container>
            {loadingData ? <ConsolidatedVehiclesSkeleton /> :
              <ConsolidatedVehicles data={data} />
            }
          </Grid>
        </Paper>
      </Grid>
    </PermissionsGate>
  ) */

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_consolidated']}>
        <Grid container item xs={12} xl={12}>
          <Typography variant="h3" className={classes.title}>Minha frota</Typography>
        </Grid>
        {loading ? 'Carregando os filtros...' : <Filters />}
        {(filters.operation && filters.cutoffDate && filters.year) ? (

          <Grid
            container
            spacing={2}
            data-cy="contentConsolidated"
            className={classnames(classes.container, {
              [classes.containerShift]: layoutState.isSidebarOpened,
            })}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Score />
                <Statistics data={statistics} selectedVehicles={[133, 589, 557]} />
                <Indicators indicators={indicators} score={score} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Brand data={fleetBrand} />
                    <Productivity data={fleetProductivity} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Risk data={accidentRisk} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Groups data={operationGroup} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Vehicles data={fleetVehicle} />
            </Grid> */}
          </Grid>
        ) : <Infobox />}
      </PermissionsGate>
    </Aux>
  );
}
