import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';

import { getVehicle, getVehicleDeviceHistory } from 'services/vehicle';
import { getDeviceStatus } from '../../helpers/deviceStatus'
import Widget from 'components/Widget/Widget';
import Aux from "hoc/auxiliar";

import { makeColumns } from "./tableColumns";
import useClasses from './styles'
import LinkDeviceToVehicleModal from 'components/LinkDeviceToVehicleModal';
import { getDevices } from 'services/device';
import ListVehiclesSkeleton from 'components/Skeletons/ListVehiclesSkeleton';
import Typography from '@material-ui/core/es/Typography/Typography';
import { useUrlParams } from 'hooks/useUrlParams';
import BackButton from 'components/BackButton';
import TableResponsive from "../../components/TableResponsive";
import {useWindowSize} from "../../hooks/useWindowsSize";


function VehicleDevices() {
  const query = useUrlParams();
  const size = useWindowSize()
  const classes = useClasses(size.mobile)
  const id = Number(query.get("vehicleId"));
  const [vehiclesDevices, setVehiclesDevices] = useState([])
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(true)
  const [isUnlink, setIsUnlink] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [rowData, setRowData] = useState(null)
  const [vehicle, setVehicle] = useState([])
  const [selectedRowData, setSelectedRowData] = useState();
  const [idRow, setId] = useState();
  const [vinculo, setVinculo] = useState(false);

  const handleCloseModalDevice = () => setOpenModal(false);

  const handleNew = () => {
    setRowData(id);
    setOpenModal(true);
    setIsUnlink(false)
  }
  const handleUnlink = async (value) => {
    setRowData(value);
    setOpenModal(true);
    setIsUnlink(true)
  }
  const columns = makeColumns({size});

  const fetchDevices = async () => {
    try {
      setLoading(true)
      const response = await getDevices();
      const filteredResponse = response.data.devices.filter((item) => item.status === 1 || item.status === 2 || item.status === 5)
      const formatedResponse = filteredResponse.map((item) => ({
        value: item.id,
        label: `${item.identification} - ${getDeviceStatus(item.status)}`
      }));
      setDevices(formatedResponse);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de unidades eletrônicas. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false)
    }
  };

  const handleLinkDevices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getVehicleDeviceHistory(id);

      if (!response) {
        toast.error(
          "Você não possui histórico ainda. Entre em contato com o suporte.",
        );
        setVehiclesDevices();
        return;
      }
      if (!response.data.data.vehiclesDevices) {
        setVehiclesDevices();
      } else {
        const newResponse = response.data.data.vehiclesDevices.map((devices) => {
          let id = devices.deviceId;
          return { ...devices, id };
        });
        setVehiclesDevices(newResponse);
      }
    } catch (error) {
      toast.error(
        "Você não possui histórico ainda. Entre em contato com o suporte.",
      );
      return;
    } finally {
      setLoading(false);
    }
  }, [id])

  const fetchVehicleById = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getVehicle(id)

      if (response.status === 200 || response.status === 201) {
        setVehicle(response.data)
      }
    } catch (error) {
      toast.error('Erro ao buscar o veículo, contate o administrador.')
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    id && handleLinkDevices()
  }, [handleLinkDevices, id])

  useEffect(() => {
    id && fetchVehicleById()
  }, [id, fetchVehicleById])

  useEffect(() => {
    fetchDevices();
  }, []);

  if (loading) {
    return (
      <ListVehiclesSkeleton />
    )
  }

  const handleSelectRow = (data,data2) => {
    setId(data2.dataIndex);
    const id = vehiclesDevices[data2.dataIndex].id;
    setSelectedRowData(id)
    setVinculo(!vehiclesDevices[data2.dataIndex].endDate)
  }
  

  return (
    <Aux>
      <LinkDeviceToVehicleModal
        loading={loading}
        open={openModal}
        devices={devices}
        isUnlink={isUnlink}
        handleClose={handleCloseModalDevice}
        deviceId={rowData}
        vehicleId={id}
        fetchDevices={fetchDevices}
        vehiclesDevices={vehiclesDevices}
        handleLinkDevices={handleLinkDevices}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} className={classes.tableContent}>
          <Grid container justifyContent="flex-end">
            <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnNew}
                onClick={() => handleNew()}
              >
                Novo vínculo
              </Button>
            </Grid>
          </Grid>
          <Widget disableWidgetMenu title="Histórico de unidade eletrônica">
            <Grid item xl={12} xs={12}>
              <Grid container>
                <Grid item xl={4} lg={4} md={5} sm={8} xs={10}>
                  <Grid
                    container
                    component={Paper}
                    spacing={2}
                    className={classes.infoDriverContainer}
                  >
                    <Grid className={classes.contentDriver} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.label}>Veículo: </Typography>
                      <strong className={classes.driverName}>{vehicle?.identification} - {vehicle?.plate}</strong>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableResponsive
              options={{
                onRowClick: handleSelectRow,
                setRowProps: (row, index) => {
                  return index===idRow? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
              }}
              pointer={true}
              data={vehiclesDevices}
              columns={columns}
              tableName="vehicle-device"
            />
          </Widget>
        </Grid>
        {/* botoes */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Box>
                <BackButton className={classes.backButton} />
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={()=>{handleUnlink(selectedRowData)}}
                  disabled={!vinculo}
                  className={classes.buttonDetail}
                >
                  Desvincular
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Aux>

  )
}

export default VehicleDevices;