import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import Aux from "hoc/auxiliar";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "pages/dashboard/general";
import Comparison from "pages/dashboard/comparison";
import DieselControl from "pages/dashboard/dieselControl";

import {
  CreateOperation,
  EditOperation,
  OperationList,
  OperationListHistory,
} from "pages/maintenance/operations";

import AssignDriver from "pages/maintenance/assignDriver";
import LinkDrivers from "pages/maintenance/linkdrivers";
import HistoricLinkDrivers from "pages/maintenance/historicLinkDrivers";
import TelemetryData from "pages/telemetry-data";
import Follow from "pages/bonus/follow";
import RewardDetails from "pages/bonus/Details";
import Co2Emission from "pages/co2";

import AddDriver from "pages/driver/addDriver/addDriver";
import AllDrivers from "pages/driver/allDrivers/AllDrivers";
import EditDriver from "pages/driver/addDriver/editDriver";
import MassAddDriver from "pages/driver/addDriver/massAddDriver";
import ListRecalculationDriver from "pages/driver/recalculation/ListRecalculationDriver";
import ListRecalculationDriverDetails from "pages/driver/recalculation/ListRecalculationDriverDetails";

import { CustomerList, CreateCustomer, EditCustomer, ViewCustomer } from "pages/customers";

import AddDevice from "pages/device/formDevice/AddDevice";
import EditDevice from "pages/device/formDevice/EditDevice";
import ListDevices from "pages/device/listDevices/ListDevices";
import MassAddDevice from "pages/device/formDevice/MassAddDevice";

import AddVehicle from "pages/vehicle/formVehicle/AddVehicle";
import EditVehicle from "pages/vehicle/formVehicle/EditVehicle";
import ViewVehicle from "pages/vehicle/viewVehicle/ViewVehicle";
import ListVehicles from "pages/vehicle/listVehicles/ListVehicles";
import MassAddVehicle from "pages/vehicle/formVehicle/MassAddVehicle";
import ListRecalculation from "pages/vehicle/recalculation/ListRecalculation";
import ListRecalculationDetails from "pages/vehicle/recalculation/ListRecalculationDetails";

import AddProfile from "pages/profile/formProfile/AddProfile";
import ViewProfile from "pages/profile/viewProfile/ViewProfile";
import ListProfiles from "pages/profile/listProfiles/ListProfiles";

import AddRewardGroup from "pages/rewardGroup/addRewardGroup";
import ListRewardGroup from "pages/rewardGroup/RewardGroupList";
import EditRewardGroup from "pages/rewardGroup/editRewardGroup";
import ViewRewardGroud from "pages/rewardGroup/viewRewardGroup";

import ListUsers from "pages/users/listUsers/ListUsers";
import AddUser from "pages/users/formUser/AddUser";
import EditUser from "pages/users/formUser/EditUser";
import ViewUser from "pages/users/viewUser/ViewUser";
import ChangePassword from "pages/users/changePassword";

import ListRoles from "pages/role/listRoles";
import AddRole from "pages/role/form/AddRole";
import EditRole from "pages/role/form/EditRole";

import ListDriverRoles from "pages/role/listDriverRoles";
import AddDriverRole from "pages/role/formDriver/AddRole";
import EditDriverRole from "pages/role/formDriver/EditRole";

import DriversMonitoring from "pages/driversMonitoring/follow";
import DriversMonitoringDetails from "pages/driversMonitoring/details";
import DriversMonitoringTripList from "pages/driversMonitoring/tripList";

import ManageNotification from "pages/manageNotification";

// context
import { useLayoutState } from "context/LayoutContext";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import VehicleDevices from "pages/vehicle-device";
import HistoricDevices from "pages/device/historicDevices";
import HistoricProfiles from "pages/profile/historicProfiles";
import HistoricVehicles from "pages/vehicle/historicVehicles";
import Evolutive from "pages/dashboard/evolutive";
import Consolidated from "pages/consolidated";
import LiveMap from "pages/liveMap/liveMap";
import LiveMapDetails from "pages/liveMapDetails/liveMapDetails";
import NotificationHistory from "pages/notificationHistory";
import Communication from "pages/communication";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            next.redirect("/login");
          }
          next();
        });
    } else {
      next();
    }
  };

  return (
    <div className={classes.root}>
      <Aux>
        <Header history={props.history} />
        <Sidebar />
        <div
          id="drawer-container"
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{ paddingTop: 80 }}
        >
          <GuardProvider guards={[requireLogin]} error={Error}>
            <GuardedRoute
              path="/gofurther/dashboard/general"
              meta={{ auth: true }}
              component={Dashboard}
            />
            <GuardedRoute
              path="/gofurther/dashboard/dieselControl"
              meta={{ auth: true }}
              component={DieselControl}
            />
            <GuardedRoute
              path="/gofurther/dashboard/evolutive"
              meta={{ auth: true }}
              component={Evolutive}
            />
            <GuardedRoute
              path="/gofurther/liveMap"
              meta={{ auth: true }}
              component={LiveMap}
            />
            <GuardedRoute
              path="/gofurther/liveMapDetails"
              meta={{ auth: true }}
              component={LiveMapDetails}
            />
            <GuardedRoute
              path="/gofurther/consolidated"
              meta={{ auth: true }}
              component={Consolidated}
            />
            <GuardedRoute
              path="/gofurther/c02-emission"
              meta={{ auth: true }}
              component={Co2Emission}
            />
            <GuardedRoute
              path="/gofurther/dashboard/comparison"
              meta={{ auth: true }}
              component={Comparison}
            />
            <GuardedRoute
              path="/gofurther/maintenance/operations/operationList"
              exact
              component={OperationList}
              meta={{ auth: true }}
            />
            <GuardedRoute
              path="/gofurther/maintenance/operations/operationListHistory"
              exact
              component={OperationListHistory}
              meta={{ auth: true }}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/maintenance/operations/createOperation"
              component={CreateOperation}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/maintenance/operations/editOperation/:id"
              component={EditOperation}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/maintenance/linkdrivers"
              component={LinkDrivers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/maintenance/historicLinkDrivers"
              component={HistoricLinkDrivers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/maintenance/assignDriver"
              component={AssignDriver}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/bonus/follow"
              component={Follow}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/bonus/follow/details"
              component={RewardDetails}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/telemetrydata"
              component={TelemetryData}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driver/all-drivers"
              component={AllDrivers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driver/add-driver"
              component={AddDriver}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driver/edit/:driverId"
              component={EditDriver}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/user/changePassword"
              component={ChangePassword}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driver/mass-add-driver"
              component={MassAddDriver}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driver/recalculation"
              component={ListRecalculationDriver}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driver/recalculation/:id"
              component={ListRecalculationDriverDetails}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/customer/customer-list"
              component={CustomerList}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/customer/create-customer"
              component={CreateCustomer}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/customer/edit-customer/:id"
              component={EditCustomer}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/customers/view-customer/:id"
              component={ViewCustomer}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/device/form-device"
              component={AddDevice}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/device/form-device/:deviceId"
              component={EditDevice}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/device/list-devices"
              component={ListDevices}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/device/historic-devices/:deviceId"
              component={HistoricDevices}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/device/vehicle-devices"
              component={VehicleDevices}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/device/mass-add-device"
              component={MassAddDevice}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/form-vehicle"
              component={AddVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/historic-vehicle/:vehicleId"
              component={HistoricVehicles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/form-vehicle/:vehicleId"
              component={EditVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/view-vehicle/:vehicleId"
              component={ViewVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/list-vehicles"
              component={ListVehicles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/mass-add-vehicle"
              component={MassAddVehicle}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/recalculation"
              component={ListRecalculation}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/vehicle/recalculation/:id"
              component={ListRecalculationDetails}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/profile/form-profile"
              component={AddProfile}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/profile/form-profile/:profileId"
              component={AddProfile}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/profile/view-profile/:profileId"
              component={ViewProfile}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/profile/list-profiles"
              component={ListProfiles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/profile/historic-profiles/:modelId"
              component={HistoricProfiles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/rewardGroup/add-rewardGroup"
              component={AddRewardGroup}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/rewardGroup/list-rewardGroup"
              component={ListRewardGroup}
            />
            {/* Route Users */}
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/user/list-users"
              component={ListUsers}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/user/form-user"
              component={AddUser}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/user/form-user/:userID"
              component={EditUser}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/user/view-user/:userID"
              component={ViewUser}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/rewardGroup/edit/:id"
              component={EditRewardGroup}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/rewardGroup/view-reward-group/:id"
              component={ViewRewardGroud}
            />

            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/role/list-roles"
              component={ListRoles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/role/form-role"
              component={AddRole}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/role/form-role/:id"
              component={EditRole}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/role/list-roles-app"
              component={ListDriverRoles}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/role/form-app-role"
              component={AddDriverRole}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/role/form-app-role/:id"
              component={EditDriverRole}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driversMonitoring"
              component={DriversMonitoring}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driversMonitoring/details"
              component={DriversMonitoringDetails}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/driversMonitoring/tripList"
              component={DriversMonitoringTripList}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/communication"
              component={Communication}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/manageNotification"
              component={ManageNotification}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/notificationHistory"
              component={NotificationHistory}
            />
            <GuardedRoute
              exact
              meta={{ auth: true }}
              path="/gofurther/dieselControl"
              component={DieselControl}
            />
          </GuardProvider>
        </div>
      </Aux>
    </div>
  );
}

export default withRouter(Layout);
