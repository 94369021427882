/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef, useCallback } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { colourStyles } from "./styles";
import { useDebouncedCallback } from "use-debounce";
import { Paper, Grid } from "@material-ui/core";
import useStyles from "./useStyles";
import colors from "../../themes/gobrax";
import { clearDuplicated } from "../../helpers/functions";

const MultiSelectUsers = ({
  isDetail,
  handleSelectedListData,
  listData,
  selectedData,
  isAllCustomers,
}) => {
  const selectAllOption = {
    value: "select-all",
    label: "Todos as empresas",
    color: colors.palette.text.primary,
    id: "select-all",
    isChild: false,
    isHidden: false,
    isSelected: false,
  };

  const classes = useStyles();

  const initialOptionsRef = useRef([]);
  const parentOptionsRef = useRef([]);
  const childOptionsRef = useRef([]);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [selected, setSelected] = useState([]);

  const formatData = useCallback(
    (data = []) => {
      const options = [];
      const childFiltered = [];
      const initialSelectedOptions = [];
      const parentFiltered = [];

      options.push(selectAllOption);

      data.forEach((item) => {
        const localItem = {
          ...item,
          value: item.value,
          label: item.label,
          color: colors.palette.text.hint,
          isChild: true,
          isHidden: true,
          isSelected: true,
          id: item.value,
        };
        options.push(localItem);
        initialSelectedOptions.push(localItem);
      });

      options.filter(
        (isChild) => isChild.isChild && childFiltered.push(isChild),
      );

      options.filter(
        (isChild) => !isChild.isChild && parentFiltered.push(isChild),
      );

      initialOptionsRef.current = options;
      childOptionsRef.current = childFiltered;
      parentOptionsRef.current = parentFiltered;
      handleClearAll();
      setSelectedOption([]);
    },
    [],
  );

  const debounced = useDebouncedCallback((value, item) => {
    let isAllCustomers = false;
    const selected = clearDuplicated(value);
    if (!!item && item[0].value === "select-all") {
      isAllCustomers = true;
    }
    handleSelectedListData(selected, isAllCustomers);
  }, 500);

  useEffect(() => {
    if (listData) {
      if (!selectedData) {
        formatData(listData);
        return;
      } else {
        const op = selectedData.map((item) => {
          return {
            ...item,
            value: item.value,
            label: item.label,
            color: colors.palette.text.hint,
            isChild: true,
            isHidden: false,
            isSelected: true,
            id: item.value,
          };
        });
        debounced(op);
        formatData(listData);
        if (isAllCustomers) {
          const action = {
            action: "select-option",
            option: {
              value: "select-all",
              label: "Todas as empresas",
              color: "#424448",
              id: "select-all",
              isChild: false,
              isHidden: false,
              isSelected: false,
            },
          };
          handleChange(listData, action);
        } else {
          setSelectedOption(op);
          setSelected(op);
        }
      }
    }
  }, [formatData, listData, selectedData]);

  const handleAddedOption = (element, list, options) => {
    // check if it is parent and hide children
    let initialOptionsLocal = [...initialOptionsRef.current];

    if (element.value === "select-all") {
      initialOptionsLocal.forEach((item, index) => {
        const updatedElement = { ...item, isSelected: true, isHidden: true };
        initialOptionsLocal[index] = updatedElement;

        if (item.isChild) {
          options.push(updatedElement);
        }
      });

      const localList = [element];

      initialOptionsRef.current = initialOptionsLocal;
      return [localList, options];
    }

    if (!element.isChild) {
      const id = element.id;
      initialOptionsLocal.forEach((item, index) => {
        if (item.parentId === id && item.isSelected) {
          list = list.filter((i) => i.parentId !== item.parentId);
        }
        if (item.parentId === id) {
          const updatedElement = { ...item, isHidden: true, isSelected: true };
          initialOptionsLocal[index] = updatedElement;
          if (!item.isSelected) {
            options.push(updatedElement);
          }
        }
      });

      initialOptionsRef.current = initialOptionsLocal;
      return [list, options];
    }

    // check if all child is selected: select parent and hide children
    const parentId = element.parentId;
    const id = element.id;

    let allSelected = true;
    initialOptionsLocal.forEach((item, index) => {
      if (item.id === id) {
        const updatedElement = { ...item, isSelected: true };
        initialOptionsLocal[index] = updatedElement;
        options.push(updatedElement);
        list.push(element);
        return false;
      }

      if (item.parentId !== parentId) return false;

      if (item.parentId === parentId && !item.isSelected) allSelected = false;
    });

    if (allSelected) {
      initialOptionsLocal.forEach((item, index) => {
        if (!item.isChild && item.id === element.parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: false };
          list.push(item);
        }
        if (item.parentId === parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: true };
          list = list.filter((i) => i.parentId !== parentId);
        }
      });
    }

    initialOptionsRef.current = initialOptionsLocal;
    return [list, options];
  };

  const handleRemovedOption = (element, list, options) => {
    if (element.value === "select-all") {
      handleClearAll();
      setSelectedOption([]);
      debounced([]);
      return [[], []];
    }

    if (element.isChild) {
      list = list.filter((item) => item.id !== element.id);

      const updatedSelectedOptions = options.filter(
        (el) => element.id !== el.id,
      );
      return [list, updatedSelectedOptions];
    }

    let updatedSelectedOptions = options;
    let initialOptionsLocal = [...initialOptionsRef.current];
    initialOptionsLocal.forEach((item, index) => {
      if (item.parentId === element.id || item.id === element.id) {
        list = list.filter((el) => el.id !== element.id);
        initialOptionsLocal[index] = {
          ...item,
          isHidden: false,
          isSelected: false,
        };
        updatedSelectedOptions = updatedSelectedOptions.filter(
          (el) => item.id !== el.parentId,
        );
      }
    });

    initialOptionsRef.current = initialOptionsLocal;

    return [list, updatedSelectedOptions];
  };

  const handleClearAll = () => {
    let initialOptionsLocal = [...initialOptionsRef.current];
    initialOptionsLocal.forEach((item, index) => {
      initialOptionsLocal[index] = {
        ...item,
        isHidden: false,
        isSelected: false,
      };
    });

    initialOptionsRef.current = initialOptionsLocal;
    setSelected([]);
  };

  const handleChange = useCallback((list, element) => {
    switch (element.action) {
      case "select-option":
        let [addedItemList, selectOptions] = handleAddedOption(
          element.option,
          list,
          selectedOptions,
        );
        setSelected(addedItemList);
        setSelectedOption(selectOptions);
        debounced(selectOptions, addedItemList);
        break;
      case "remove-value":
        let [removedItemList, removeOptions] = handleRemovedOption(
          element.removedValue,
          list,
          selectedOptions,
        );
        setSelected(removedItemList);
        setSelectedOption(removeOptions);
        debounced(removeOptions);
        break;
      case "clear":
        handleClearAll();
        setSelectedOption([]);
        debounced([]);
        break;
      default:
        break;
    }
  });

  const animatedComponents = makeAnimated();

  return (
    <>
      {!isDetail && (
        <Paper elevation={0} className={classes.paper}>
          <Grid item>
            <Select
              id="select-vehicles"
              placeholder="Digite o nome da empresa"
              closeMenuOnSelect={false}
              isMulti
              components={animatedComponents}
              options={initialOptionsRef.current}
              styles={colourStyles}
              onChange={(list, index) => handleChange(list, index)}
              value={selected}
              noOptionsMessage={() => "Nenhum resultado encontrado"}
              zIndex={9999}
            />
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default MultiSelectUsers;
