import * as yup from "yup";

export default yup.object().shape({
  customer: yup.object().required("Campo obrigatório").nullable(),
  identification: yup.string().required("Campo obrigatório").nullable(),
  plate: yup.string().required("Campo obrigatório").max(10, "Máximo de 10 caracteres").nullable(),
  odomType: yup.object().required("Campo obrigatório").nullable(),
  initialKm: yup.string().when("odomType", {
    is: (value) => value?.value,
    then: yup.string().required("Campo Obrigatorio"),
    otherwise: yup.string().nullable(),
  }),
  maxFuelCapacity: yup.string().required("Campo obrigatório"),
  maxAcceleration: yup.string().required("Campo obrigatório"),
  chassi: yup.string().max(20, "Máximo de 20 caracteres").nullable(),
  distanceCalcMethod: yup.object().required("Campo obrigatório").nullable(),
  fuelCalcMethod: yup.object().required("Campo obrigatório").nullable(),
  accelerationIdentificationMethod: yup.object().required("Campo obrigatório").nullable(),
  maxSpeedAllowed: yup.string().required("Campo obrigatório"),
  maxSpeedAllowedWithRain: yup.string().required("Campo obrigatório"),
  leveragePeak: yup.string().required("Campo obrigatório"),
  brand: yup.object().required("Campo obrigatório").nullable(),
  model: yup.object().required("Campo obrigatório").nullable(),
});
