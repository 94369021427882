import React, { useState } from "react";
import { IconButton, Grid, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

import { format } from "date-fns";

export const makeColumns = ({size}) => {
  let current = null
  return [
    {
      name: "identification",
      label: size.mobile? ' ':"Nome",
    },
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2">
            {format(new Date(value), "dd/MM/yyyy HH:mm")}
          </Typography>
        ),
      },
    },
    {
      name: "endDate",
      label: "Data final",
      options: {
        customBodyRender: (value) => {
          if(value) {
            return (
              <Typography variant="body2">{format(new Date(value), "dd/MM/yyyy HH:mm")}</Typography>
            )
          } else {
            current = true
            return (
              <Typography variant="body2">Vínculo atual</Typography>
            )
          }
        },
      },
    },
  ];
};
