import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({

  containerInfobox: {
    height: 450, 
    display: 'flex', 
    alignItems: 'center',
  },
  containerIsComparison: {
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoBox: {
    fontSize: theme.typography.h3.fontSize,
  },
  paper: {
    borderRadius: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: "850px",
    margin: "0 auto",
  },
  message: {
    textAlign: 'left',
    padding: theme.spacing(3),
    fontSize: 18,
  },
}));
