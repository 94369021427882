import api from "./api";
import { convertStringDate } from "helpers/functions";

export const createNotification = async (data) => {

  try {
    const response = await api.post(`/api/v1/notification`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getNotifications = async (email) => {
  try {
    const response = await api.get(`/api/v1/notification?credential=${email}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const getFilterNotification = async ({ filterBy = '', users = '', startDate = '', endDate = '', status = '', platforms = '', notificationType = '', words = '' }) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/api/v1/notification/manager?filter_by=${filterBy}&users=${users}&start_date=${convertedDates.startDate}&end_date=${convertedDates.endDate}&status=${status}&platforms=${platforms}&notification_type=${notificationType}&word_filter=${words}`)
    return response
  } catch (error) {
    throw error
  }
}

export const getNotificationsManager = async (email) => {
  try {
    const response = await api.get(`/api/v1/notification/manager?credential=${email}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const getHistoricNotifications = async (notificationId) => {
  try {
    const response = await api.get(`/api/v1/notification/history?notification_id=${notificationId}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const updateNotificationsViewed = async (email) => {
  try {
    const response = await api.put(`api/v1/notification/viewed?credential=${email}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const updateNotificationById = async (data) => {
  try {
    const response = await api.put(`api/v1/notification`, data);
    return response;
  } catch (err) {
    throw err;
  }
}

export const cancelNotificationById = async (notificationId, user) => {
  try {
    const response = await api.put(`api/v1/notification/cancel?notification_id=${notificationId}&user=${user}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const getNotificationById = async (notificationId) => {
  try {
    const response = await api.get(`/api/v1/notification/${notificationId}`);
    return response;
  } catch (err) {
    throw err;
  }
}