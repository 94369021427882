import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import fields from 'domain/forms/customRewardGroup';
import schemaValidation from 'domain/validation/yup/customRewardGroup';
import makeDefaultValues from 'helpers/makeDefaultValues';
import IndicatorModel from 'models/Indicator';
import RewardGroupModel from 'models/RewardGroup';
import { createOperationType } from 'services/operationType';
import { createIndicators } from 'services/indicators';
import BackButton from 'components/BackButton';
import Custom from '../components/custom';
import Aux from 'hoc/auxiliar';
import useStyles from './styles';
import { checkAndWarnWeights } from 'helpers/checkAndWarnWeights';

const CreateCustom = () => {
  const classes = useStyles();
  const history = useHistory();
  const customer = localStorage.getItem('customers')

  const defaultValues = makeDefaultValues({
    fields,
    data: {
      customIndicatorsList: [
        {
          conduction: "",
          idealPercentage: "",
          acceptablePercentage: "",
          weight: "",
          id: "customIndicatorsList",
        },
      ],
      penalizingCustomIndicatorsList: [
        {
          conduction: "",
          idealPercentage: "",
          acceptablePercentage: "",
          weight: "",
          penalizing: true,
          id: "penalizingCustomIndicatorsList",
        },
      ],
      customControll: [],
      penalizingCustomControll: [],
      penalty_score: ''
    },
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
  });

  const resetForm = () => {
    methods.setValue("name", "");
    methods.setValue("customControll", []);
    methods.setValue("penalizingCustomControll", []);
  };

  const onSubmit = async () => {
    const result = await methods.trigger([
      "name",
      "rewardType",
      "customControll",
      'penalizingCustomControll'
    ]);

    if (result) {
      try {
        const data = methods.getValues();
        if (checkAndWarnWeights(data?.customControll)) {
          toast.warning('A soma dos pesos dos indicadores de cada quadro deve ser 100.');

          return;
        } else if (checkAndWarnWeights(data?.penalizingCustomControll)) {
          toast.warning('A soma dos pesos dos penalizadores de cada quadro deve ser 100.');

          return;
        }
        const rewardGroup = new RewardGroupModel({
          name: data.name,
          type: 2,
          description: "",
          penalty_score: Number(data.penalty_score),
          customer_id: Number(customer)
        });
        const response = await createOperationType(rewardGroup);
        let indicatorsList = [...data.customControll, ...data.penalizingCustomControll];
        const indicators = indicatorsList.map(
          (ind) =>
            new IndicatorModel({
              id: ind.id,
              lowerLimit: ind.acceptablePercentage,
              upperLimit: ind.idealPercentage,
              weight: ind.weight,
              requirementLevel: ind.requirement_level,
              penalizing: ind.penalizing || false
            }),
        );
        await createIndicators({
          operation_type_id: response.data.operation_type_id,
          indicators,
        });
        toast.success("Grupo criado com sucesso!");
        history.push("/gofurther/rewardGroup/list-rewardGroup");
        resetForm();
      } catch (err) {
        toast.error("Erro ao criar grupo!");
        console.log(err);
      }
    }
  };

  return (
    <Aux>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mt: 4 }}>
                  <Typography className={classes.explanation}>
                    * Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.reward}>
                <Custom />
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Aux>
  );
};

export default CreateCustom;
