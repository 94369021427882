import React from "react";
import "./clusterSingle.css";
import { trunkSVG } from "./variables";
import { color_blue_500, color_green_500, color_neutral_0, color_neutral_500, color_red_500, color_yellow_500 } from "@gobrax-ui/react";
import { returnNA } from "helpers/returnNA";
import StatusCar from "componentsNew/molecules/statusCar/statusCar";
import { Button } from "@material-ui/core";
import IconComponent from "components/IconComponent";
import { useModal } from "hooks/useModal";

export const colorMaps = {
  'blue': color_blue_500,
  'green': color_green_500,
  'yellow': color_yellow_500,
  'gray': color_neutral_500,
  'white': color_neutral_0,
  'red': color_red_500
}

const handleSelectVehicle = (noClick, vehicleId, closeConnection) => {
  if (!noClick ?? true) {
    if (closeConnection) {
      closeConnection()
    }
    const alreadySelectedVehicle = new URLSearchParams(window.location.search).get('id');
    if (!alreadySelectedVehicle) {
      window.location.href = `/gofurther/liveMapDetails?id=${vehicleId}`;
    }
  }
}

const ClusterSingle = ({
  noClick,
  closeConnection,
  title,
  color,
  vehicle,
  modalDetails,
  handleModalDetails,
  loadingModal
}) => {
  const { hideModal, visibleModals, showModal } = useModal();
  const vehicleData = modalDetails.current[vehicle.vehicleId];

  const toggleModal = async () => {
    if (visibleModals[vehicle.vehicleId]) {
      hideModal(vehicle.vehicleId);
    } else {
      await handleModalDetails(vehicle.vehicleId);
      showModal(vehicle.vehicleId);
    }
  };

  return (
    <div
      key={`Modal-Vehicle-${vehicle.vehicleId}`}
      className={"ClusterSingle-container"}
      style={{ backgroundColor: colorMaps[color] }}
      onClick={toggleModal}
    >
      <div className={"ClusterSingle-border"} />
      <span className={"ClusterSingle-item-icon"}>{trunkSVG}</span>
      <span className={"ClusterSingle-item-typography"}>
        {title}
        {visibleModals[vehicle.vehicleId] && <div className="ClusterSingle-modal">
          <Button
            className="ClusterSingle-close-details-button"
            onClick={() => hideModal(vehicle.vehicleId)}
          >
            <IconComponent name={'close'} size={'l'} />
          </Button>
          <StatusCar
            location={vehicleData?.Address || " - "}
            driven={`${returnNA(vehicleData?.DrivenKM)} km`}
            consumption={`${returnNA(vehicleData?.Consumption)} litros`}
            average={`${returnNA(vehicleData?.Average)} km/l`}
            fuelLevel={`${returnNA(vehicleData?.FuelLevel)}% (${returnNA(vehicleData?.ActualVolume)} litros)`}
            Autonomy={`${returnNA(vehicleData?.Autonomy)} km`}
            averageSpeed={`${returnNA(vehicleData?.AverageSpeed)} km/h`}
            highSpeedBraking={`${returnNA(vehicleData?.HighSpeedBraking)}`}
            fullBraking={`${returnNA(vehicleData?.FullBraking)}`}
            odometer={`${returnNA(vehicleData?.Odometer)}`}
            loading={loadingModal}
          />
          <Button className="ClusterSingle-show-details-button" onClick={() => handleSelectVehicle(noClick, vehicle.vehicleId, closeConnection, vehicle)}>
            Ver mais detalhes
            <IconComponent name={'arrow_right_blue'} size={'l'} />
          </Button>
        </div>}
      </span>
    </div>
  );
};

export default ClusterSingle;
