import React from "react";
import Button from '@material-ui/core/Button';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useStyles from "./styles";
import { useAppSelector } from "redux/store";
import { handleAmplitudeEvent } from "services/amplitude";

const ModalUnassignDriverToVehicle = ({ confirmOpen, data, currentDriver, handleConfirmClose, handleUnassignDriverToVehicle }) => {
  const classes = useStyles();
  const { email } = useAppSelector((state) => state.global.user);

  const handleConfirm = () => {
    const currentData = data?.rowData ? data?.rowData : data;
    const model = {
      driverId: currentData[1] ? parseInt(currentData[1].driverId) : 0,
      vehicleId: currentData[4],
      source: 1,
      execEmail: email,
    }
    handleUnassignDriverToVehicle(model)
    handleAmplitudeEvent('Driver/Vehicle Unlinked');
  }
  return (
    <Dialog
      open={confirmOpen}
      onClose={handleConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Desvincular motorista?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza que deseja desvincular o motorista <strong>{currentDriver && currentDriver.driverName}</strong> do veículo <strong>{data.identification && data.identification}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClose} className={classes.btCancel}>
          Cancelar
        </Button>
        <Button onClick={() => handleConfirm()} className={classes.btConfirm} variant="contained" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUnassignDriverToVehicle;
