import React from "react";
import { Grid } from "@material-ui/core";
import TableResponsive from "../../components/TableResponsive";

import { makeColumns } from "./tableColumns";

export default function TooltipChart({ data }) {
  const columns = makeColumns();
  const tableInfo = (
    <TableResponsive
      columns={columns}
      tableWhite
      data={[data]}
      options={{
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        pagination: false,
      }}
      toolbarNone={true}
      tableHeight={"auto"}
      tableName="tooltip-chart"
    />
  );

  return (
    <Grid container spacing={0} justifyContent="space-between">
      {tableInfo}
    </Grid>
  );
}
