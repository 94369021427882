import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import 'react-quill/dist/quill.snow.css';
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import { useAppDispatch, useAppSelector } from "../redux/store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./custom.css";

import { getUserByEmail } from "../services/user";
import { getUserPermissions } from "../services/roles";

import Layout from "./Layout";

import Error from "../pages/error";
import Login from "../pages/login";
import DashboardPrint from "../pages/dashboard/print";
import { setGlobalUser } from "redux/globalSlice";
import * as amplitude from "@amplitude/analytics-browser";
import PassRecovery from "../pages/autoService/passRecovery/passRecovery";
import { getNotifications } from "services/notifications";
import { getFirebaseToken, onMessageListener } from "firebase";
import api from "services/api";
import { formatIdToFiveCharMinimum } from "helpers/functions";
import { handleAmplitudeEvent } from "services/amplitude";
import useLoadScript from "hooks/useLoadScript";

export default function App() {
  const [userIdentity, setUserIdentity] = useState(undefined);
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.global.user);
  
  const url = `https://maps.googleapis.com/maps/api/js?key=AIzaSyApnbkideYXxPEwwRnWPEZoIciQynUABJI&libraries=places`;

  useLoadScript(url);

  const registerNotifications = async (userEmail) => {
    try {
      const responseNotifications = await getNotifications(userEmail)

      if (responseNotifications.status === 200) {
        const allNotifications = responseNotifications.data.data.filter(notification => notification.status === 'published' || notification.status === 'viewed')
        const newsNotifications = responseNotifications.data.data.filter(notification => notification.status === 'published')

        let orderNewsNotifications = [];
        if (newsNotifications.length > 0) {
          orderNewsNotifications = newsNotifications.sort((a, b) => {
            return new Date(b.publish_at) - new Date(a.publish_at);
          })
        }


        const notificationModel = {
          notifications: {
            allNotifications,
            newsNotifications: orderNewsNotifications
          }
        }
        dispatch(setGlobalUser({ notifications: notificationModel.notifications }))
      }
    } catch (error) {

    }
  }

  const requireLogin = async (to, from, next) => {
    if (to.meta.auth) {
      fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.error) {
            next.redirect("/login");
          }

          let tokenWhoami = Buffer.from(JSON.stringify(data)).toString("base64");
          const userEmail = data.identity.traits.email;
          const userName = data.identity.traits.name?.first;

          const responseUserByEmail = await getUserByEmail(userEmail);

          if (responseUserByEmail.status === 200 && responseUserByEmail?.data) {
            localStorage.setItem(
              "userId",
              responseUserByEmail.data.data.userid,
            );
            dispatch(
              setGlobalUser({ userId: responseUserByEmail.data.data.userid }),
            );
          }
          const responseUserPermissions = await getUserPermissions(userEmail);
          if (
            responseUserPermissions.status === 200 &&
            responseUserPermissions.data &&
            responseUserPermissions.data.data
          ) {
            const login_redirect =
              responseUserPermissions.data.data[0].login_redirect || "";
            localStorage.setItem("login_redirect", login_redirect);
            localStorage.setItem(
              "roles",
              Buffer.from(
                JSON.stringify(responseUserPermissions.data.data),
              ).toString("base64"),
            );
            dispatch(
              setGlobalUser({
                roles: Buffer.from(
                  JSON.stringify(responseUserPermissions.data.data),
                ).toString("base64"),
              }),
            );
          }
          dispatch(
            setGlobalUser({ name: userName, email: userEmail, token: tokenWhoami }),
          );
          responseUserByEmail.data?.data?.userid && amplitude.setUserId(formatIdToFiveCharMinimum(responseUserByEmail.data?.data?.userid));

          !token && handleAmplitudeEvent('Login')
          const identify = new amplitude.Identify()
          identify
            .set("name", userName)
            .set("email", userEmail)

          amplitude.identify(identify)
          getFirebaseToken().then(async (firebaseToken) => {
            if (firebaseToken) {
              try {
                await api.post('/api/v1/notification/credential', {
                  "platform": "web",
                  "credential": userEmail,
                  "token": firebaseToken
                })
              } catch (err) {

              }
              registerNotifications(userEmail)
              onMessageListener().then(payload => {
                console.log(payload, 'onmessagelistener');
                registerNotifications(userEmail)
              }).catch(err => console.log('failed: ', err));
            }
          })
          setUserIdentity(data);
          next();
        });
    } else {
      next();
    }
  };

  

  return (
    <Router>
      <ToastContainer />

      <GuardProvider guards={[requireLogin]} error={Error}>
        <Switch>
          <GuardedRoute exact path="/login" component={Login} />
          <GuardedRoute
            exact
            path="/autoservice/passrecovery"
            component={PassRecovery}
          />
          <GuardedRoute path="/gofurther" component={Layout} />
          <GuardedRoute
            exact
            path="/dashboard/print"
            component={DashboardPrint}
          />
          <Route path="/fleet">
            <Redirect to="/" />
          </Route>
          {userIdentity ? (
            <Route
              path="/"
              render={() => <Redirect to="gofurther/bonus/follow" />}
            />
          ) : (
            <Route path="/" exact render={() => <Redirect to="/login" />} />
          )}
          <GuardedRoute component={Error} />
        </Switch>
      </GuardProvider>
    </Router>
  );
}
