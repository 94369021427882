import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, FormHelperText, Box } from "@material-ui/core";
import { useWatch, useFormState, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { Checkbox, Input, Select } from "components/react-hook-form";
import { cpfMask, dateMask, phoneMask, removeSpecialCharactersAndNumbersMask } from "helpers/masks";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { orderByName } from "helpers/functions";
import useStyles from "./styles";
import FormDriverSkeleton from "../Skeletons/FormDriverSkeleton";
import { getCustomersById } from "services/customers";
import Aux from "hoc/auxiliar";
import { useAppSelector } from "redux/store";
import { hasPermission } from "components/PermissionsGate";
import { getGroupedDriverRoles } from 'helpers/permissions';
import { getAllPermissionRoles } from 'services/roles'


const DriverForm = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const [customers, setCustomers] = useState(null);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  
  const fetchCustomerList = async () => {
    try {
      const response = await getCustomersById(currentCustomer);
      const customerOptions = makeSelectOptions({
        data: response.data?.customers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });
      const orderOptions = orderByName(customerOptions);
      setCustomers(orderOptions);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de clientes. Entre em contato com o suporte.",
      );
      console.log(err);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getAllPermissionRoles();
      const permissions = response.status === 200 && getGroupedDriverRoles(response.data.data);
      const accessTypes = [];
      permissions.forEach((item) => {
        if (item.label === "Super Administrador" && hasPermission({ scopes: ["view_super_admin_option"] })) {
          accessTypes.push({ label: item.name, value: item.role });
        } else if (item.name !== "Super Administrador") {
          accessTypes.push({ label: item.name, value: item.role });
        }
      })
      setRoles(accessTypes);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de perfis. Entre em contato com o suporte!",
      );
    }
  }

  const birthDateValue = useWatch({ control, name: "birthDate" });
  const customerOption = useWatch({ control, name: "customerOption" });
  const documentNumber = useWatch({ control, name: "documentNumber" });
  const phoneValue = useWatch({ control, name: "phone" });
  const nameValue = useWatch({ control, name: "name" });
  const canLink = useWatch({ control, name: "canLink" })
  const role = useWatch({ control, name: "role" })

  useEffect(() => {
    if (customers?.length > 0) {
      setValue(
        "customer",
        customers.find((clt) => clt.value === customerOption),
      );
    }
  }, [customers, customerOption]);

  useEffect(() => {
    if (birthDateValue) {
      setValue("birthDate", dateMask(birthDateValue));
    }
  }, [birthDateValue]);

  useEffect(() => {
    if (nameValue) {
      setValue("name", removeSpecialCharactersAndNumbersMask(nameValue));
    }
  }, [nameValue, setValue]);

  useEffect(() => {
    if (documentNumber) {
      setValue("documentNumber", cpfMask(documentNumber));
    }
  }, [documentNumber]);

  useEffect(() => {
    if (phoneValue) {
      setValue("phone", phoneMask(phoneValue));
    }
  }, [phoneValue]);

  useEffect(() => {
    if (canLink) {
      setValue("canLink", canLink);
    }
  }, [canLink]);

  useEffect(() => {
    if (currentCustomer) {
      fetchRoles()
      fetchCustomerList();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (role && roles.length > 0) {
      setSelectedRole(roles?.find(item => item.value === role))
    }
  }, [role, roles]);

  if (!customers) {
    return <FormDriverSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Empresa</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Select
                    options={customers}
                    name="customer"
                    placeholder="Selecione uma empresa *"
                  />
                  <FormHelperText className={classes.formHelperText} error={true}>
                    {errors.customer?.message}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Dados do motorista</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="name"
                    variant="outlined"
                    fullWidth
                    label="Nome *"
                    helperText={errors.name?.message}
                    error={errors.name && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="documentNumber"
                    variant="outlined"
                    fullWidth
                    label="CPF *"
                    helperText={errors.documentNumber?.message}
                    error={errors.documentNumber && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="birthDate"
                    variant="outlined"
                    fullWidth
                    label="Data de nascimento"
                    placeholder="__/__/____"
                  />
                </Box>
              </Grid>
              {(hasPermission({ scopes: ["can_update_users_roles"] })) &&
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Select
                      options={roles}
                      name="role"
                      placeholder="Tipo de acesso *"
                      zIndex={9998}
                      onSelect={() => setValue("role", role)}
                      value={selectedRole}
                    />
                    <FormHelperText className={classes.formHelperText} error={true}>
                      {errors.role?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Formatos de acesso ao aplicativo</Typography>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="phone"
                    variant="outlined"
                    fullWidth
                    label="Telefone celular *"
                    helperText={errors.phone?.message}
                    error={errors.phone && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="email"
                    variant="outlined"
                    fullWidth
                    label="E-mail"
                    helperText={errors.email?.message}
                    error={errors.email && true}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Checkbox checked={canLink} name="canLink" label="Permitir vínculo pelo aplicativo?" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Anotações sobre o motorista</Typography>
            <Grid container>
              <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Input
                    name="obs"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1}
                    label="Observações"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>*Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
              <Typography className={classes.explanation}>*É possível cadastrar o motorista sem informar o telefone e/ou e-mail, porém, nesse caso, ele não terá acesso ao aplicativo. Essa opção é destinada apenas para o controle do gestor via web. Caso deseje que os motoristas utilizem o aplicativo, por favor, informe o telefone e/ou e-mail.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default DriverForm;

