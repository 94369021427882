import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  legendTitle: {
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    fontFamily: "Roboto",
    color: "#595959",
    "& label.Mui-focused": {
      color: "#595959",
    },
  },
}));
