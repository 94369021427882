import React from 'react';
import './cardValue.css';

export const CardValue = ({ value, valueStyle, ...props }) => {
  return (
    <div
      className={`CardValue-container`}
      style={valueStyle}
      {...props}
    >
      {value}
    </div>
  );
};

CardValue.defaultProps = {
  value: 0,
};
