import React from "react";
import { Grid} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowSize } from "hooks/useWindowsSize";

const HistoricVehiclesSkeleton = () => {
  const classes = useStyles();
  const size = useWindowSize();
  return (
    <Aux>
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={550}
            className={classes.bordered}
          />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  bordered: {
    borderRadius: "22px",
  },
}));

export default HistoricVehiclesSkeleton;
