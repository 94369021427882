import React from "react";
import BackdropMaterial from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles";

export default function Backdrop(props) {
  const classes = useStyles();

  return (
    <BackdropMaterial className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </BackdropMaterial>
  );
}
