import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Swal from "sweetalert2";
import Aux from "hoc/auxiliar";
import Widget from "components/Widget/Widget";
import { getDrivers, disableDriver, deleteDriver } from "services/driver";
import { makeColumns } from "./tableColumns";
import useStyles from "./styles";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { useAppSelector } from "redux/store";
import TableResponsive from "../../../components/TableResponsive";
import { useWindowSize } from "../../../hooks/useWindowsSize";
import Table from "../../../components/Table";
import classNames from "classnames";
import { handleAmplitudeEvent } from "services/amplitude";

const AllDrivers = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [dataTable, setDataTable] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
  });
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const handleEdit = (id) => {
    const driver = dataTable.filter((driver) => driver.id === id);
    handleAmplitudeEvent('Edit Button Clicked', { driver_id: driver[0].id, driver_name: driver[0].name })
    history.push(`/gofurther/driver/edit/${id}`)
  };

  const handleDisable = (value) => {
    const driver = dataTable.filter((driver) => driver.id === value);
    handleAmplitudeEvent('Delete Button Clicked', { driver_id: driver[0].id, driver_name: driver[0].name })
    Swal.fire({
      title: "Tem certeza que deseja desabilitar o motorista?",
      text: "Essa ação irá desabilitar o motorista!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, desabilitar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await disableDriver(value);
          if (response.status === 200) {
            setDataTable((oldState) =>
              oldState.filter((driver) => driver.id !== value),
            );
            toast.success("Motorista desabilitado com sucesso! ");
            Swal.fire(
              "Desabilitado!",
              "O registro foi desabilitado com sucesso",
              "success",
            );
            handleAmplitudeEvent("Driver Disabled");
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao desabilitar motorista. Entre em contato com o suporte.",
          );
        }
      }
    });
  };

  const handleDelete = (value) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir o motorista?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteDriver(value);
          if (response.status === 200) {
            setDataTable((oldState) =>
              oldState.filter((driver) => driver.id !== value),
            );
            toast.success("Motorista excluído com sucesso! ");
            Swal.fire(
              "Excluído!",
              "O registro foi excluído com sucesso",
              "success",
            );
            handleAmplitudeEvent("Driver Deleted");
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir motorista. Entre em contato com o suporte.",
          );
        }
      }
    });
  };

  const size = useWindowSize();

  const handleClickAddDriver = () => {
    handleAmplitudeEvent('New Driver Clicked')
    history.push("/gofurther/driver/add-driver")
  };
  const handleClickMassAddDriver = () => {
    handleAmplitudeEvent('Mass Import Clicked')
    history.push("/gofurther/driver/mass-add-driver")
  };
  const handleRecalculation = () => {
    handleAmplitudeEvent('Bonus Recalculation Clicked');
    history.push("/gofurther/driver/recalculation")
  };

  const fetchDrivers = useCallback(async () => {
    try {
      setDownload({
        link: `/drivers/export?customers=${currentCustomer}`,
        fileName: `motoristas_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
      });
      const response = await getDrivers(currentCustomer);
      setDataTable(response.data?.drivers ?? []);
    } catch (error) {
      console.log(error);
      toast.error(
        "Erro buscar lista de motoristas. Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (hasPermission({ scopes: ["can_view_drivers"] })) {
      fetchDrivers();
    }
  }, [fetchDrivers]);

  const handleSelectRow = useCallback((data, data2) => {
    setId(data2.dataIndex);
    const id = dataTable[data2.dataIndex].id;
    setSelectedRowData(id);
  }, [dataTable]);

  const columns = makeColumns({
    onDisable: handleDisable,
    onEdit: handleEdit,
    onDelete: handleDelete,
    size,
  });

  useEffect(() => {
    handleAmplitudeEvent('Drivers Screen Viewed')
  }, [])

  const renderTable = useCallback(
    () =>
      size.mobile ? (
        <TableResponsive
          columns={columns}
          data={dataTable}
          download={download}
          pointer={true}
          tableName="list-drivers"
          setRowsPerPage={setRowsPerPage}
          options={{
            onRowClick: handleSelectRow,
            rowsPerPage: rowsPerPage,
            setRowProps: (row, index) => {
              return index === id
                ? {
                  style: {
                    backgroundColor: "#FFF5CB",
                    cursor: "pointer",
                    width: "100%",
                  },
                }
                : null;
            },
          }}
        />
      ) : (
        <Table
          columns={columns}
          data={dataTable}
          download={download}
          tableName="list-drivers"
          options={{ rowsPerPage }}
          setRowsPerPage={setRowsPerPage}
        />
      ),
    [
      size.mobile,
      columns,
      dataTable,
      download,
      handleSelectRow,
      rowsPerPage,
      id,
    ],
  );

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_drivers"]}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end">
              <PermissionsGate scopes={["can_recalculate_drivers"]}>
                <Grid
                  item
                  xl={2}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.btItem}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleRecalculation}
                  >
                    Recalcular bonificação
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={["can_import_drivers"]}>
                <Grid
                  item
                  xl={2}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  className={classes.btItem}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickMassAddDriver}
                  >
                    Importar em Massa
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={["can_create_drivers"]}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddDriver}
                  >
                    Novo Motorista
                  </Button>
                </Grid>
              </PermissionsGate>
            </Grid>
            <Widget disableWidgetMenu title="Gerenciamento de motoristas" helpButton>
              {renderTable()}
            </Widget>
          </Grid>
          {/* botoes */}
          {size.mobile ? (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => handleEdit(selectedRowData)}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Editar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box>
                    <Button
                      fullWidth
                      variant="outlined"
                      className={classNames(
                        classes.buttonDetail,
                        {[classes.buttonDelete]:selectedRowData}
                    )}
                      onClick={() => handleDisable(selectedRowData)}
                      disabled={!selectedRowData}
                    >
                      Excluir
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AllDrivers;
