/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import Aux from "hoc/auxiliar";
import VehicleProfileForm from "components/VehicleProfileForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import makeDefaultValues from "helpers/makeDefaultValues";

import fields from "domain/forms/formVehicleProfileInitialValues";
import schemaValidation from "domain/validation/yup/formVehicleProfileValidation";

import { createVehicleProfile } from "services/profiles";
import { getVehicleProfile, updateVehicleProfile } from "services/profiles";

import { useInputsProfile } from "context/useInputsProfile";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const AddProfile = () => {
  const history = useHistory();
  const classes = useStyles();
  const { inputs, setInputs, resetInputs, isFormValid } = useInputsProfile()
  
  const { profileId } = useParams();
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data) => {
    const formData = inputs.reduce((obj, input) => {
      obj[input.name] = parseInt(input.value);
      return obj;
    }, {});
    
    formData.neutralRangeStart = 0;
    formData.neutralRangeEnd = formData.whiteRangeStart - 1;
    formData.whiteRangeEnd = formData.extraEconomicRangeStart - 1;
    formData.extraEconomicRangeEnd = formData.economicRangeStart - 1;
    formData.powerRangeEnd = formData.engineBrakeRangeStart - 1;
    formData.economicRangeEnd = formData.powerRangeStart - 1;
    formData.engineBrakeRangeEnd = formData.redRangeStart - 1;
    formData.modelId = data.modelId.id;
    formData.brandId = data.brandId.value
    try {
      const response = profileId ? await updateVehicleProfile(profileId, formData) : await createVehicleProfile(formData);

      if (response.status === 200) {
        profileId ? toast.success("Perfil atualizado com sucesso!") : toast.success("Perfil criado com sucesso!")
        resetInputs()
        methods.reset(defaultValues)
        history.push("/gofurther/profile/list-profiles")
      }
    } catch (error) {
      profileId ? toast.error("Erro ao atualizar o perfil. Verifique com o suporte") : toast.error("Erro ao criar Perfil. Verifique com o suporte");
    }
  };

  const getInitialInputsState = (getValues) => {
    const fieldsToFill = [
      { name: 'whiteRangeStart', title: 'Branca' },
      { name: 'extraEconomicRangeStart', title: 'Início faixa verde' },
      { name: 'economicRangeStart', title: 'Final faixa verde' },
      { name: 'powerRangeStart', title: 'Acelerando acima do verde' },
      { name: 'engineBrakeRangeStart', title: 'Freio motor' },
      { name: 'redRangeStart', title: 'Vermelha' },
      { name: 'redRangeEnd', title: 'Final vermelha' },
    ];
  
    return fieldsToFill.map(field => {
      const value = getValues[field.name] || '';
      return { ...field, value, enabled: true, invalid: false };
    });
  };

  const fetchProfileDetail = async () => {
    try {
      const responseProfile = await getVehicleProfile(profileId);

      const { profile } = responseProfile.data.data
      const data = getInitialInputsState(profile)
      setInputs(data);
      methods.setValue("modelIdOption", profile.modelId);
      methods.setValue("brandIdOption", profile.brandId);
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do Perfil. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (profileId) {
      if (hasPermission({ scopes: ['can_edit_profiles'] })) {
        resetInputs()
        fetchProfileDetail();
      }
    } else {
      resetInputs()
    }
  }, [profileId])

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_profiles', 'can_edit_profiles']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>{profileId ? 'Edição de perfil' : 'Cadastro de perfil'}</Typography>
                    <VehicleProfileForm />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                disabled={!isFormValid()}
                                className={classes.btConfirm}
                              >
                                {profileId ? 'Atualizar' : 'Salvar'}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>  
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddProfile;
