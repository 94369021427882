import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import classNames from "classnames";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import { deleteUser, getUserByCustomers } from "services/user";
import { deleteUserPermissions, getUsersRoles } from "services/roles";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import ListUsersSkeleton from "components/Skeletons/ListUsersSkeleton";
import { useAppSelector } from "redux/store";
import TableResponsive from "../../../components/TableResponsive";
import {useWindowSize} from "../../../hooks/useWindowsSize";

const ListUsers = () => {
  const { listCustomers } = useAppSelector((state) => state.global.user);
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const fetchUsersRole = useCallback(async () => {
    try {
      const response = await getUsersRoles();
      if (response.status === 200) {
        fetchUsers(response.data.data);
      }
    } catch (err) {
      return err;
    }
  }, []);

  const fetchUsers = useCallback(async (roles) => {
    try {
      setId()
      setSelectedRowData()
      setLoading(true)
      const response = await getUserByCustomers(listCustomers);
      if (!response.data || !response.data?.user) {
        return;
      }
      const users = response.data?.user.map(item => {
        const totalCustomers = item.customers.length - 1;
        let customersName = '';
      
        if (item.customers.some(customer => customer.id === 0)) {
          customersName = 'TODAS AS EMPRESAS';
        } else {
          customersName = item.customers.map((customer, index) => index < totalCustomers ? `${customer.name} • ` : customer.name);
        }
      
        const role = roles.find(elm => elm.user === item.email);
        return {
          id: item.id,
          custom: {
            id: item.id,
            email: item.email
          },
          customerName: customersName,
          name: item.name,
          email: item.email,
          role: role ? role.rolename : ''
        }
      });
      setUsers(users);
    } catch (err) {
      toast.error("Erro ao carregar usuários. Entre em contato com o suporte.");
    } finally {
      setLoading(false)
    }
  }, [listCustomers]);

  const handleClickAddUser = () => history.push("/gofurther/user/form-user");
  const handleEdit = (value) => history.push(`/gofurther/user/form-user/${value}`);
  const handleDelete = (value) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteUser(value.id);
          if (response.status === 200) {
            const responsePermission = await deleteUserPermissions(value.email);
            if (responsePermission.status === 200) {
              Swal.fire(
                "Excluído!",
                "O registro foi excluído com sucesso",
                "success",
              );
              fetchUsersRole();
            }
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir usuário. Entre em contato com o suporte.",
          );
        }
      }
    });
  };

  const size = useWindowSize()
  const columns = makeColumns({ onEdit: handleEdit, onDelete: handleDelete, size });

  useEffect(() => {
    if (listCustomers && hasPermission({ scopes: ['can_view_users'] })) {
      fetchUsersRole();
    }
  }, [fetchUsersRole, listCustomers]);



  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);
    const id = users[data2.dataIndex].id;
    setSelectedRowData(id)
  }

  const renderTable = useCallback(() => (
    size.mobile ?
      <TableResponsive
        options={{
          onRowClick: handleSelectRow,
          rowsPerPage: rowsPerPage,
          setRowProps: (row, index) => {
            return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
          },
        }}
        pointer={true}
        columns={columns}
        data={users}
        tableName="list-users"
        setRowsPerPage={setRowsPerPage}
      /> :
      <Table
        columns={columns}
        data={users}
        tableName="list-users"
        options={{ rowsPerPage }}
        setRowsPerPage={setRowsPerPage}
      />
  ), [columns, users, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_users']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_users']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddUser}
                  >
                    Novo Usuário
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de usuários">
              {loading ? <ListUsersSkeleton /> : renderTable()}
              {/* botoes */}
              {size.mobile ?
                <PermissionsGate scopes={['can_edit_users', 'can_remove_users']}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <PermissionsGate scopes={['can_edit_users']}>
                        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                          <Box >
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => handleEdit(selectedRowData)}
                              disabled={!selectedRowData}
                              className={classes.buttonDetail}
                            >
                              Editar
                            </Button>
                          </Box>
                        </Grid>
                      </PermissionsGate>
                      <PermissionsGate scopes={['can_remove_users']}>
                        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                          <Box >
                            <Button
                              fullWidth
                              variant="outlined"
                              className={classNames(
                                classes.buttonDetail,
                                {[classes.buttonDelete]:selectedRowData}
                            )}
                              onClick={() => handleDelete(users[id])}
                              disabled={!selectedRowData}
                            >
                              Excluir
                            </Button>
                          </Box>
                        </Grid>
                      </PermissionsGate>
                    </Grid>
                  </Grid>
              </PermissionsGate>:null}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListUsers;
