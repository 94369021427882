import { createSlice } from "@reduxjs/toolkit";
import { defaultOperation, selectFilterOptions } from "../../helpers/follow";

const TODAY = new Date();

const initialState = {
  printCode: null,
  fields: {
    step: null,
  },
  filters: {
    operation: defaultOperation,
    year: {
      value: TODAY.getFullYear(),
      label: TODAY.getFullYear(),
    },
    cutoffDate: null,
    calendar: null,
    isCalendarFilter: selectFilterOptions[0],
  },
  totalPercentage: null,
  goal: { total: 0, monthly: 0 },
  drivers: null,
  download: {
    link: "",
    fileName: "",
    params: {
      drivers: [],
      startDate: null,
      endDate: null,
      limit: null,
    },
  },
};

export const bonusFollowSlice = createSlice({
  name: "bonusFollow",
  initialState,
  reducers: {
    setBonusFilters: (state, action) => {
      const { name, selectedOption } = action.payload;
      switch (name) {
        case "cutoffDate":
          state.filters[name] = JSON.stringify(selectedOption);
          break;
        case "calendar":
          state.filters[name] = JSON.stringify(selectedOption);
          break;
        default:
          state.filters[name] = selectedOption;
          break;
      }
    },
    clearCalendar: (state) => {
      state.filters.calendar = initialState.filters.calendar;
    },
    setTotalPercentageReducer: (state, action) => {
      state.totalPercentage = action.payload;
    },
    setGoalReducer: (state, action) => {
      state.goal = action.payload;
    },
    setDriversReducer: (state, action) => {
      state.drivers = action.payload;
    },
    clearBonusFilters: (state) => {
      state.fields.step = null;
      state.filters = initialState.filters;
      state.totalPercentage = initialState.totalPercentage;
      state.goal = initialState.goal;
      state.drivers = initialState.drivers;
      state.print = initialState.print;
    },
    setStepAdd: (state) => {
      if (!state.fields.step) {
        state.fields.step = 1;
      } else {
        state.fields.step = state.fields.step + 1;
      }
    },
    setStepDeductBonus: (state) => {
      state.fields.step = state.fields.step - 1;
    },
    setIsCalendarFilter: (state, action) => {
      state.filters.isCalendarFilter = action.payload;
    },
    resetStep: (state) => {
      state.fields = initialState.fields;
    },
    resetTotalPercentage: (state) => {
      state.totalPercentage = initialState.totalPercentage;
    },
    resetYear: (state) => {
      state.filters.year = initialState.filters.year;
    },
    resetCutoffDate: (state) => {
      state.filters.cutoffDate = initialState.filters.cutoffDate;
    },
    resetCalendar: (state) => {
      state.filters.calendar = initialState.filters.calendar;
    },
    setPrintData: (state, action) => {
      state.printCode = action.payload;
    },
    setDownloadFollow: (state, action) => {
      state.download = action.payload;
    },
    clearPrintData: (state, action) => {
      state.print = initialState.print;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearBonusFilters,
  clearCalendar,
  clearPrintData,
  resetCalendar,
  resetCutoffDate,
  resetStep,
  resetTotalPercentage,
  resetYear,
  setBonusFilters,
  setDownloadFollow,
  setDriversReducer,
  setGoalReducer,
  setIsCalendarFilter,
  setPrintData,
  setStepAdd,
  setStepDeductBonus,
  setTotalPercentageReducer,
} = bonusFollowSlice.actions;

export default bonusFollowSlice.reducer;
