import React from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { convertDateBr }  from "helpers/dates";
import classNames from "classnames";
import makeStyles from "./styles";
import { makeValueStars, valueToPositiveFloat } from "../../helpers/functions";

export const makeColumns = () => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classNames({
          [classes.textBold]: textBold,
        })}
        noWrap={true}
      >
        {data}
      </Typography>
    );
  };

  const getStars = (data) => {
    return (
      <Tooltip title={`Nota: ${data}`}>
        <Box>
          <Rating
            name="half-rating-read"
            value={makeValueStars(data)}
            precision={0.1}
            readOnly
            size="small"
          />
        </Box>
      </Tooltip>
    );
  };

  return [
    {
      name: "origin",
      label: "Origem",
      options: {
        customBodyRender: (value) =>
          getCell(`${convertDateBr(value.date)} - ${value.name}`, true),
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        customBodyRender: (value) =>
          getCell(`${convertDateBr(value.date)} - ${value.name}`, true),
      },
    },
    {
      name: "driverName",
      label: "Motorista",
    },
    {
      name: "achievedMileage",
      label: "Dist. percorrida",
      options: {
        customBodyRender: (value) => `${valueToPositiveFloat(value, 2)} Km`,
      },
    },
    {
      name: "score",
      label: "Nota geral",
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => getStars(value),
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => getStars(value),
      },
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Direção extra econômica",
      options: {
        customBodyRender: (value) => getStars(value),
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => getStars(value),
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => getStars(value),
      },
    },
  ];
};
