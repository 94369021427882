import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title
  },
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    borderRadius: 28
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400
  },
  formHelperText:{
    marginLeft: 15
  }
}));
