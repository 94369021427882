import api from "./api";
import { convertStringDate } from "helpers/functions";

const arrayDataUrl = (arrayData = "") => {
  let partialUrl = "";
  if (arrayData) {
    partialUrl = Array.isArray(arrayData) ? arrayData.join() : arrayData;
  }
  return partialUrl;
};

export const getDrivers = async (customers) => {
  const partialUrl = arrayDataUrl(customers);
  let params = `customers=${partialUrl}`;
  try {
    const response = await api.get(`/drivers?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDriversByOperation = async (operationId, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`drivers-prfc/byOperation?operationId=${operationId}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAssignedVehicles = async (vehicleId, driverId, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/vehiclesDriversAssigned?driverId=${driverId}&vehicleId=${vehicleId}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};


export const getDriverDetail = async (id) => {
  try {
    const response = await api.get(`/drivers/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createDriver = async (data) => {
  try {
    const response = await api.post(`/drivers`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const disableDriver = async (id) => {
  try {
    const response = await api.delete(`/drivers/disableDriver?driver_id=${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteDriver = async (id) => {
  try {
    const response = await api.delete(`/drivers/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDriversHistory = async (vehicleId) => {
  try {
    const response = await api.get(`/vehicles/driversHistory?vehicleId=${vehicleId}`);
    return response;
  } catch (error) {
    return error
  }
}


export const updateDriver = async (data) => {
  try {
    const response = await api.put(`/drivers/${data.id}`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDriversPerformance = async (drivers, vehicles, startDate, endDate, limit) => {
  const paramsDrivers = `drivers=${arrayDataUrl(drivers)}`;
  const paramsVehicles = `vehicles=${arrayDataUrl(vehicles)}`;
  const convertedDates = convertStringDate(startDate, endDate);
  if (!limit) {
    limit = 10000;
  }
  try {
    const response = await api.get(`/web/v2/performance/drivers/efficiency/multiple?${paramsDrivers}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&limit=${limit}&${paramsVehicles}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const importTemplateMassDrivers = async () => {
  try {
    const response = await api.get(`/drivers/importDriverTemplate`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createMassDrivers = async (data) => {
  try {
    const response = await api.post(`/drivers/import`, data);
    return response;
  } catch (err) {
    throw err;
  }
};
