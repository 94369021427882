import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  center: {
    textAlign: "center",
  },
  textBold: {
    fontWeight: "bold",
  },
  text400: {
    fontWeight: "400",
  },
  textInfo: {
    color: theme.palette.text.secondary,
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  actionContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  actionButton: {
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
      fontSize: 28,
    },
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  paperText: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
    borderRadius: 28,
    margin: 5,
  },
  titleText: {
    fontWeight: "600",
    marginRight: 4,
  },
  btnNew: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600,
    },
  },
}));
