import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-toastify";
import BackButton from "components/BackButton";
import Aux from "hoc/auxiliar";
import FormVehicleGroupComponent from "components/FormVehicleGroupComponent";
import LinkVehicleGroupComponent from "components/LinkVehicleGroupComponent";
import OperationRegisterSkeleton from "components/Skeletons/OperationRegister";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import operationValidation from "domain/validation/yup/operationValidation";
import operationValidationMinScore from "domain/validation/yup/operationValidationMinScore";
import fields from "domain/forms/operationInitialValues";
import makeDefaultValues from "helpers/makeDefaultValues";
import { getOperationById, updateOperation } from "services/operations";

import useStyles from "./styles";
import { getVehiclesByOperation } from "services/vehicle";

import { useAppSelector } from "redux/store";
import ModalConfirm from "../../../../components/ModalConfirm";
import { handleAmplitudeEvent } from "services/amplitude";
const EditOperation = ({ history }) => {
  const { id } = useParams();
  const classes = useStyles();
  const { email, currentCustomer } = useAppSelector((state) => state.global.user);
  const [unavailableVehicles, setUnavailableVehicles] = useState([])
  const [availableVehicles, setAvailableVehicles] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [dataOld, setdataOld] = useState({});

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: hasPermission({ scopes: ['can_create_minscore'] }) ? yupResolver(operationValidationMinScore) : yupResolver(operationValidation)
  });

  const getAvailableVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer)
      const { customers: operations } = response.data
      let availableVehicles = []
      operations.forEach(op => {
        if (Number(op.id) === 0) {
          op.vehicles.forEach(vehicle => {
            availableVehicles.push(vehicle)
          })
        }
      })
      setAvailableVehicles([...new Set(availableVehicles)])
    } catch (error) {
      throw new Error()
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])

  const fetchOperationById = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await getOperationById(id);
      if (response.status === 200 || response.status === 201) {
        const { data } = response.data
        if (data.operation) {
          methods.setValue("customerId", data.operation.customerId);
          methods.setValue("targetMileage", data.operation.reward.targetMileage);
          methods.setValue("maxAmount", data.operation.reward.maxAmount);
          if (data.operation.minScore !== undefined) {
            methods.setValue("minScore", data.operation.minScore);
          }
          methods.setValue("name", data.operation.name);
          methods.setValue("id", data.operation.id);
          methods.setValue("operationTypeOption", data.operation.operationType);
          methods.setValue("requirementLevelOption", data.operation.requirementLevel);
          //cadastra os dados para realizar a validação, caso tenha algum dado diferente
          setdataOld({
            customerId: data.operation.customerId,
            requirementLevelOption: data.operation.requirementLevel,
            targetMileage: data.operation.reward.targetMileage.toString(),
            maxAmount: data.operation.reward.maxAmount.toString(),
            minScore: data.operation.minScore.toString(),
            name: data.operation.name,
            id: data.operation.id,
            operationTypeOption: data.operation.operationType,

          });
          if (data.operation.vehicles) {
            const vehiclesUnavailable = data.operation.vehicles.map((vehicle) => ({
              ...vehicle,
              id: vehicle.vehicleId,
            }));
            setUnavailableVehicles([...new Set(vehiclesUnavailable)])
          } else {
            setUnavailableVehicles([])
          }
        }
      } else {
        return toast.error("Erro ao buscar os dados da operação. Contate o suporte!");
      }
    } catch (error) {
      return toast.error("Erro estranho aconteceu. Contate o suporte!");
    } finally {
      setLoading(false)
    }
  }, [id])

  //Registra os veiculos que já existem
  useEffect(() => {
    if (dataOld.vehicles === undefined) {
      setdataOld({
        ...dataOld, vehicles: selectedVehicles?.map((vehicle) => ({
          vehicleId: vehicle.id,
        })),
      })
    }
  }, [selectedVehicles])

  const handleSelectedVehicles = (selected) => setSelectedVehicles(selected);


  const onSubmit = async (data) => {
    const vehicles = selectedVehicles?.map((vehicle) => ({
      vehicleId: vehicle.id,
    }))

    const isOtherDataChanged = data.customerId !== dataOld.customerId || data.requirementLevel.value !== dataOld.requirementLevelOption || data.targetMileage !== dataOld.targetMileage || data.maxAmount !== dataOld.maxAmount || data.minScore !== dataOld.minScore || data.id !== dataOld.id || JSON.stringify(vehicles) !== JSON.stringify(dataOld.vehicles) || data.operationType.value !== dataOld.operationTypeOption;

    if (isOtherDataChanged) {
      setDialogData(data)
      setDialog(true)
    } else {
      onSubmitWithoutChanges(data)
    }
  }

  const onSubmitWithoutChanges = async (data) => {
    const submitModel = {
      customerId: Number(data.customerId),
      sourceId: Number(data.id),
      name: data.name,
      reward: {
        maxAmount: Number(data.maxAmount),
        targetMileage: Number(data.targetMileage)
      },
      operationType: Number(data.operationType.value),
      requirementLevel: Number(data.requirementLevel.value),
      vehicles: selectedVehicles?.map((vehicle) => ({
        vehicleId: vehicle.id,
      })),
      minScore: data.minScore ? Number(data.minScore) : 0,
      execEmail: email
    }
    //verificaçao de veiculos selecionados
    //os veiculos que permanecerem inalterados, devem ser retirados do array a ser enviado
    let vehiclesToCompare = [...unavailableVehicles];
    let currentSelectedsVehicles = [...selectedVehicles];
    //Caso estamos em Edit e a lista de veiculos selecionados é 0, isso quer dizer que todos os veiculos que estavam antes nela(unavailableVehicles), 
    //devem ser deletados
    if (currentSelectedsVehicles.length === 0) {
      currentSelectedsVehicles = vehiclesToCompare.map((item) => ({
        vehicleId: item.id,
        delete: true,
      }));
    } else {
      vehiclesToCompare.forEach((vehicle) => {
        const findedVehicle = currentSelectedsVehicles.find(
          (truck) => truck.plate === vehicle.plate,
        );
        if (findedVehicle) {
          currentSelectedsVehicles = currentSelectedsVehicles.filter(
            (truck) => truck.plate !== vehicle.plate,
          );
        } else {
          currentSelectedsVehicles.push({
            id: vehicle.id,
            delete: true,
          });
        }
      });

      await onSubmitUpdate({
        ...submitModel,
        vehicles: currentSelectedsVehicles.map((truck) => ({
          ...truck,
          vehicleId: truck.id,
        })),
        id: data.id,
        sourceId: 1,
        execEmail: email
      });
    }
  };

  const onSubmitUpdate = async (data) => {
    const response = await updateOperation(data);
    if (response.status === 200 || response.status === 201) {
      handleAmplitudeEvent('Vehicle Group Edited');
      toast.success("Operação atualizado com sucesso");
      history.push(`/gofurther/maintenance/operations/operationList`)
    } else {
      toast.error("Erro ao editar Operação. Verifique com o suporte");
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_operations'] }) && currentCustomer) {
      getAvailableVehicles()
    }
  }, [currentCustomer, getAvailableVehicles])

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_operations'] })) {
      id && fetchOperationById(id);
    }
  }, [id, fetchOperationById]);

  useEffect(() => {
    handleAmplitudeEvent('Edit Vehicle Group Screen Viewed')
  }, [])

  if (loading) return <OperationRegisterSkeleton />
  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_operations']}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 1, mt: 4 }}>
                    <Typography variant='h1' className={classes.title}>Grupo de veículos</Typography>
                    <FormVehicleGroupComponent
                      register={methods.register}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 1, mt: 4 }}>
                    <Typography variant="h1" className={classes.title}>Associar veículos ao grupo</Typography>
                    <LinkVehicleGroupComponent
                      handleSelectedVehicles={handleSelectedVehicles}
                      availableVehicles={availableVehicles}
                      unavailableVehiclesByOperation={unavailableVehicles}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <BackButton className={classes.backButton} />
                      </Box>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          className={classes.btConfirm}
                        >
                          Salvar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PermissionsGate>
      <ModalConfirm title={'Ao alterar os dados de um grupo de veículos, os valores dos motoristas do mês atual e futuro serão alterados dessa maneira. '}
        description={'Deseja realmente confirmar a alteração?'}
        open={dialog} setOpen={setDialog} onClickAgree={() => onSubmitWithoutChanges(dialogData)} />
    </Aux>
  );
};

export default EditOperation;

