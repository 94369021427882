import React, { createContext, useContext, useState } from 'react';

const InputsProfileContext = createContext();

const getInitialInputsState = () => [
  { value: '', enabled: true, invalid: false, name: 'whiteRangeStart', title: 'Branca' },
  { value: '', enabled: false, invalid: false, name: 'extraEconomicRangeStart', title: 'Início faixa verde' },
  { value: '', enabled: false, invalid: false, name: 'economicRangeStart', title: 'Final faixa verde' },
  { value: '', enabled: false, invalid: false, name: 'powerRangeStart', title: 'Acelerando acima do verde' },
  { value: '', enabled: false, invalid: false, name: 'engineBrakeRangeStart', title: 'Freio motor' },
  { value: '', enabled: false, invalid: false, name: 'redRangeStart', title: 'Vermelha' },
  { value: '', enabled: false, invalid: false, name: 'redRangeEnd', title: 'Final vermelha' },
];

const InputsProfileProvider = ({ children }) => {
  const [inputs, setInputs] = useState(getInitialInputsState());

  const resetInputs = () => setInputs(getInitialInputsState());

  const isFormValid = () => {
    return inputs.every(input => input.value && !input.invalid);
  };
  const contexts = {
    inputs,
    setInputs,
    resetInputs,
    isFormValid
  };

  return (
    <InputsProfileContext.Provider value={contexts}>
      {children}
    </InputsProfileContext.Provider>
  );
};

function useInputsProfile() {
  const context = useContext(InputsProfileContext);
  return context;
}

export { InputsProfileProvider, useInputsProfile };
