import React from "react";
import { IconButton, Grid, Button, Tooltip } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon, RemoveRedEyeOutlined as EyeIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit ,size}) => {
  let columns = [
    {
      name: "customerName",
      label: size.mobile? ' ':"Empresa",
      options: {
        sort: !size.mobile,
        customBodyRender: (value) => {
          value = Array.isArray(value) ? value.toString() : value;
          const shouldTruncate = value.length  > 300 || (value.length > 25 && size.mobile);
          const truncatedValue = shouldTruncate
            ? size.mobile? `${value.slice(0, 25)}...` : `${value.slice(0, 300)}...`
            : value;

          return (
            <Grid
              container
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {shouldTruncate && !size.mobile ? (
                <>
                  {truncatedValue}
                  <Tooltip title={shouldTruncate ? value : ""}>
                    <Button size="small" color="primary">
                      [...]
                    </Button>
                  </Tooltip>
                </>
              ) : (
                truncatedValue
              )}
            </Grid>
          );
        },
      },
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "email",
      label: "E-mail",
      options: {
        sort: !size.mobile,
        customBodyRender: (value) => {
          const shouldTruncate = value.length > 15 && size.mobile;
          const truncatedValue = shouldTruncate
            ? `${value.slice(0, 15)}...`
            : value;

          return (
            <Grid
              container
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncatedValue}
            </Grid>
          );
        },
      },
    },
    {
      name: "role",
      label: "Tipo de acesso",
    },
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_edit_users', 'can_remove_users', 'can_view_users_without_edit'] })) {
    columns.push({
      name: "custom",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_users']}>
                <Grid container item xs={4} justifyContent="center">
                  <IconButton
                    onClick={() => onEdit(value.id)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_users']}>
                <Grid container item xs={4} justifyContent="center">
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_users_without_edit']}>
                <Grid container item xs={4} justifyContent="center">
                  <IconButton
                    aria-label="view-user"
                    title="Visualizar usuário"
                    component={Link}
                    to={`/gofurther/user/view-user/${value.id}`}
                  >
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
