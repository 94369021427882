import React from "react";
import "./item.css";
import { Skeleton } from "@material-ui/lab";
import IconComponent from "components/IconComponent";

const Item = ({ icon, label, size, labelStrong, loading }) => {
  return (
    <div className={"Item-container"}>
      {loading ?
        <Skeleton variant="rect"
          width={'100%'}
          height={20}
          style={{ borderRadius: "8px", margin: "2px", minWidth: 100 }}
        /> :
        <div className="Item-container-div">
          <IconComponent name={icon} size={size} />
          <div className="Item-container-span">
            {label && <span className={"Item-label"}>{label}</span>}
            {labelStrong && <span className={"Item-labelStrong"}>{labelStrong}</span>}
          </div>
        </div>
      }
    </div>
  );
};

export default Item;

Item.defaultProps = {
  icon: 0,
  size: "medium",
  loading: false,
};
