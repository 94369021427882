import React from "react";
import { Box, Grid, Paper, Typography, Tooltip } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { makeValueStars, timeConvert, valueToPositiveFloat, clearText } from "helpers/functions";
import classNames from "classnames";
import useStyle from "./styles";
import { useTheme, withStyles } from "@material-ui/styles";

export default function Indicator({
  indicator,
  hasBonus,
  print
}) {
  const theme = useTheme();
  const classes = useStyle();

  const getColor = (value) => {
    if(value <= 40) {
      return theme.palette.error.main;
    } else if(value > 40 && value <= 80) {
      return theme.palette.warning.main;
    } else if(value > 80) {
      return theme.palette.success.main;
    } else {
      return theme.palette.primary.main;
    }
  };

  const StyledRating = withStyles({
    iconFilled: {
      color: props => props.color,
    },
    iconHover: {
      color: props => props.color,
    },
  })(Rating);

  const getStars = (score, dataCy) => {
    return (
      <Tooltip title={`Nota: ${valueToPositiveFloat(score, 1)}`}>
        <Box data-cy={`divEstrelas${dataCy}`}>
          <StyledRating
            name="half-rating-read"
            value={makeValueStars(score)}
            precision={0.1}
            readOnly
            size="small"
            color={getColor(score)}
            data-cy={`spanScore${dataCy}`}
          />
        </Box>
      </Tooltip>
    );
  };

  // fix porcentagem > 100
  indicator.percentage = (indicator.percentage > 100) ? 100 : indicator.percentage;
  const dataCy = clearText(indicator.title.replace(/\s/g, ''))
  return (
    <Grid item style={{display:'flex',justifyContent: 'center'}} className={classNames(classes.container, {[classes.print]: print})}>
      <Paper style={{maxWidth:160,marginRight:5,marginLeft:5}} className={classNames(classes.card, {[classes.printCard]: print})}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography 
              className={classNames(classes.percentage)}
              style={{ color: hasBonus ? getColor(indicator.score) : theme.palette.primary.main }}
              data-cy={`pNota${dataCy}`}
            >
              {valueToPositiveFloat(indicator.percentage, 0)}%
            </Typography>
            <Typography 
              className={classes.subtitle}
              data-cy={`pTempo${dataCy}`}
            >
              {indicator.duration ? timeConvert(indicator.duration) : "00:00:00"}
            </Typography>
            {hasBonus && (
              <Grid className={classes.stars}>
                {getStars(indicator.score, dataCy)}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography 
              className={classes.title}
              data-cy={`pTexto${dataCy}`}
            >
              {indicator.label}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
