import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {Box, Button, Grid} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import classNames from "classnames";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import {
  deleteVehicleProfile,
  getVehicleProfiles,
} from "services/profiles";

import { errorsMessages } from 'helpers/errors';

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import TableResponsive from "../../../components/TableResponsive";
import {useWindowSize} from "../../../hooks/useWindowsSize";

const ListProfiles = () => {
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [profiles, setProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const fetchProfiles = async () => {
    try {
      const response = await getVehicleProfiles();
      setProfiles(response.data.data?.profiles ?? []);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Perfils. Entre em contato com o suporte.",
      );
    }
  };

  const handleClickAddVehicleProfile = () => history.push("/gofurther/profile/form-profile");
  const handleEdit = (value) => history.push(`/gofurther/profile/form-profile/${value}`);
  const handleDelete = (value) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteVehicleProfile(value);
          if (response.status !== 200) {
            toast.error("Erro ao atualizar cliente!");
          } else if (response.data) {
            if (response.data.resp) {
              toast.error(
                errorsMessages[response.data.resp].message,
              );
              return;
            }
          }
          if (response.status === 200 || response.status === 201) {
            Swal.fire(
              "Excluído!",
              "O registro foi excluído com sucesso",
              "success",
            );
            fetchProfiles();
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir veículo. Entre em contato com o suporte.",
          );
        }
      }
    });
  };
  
  const size = useWindowSize()

  const columns = makeColumns({ onDelete: handleDelete, onEdit: handleEdit, size });

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_profiles'] })) {
      fetchProfiles();
    }
  }, []);

  const handleSelectRow = (data,data2) => {
    setId(data2.dataIndex);
    const id = profiles[data2.dataIndex].id;
    setSelectedRowData(id)
  }

  const renderTable = useCallback(() => (
    size.mobile?
    <TableResponsive
      pointer={true}
      options={{
        onRowClick: handleSelectRow,
        rowsPerPage: rowsPerPage,
        setRowProps: (row, index) => {
          return index===id? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
      }} 
      columnProps={true}
      columns={columns}
      data={profiles}
      tableName="list-profiles"
      setRowsPerPage={setRowsPerPage}
    />:
      <Table
        columns={columns}
        data={profiles}
        tableName="list-profiles"
        options={{ rowsPerPage }}
        setRowsPerPage={setRowsPerPage}
      />
  ), [columns, profiles, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_profiles']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_profiles']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddVehicleProfile}
                  >
                    Novo Perfil
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de perfis">
              {renderTable()}
              {/* botoes */}
              {size.mobile?
              <PermissionsGate scopes={['can_edit_profiles', 'can_remove_profiles']}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <PermissionsGate scopes={['can_remove_profiles']}>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                    <Box >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={()=>handleEdit(selectedRowData)}
                        disabled={!selectedRowData}
                        className={classes.buttonDetail}
                      >
                        Editar
                      </Button>
                    </Box>
                  </Grid>
                  </PermissionsGate>
                  <PermissionsGate scopes={['can_edit_profiles']}>
                    <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                      <Box >
                        <Button
                          fullWidth
                          variant="outlined"
                          className={classNames(
                            classes.buttonDetail,
                            {[classes.buttonDelete]:selectedRowData}
                        )}
                          onClick={()=>handleDelete(selectedRowData)}
                          disabled={!selectedRowData}
                        >
                          Excluir
                        </Button>
                      </Box>
                    </Grid>
                  </PermissionsGate>
                </Grid>
              </Grid>
              </PermissionsGate>
                :null}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListProfiles;
