import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  helpButton: {
    color: theme.palette.primary.main,
    fontSize: 18,
    textTransform: 'capitalize',
    marginLeft: 8,
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  helpIcon: {
    fontSize: 16,
    marginLeft: 4
  },
}));