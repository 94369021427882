import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.text.title,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    maxWidth: '335px'
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
    backButton: {
      backgroundColor: theme.palette.background.backgroundButton
    }
  },
  formHelperText: {
    marginLeft: theme.spacing(2)
  },
  bottomForm: {
    marginTop: theme.spacing(2)
  }
}));
