import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./clusterSingleDetail.css";
import { trunkSVG } from "./variables";
import { color_blue_500, color_green_500, color_neutral_0, color_neutral_500, color_red_500, color_yellow_500 } from "@gobrax-ui/react";
import { useWebSocketLocationGroupVehicleStatus } from "hooks/useWebSocketLocationGroupVehicleStatus";
import { returnNA } from "helpers/returnNA";
import StatusCar from "componentsNew/molecules/statusCar/statusCar";
import { Button } from "@material-ui/core";
import IconComponent from "components/IconComponent";

export const colorMaps = {
  'blue': color_blue_500,
  'green': color_green_500,
  'yellow': color_yellow_500,
  'gray': color_neutral_500,
  'white': color_neutral_0,
  'red': color_red_500
}

const handleSelectVehicle = (noClick, uuid, closeConnection) => {
  if (!noClick ?? true) {
    if (closeConnection) {
      closeConnection()
    }
    const alreadySelectedVehicle = new URLSearchParams(window.location.search).get('id');
    if (!alreadySelectedVehicle) {
      window.location.href = `/gofurther/liveMapDetails?id=${uuid}`;
    }
  }
}

const Status = React.memo(({ vehicle, loading, handleSelectVehicle, handleCloseModal }) => {

  return (
    <div className="ClusterSingleDetail-modal">
      <Button className="ClusterSingleDetail-close-details-button" onClick={() => handleCloseModal()} >
        <IconComponent name={'close'} size={'l'} />
      </Button>
      <StatusCar
        location={returnNA(vehicle?.Address)}
        driven={`${returnNA(vehicle?.DrivenKM)} km`}
        consumption={`${returnNA(vehicle?.Consumption)} litros`}
        average={`${returnNA(vehicle?.Average)} km/l`}
        fuelLevel={`${returnNA(vehicle?.FuelLevel)}% (${returnNA(vehicle?.ActualVolume)} litros)`}
        Autonomy={`${returnNA(vehicle?.Autonomy)} km`}
        averageSpeed={`${returnNA(vehicle?.AverageSpeed)} km/h`}
        highSpeedBraking={`${returnNA(vehicle?.HighSpeedBraking)}`}
        fullBraking={`${returnNA(vehicle?.FullBraking)}`}
        odometer={`${returnNA(vehicle?.Odometer)}`}
        loading={loading}
      />
      <Button className="ClusterSingleDetail-show-details-button" onClick={handleSelectVehicle}>
        Ver mais detalhes
        <IconComponent name={'arrow_right_blue'} size={'l'} />
      </Button>
    </div>
  );
});

const ClusterSingleDetail = ({ noClick, uuid, title, color, closeConnection, vehicle }) => {
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { vehicle: vehicleInfos, closeConnection: closeConnectionStatus } = useWebSocketLocationGroupVehicleStatus(
    setLoadingStatus,
    vehicle?.uuid,
  );

  const handleCloseModal = () => {
    setShowModal(false);
    closeConnectionStatus();
  }

  return (
    <div
      key={title + "-" + uuid + "single"}
      className={"ClusterSingleDetail-container"}
      style={{ backgroundColor: colorMaps[color] }}
      onClick={() => !showModal && !noClick && setShowModal(true)}
    >
      <span className={"ClusterSingleDetail-item-icon"}>{trunkSVG}</span>
      <span className={"ClusterSingleDetail-item-typography"}>
        {title}
        {showModal && <Status
          vehicle={vehicleInfos}
          loading={loadingStatus}
          handleCloseModal={handleCloseModal}
          handleSelectVehicle={() => handleSelectVehicle(noClick, uuid, closeConnection, vehicle)}
        />}
      </span>
    </div>
  );
};

export default ClusterSingleDetail;

ClusterSingleDetail.propTypes = {
  /**
   * Texto para o cluster
   */
  title: PropTypes.string.isRequired,
  /**
   * Qual cor?
   */
  color: PropTypes.oneOf(["blue", "green", "yellow", "gray", "white", "red"]).isRequired,
  /**
   * ID
   */
  uuid: PropTypes.number.isRequired,
  /**
   * Não ter o click
   */
  noClick: PropTypes.bool,
};

ClusterSingleDetail.defaultProps = {
  title: "SUL_GBX_1234",
  color: "blue",
  uuid: 1,
  noClick: false,
};
