import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";
import {useWindowSize} from "../../hooks/useWindowsSize";

const DriversMonitoringSkeleton = () => {
  const classes = useStyles();
  const size = useWindowSize()
  return (
    <Aux>
      <Grid container spacing={1}
            direction={size.mobile? "column":"row"}
      >
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <Skeleton variant="rect" height={50} className={classes.bordered} />
        </Grid>
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <Skeleton variant="rect"  height={50} className={classes.bordered} />
        </Grid>
        
      </Grid>
      <Grid container spacing={1}
            direction={size.mobile? "column":"row"}
            >
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <Skeleton variant="rect"  height={50} className={classes.bordered} />
        </Grid>
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <Skeleton variant="rect"  height={50} className={classes.bordered} />
        </Grid>
      </Grid>
      
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton variant="rect" width={"100%"} height={550} className={classes.bordered} />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  bordered: {
    borderRadius: "10px",
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tableButtons: {
    marginTop: theme.spacing(1),
  },
  buttons: { 
    marginBottom: "10px", 
    display: "flex", 
    justifySelf: "flex-end",
    borderRadius: "10px"
  }
}));

export default DriversMonitoringSkeleton;
