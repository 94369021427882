import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  titleBox: {
    display: 'flex',
    marginTop: theme.spacing(4),
    alignItems: 'baseline',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginLeft: theme.spacing(1),
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    backgroundColor: theme.palette.background.backgroundButton
  },
  paperText: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
    borderRadius: 28,
    margin: 5,
  },
  titleText: {
    fontWeight: "600",
    marginRight: 4,
  },
}));
