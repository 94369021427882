import React from "react";
import PropTypes from "prop-types";
import "./headerDetails.css";
import { Button } from "../../../componentsNew/atoms/button/button";
import Item from "../../../componentsNew/atoms/item/item";

const HeaderDetails = ({ vehicle, driver, date, onBack, loading, showBackButton = true }) => {
  return (
    <div className={`HeaderDetails-container`}>
      <Item icon={'truck_full'} label={vehicle} loading={loading} />
      <Item icon={'person_filled'} label={driver} loading={loading} />
      <Item icon={'date_calendar'} label={date} loading={loading} />
      {showBackButton && <Button
        size={"small"}
        type={"secondary"}
        onClick={onBack}
        label="Voltar"
      />}
    </div>
  );
};

export default HeaderDetails;

HeaderDetails.propTypes = {
  /**
   * Placa/Identificador do veículo
   */
  vehicle: PropTypes.string.isRequired,
  /**
   * Motorista do veículo
   */
  driver: PropTypes.string.isRequired,
  /**
   * Data
   */
  date: PropTypes.string.isRequired,
  /**
   * Função quando clicar em voltar
   */
  onBack: PropTypes.any,
};

HeaderDetails.defaultProps = {
  vehicle: "SUL_123",
  driver: "GOBRAX",
  date: "08/08/2023 às 15:00",
};
