import React, { useState, useEffect, useRef } from "react";
import { Box, Checkbox, FormHelperText, Grid, Input, Paper, Typography } from "@material-ui/core";
import { Controller, useFormState, useFormContext, useWatch } from "react-hook-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { format, parse } from "date-fns";
import { Select } from "components/react-hook-form";
import FormDriverSkeleton from "components/Skeletons/FormDriverSkeleton";
import Aux from "hoc/auxiliar";
import useStyles from "./styles";

const AssignForm = (props) => {
  const classes = useStyles();
  const keyRef = useRef(Date.now());
  const formatDate = "dd/MM/yyyy HH:mm";
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const { drivers, isEdit } = props;
  const [ driversOptions, setDriversOptions ] = useState([]);
  const [ isChecked, setIsChecked ] = useState(true);
  const [ dates, setDates ] = useState(null);

  const driverOption = useWatch({ control, name: "driverOption" });
  const startDateOption = useWatch({ control, name: "startDateOption" });
  const endDateOption = useWatch({ control, name: "endDateOption" });

  const fetchData = () => {
    const options = drivers.map((item) => ({
      label: item.name,
      value: item.id,
      vehicleId: item.customerId
    }));
    setDriversOptions(options);
    setValue("isChecked", isChecked);
  };

  const handleInitialDates = () => {
    if (!isEdit) {
      setDates({
        initialDate: format(new Date(), formatDate),
        finalDate: format(new Date(), formatDate),
      })
    }
  };

  const handleStartDate = (startDate) => {
    if (startDate) {
      if (new Date(startDate) > new Date(dates.finalDate)) {
        setDates({
          initialDate: format(startDate, formatDate),
          finalDate: format(startDate, formatDate)
        });
      } else {
        setDates({
          ...dates,
          initialDate: format(startDate, formatDate)
        });
      }
    }
  };

  const handleFinalDate = (finalDate) => {
    if (finalDate) {
      setDates({
        ...dates,
        finalDate: format(finalDate, formatDate)
      });
    }
  };

  useEffect(() => {
    // Using on edit
    if (driverOption && driversOptions && driversOptions.length > 0) {
      setValue(
        "drivers",
        driversOptions.find((elm) => elm.value === driverOption),
      );
      setValue("isChecked", !!!endDateOption);
      setIsChecked(!!!endDateOption);
      setValue("startDate", format(new Date(startDateOption), formatDate));
      endDateOption && setValue("endDate", format(new Date(endDateOption), formatDate));
      let newDates = {
        initialDate: format(new Date(startDateOption), formatDate),
      };
      if (endDateOption) {
        newDates.finalDate = format(new Date(endDateOption), formatDate)
      }
      setDates(newDates)
    }
  }, [driversOptions]);

  useEffect(() => {
    drivers && fetchData();
  }, [drivers]);

  useEffect(() => {
    handleInitialDates();
  }, [])

  if (!props) {
    return <FormDriverSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Motorista</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Select
                    options={driversOptions}
                    name="drivers"
                    placeholder="Selecione um motorista *"
                  />
                  <FormHelperText className={classes.formHelperText} error={true}>
                    {errors.drivers?.message}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Dia do vínculo</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  {dates && <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => {
                      const date = field.value.split('/')
                      const newDate = date[1] + '/' + date[0] + '/' + date[2];
                      const currentDate = new Date(newDate);
                      return (
                        <DateRangePicker
                          initialSettings={{
                            startDate: dates.initialDate,
                            singleDatePicker: true,
                            timePicker24Hour: true,
                            timePicker: true,
                            maxDate: new Date(),
                            locale: {
                              format: "DD/MM/YYYY HH:mm",
                              applyLabel: "Aplicar",
                              cancelLabel: "Cancelar",
                            },
                            opens: "center"
                          }}
                          autoApply={true}
                          onApply={(event, picker) => {
                            keyRef.current = Date.now();
                            field.onChange(format(new Date(picker.startDate._d), formatDate));
                            handleStartDate(picker.startDate._d);
                          }}
                        >
                          <div>
                            <Input
                              data-cy="inputCalendarStart"
                              className={classes.inputCalendar}
                              value={format(currentDate, formatDate)}
                            />
                          </div>
                        </DateRangePicker>
                      )
                    }}
                  />}
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.containerCheckbox}
                >
                  <Controller
                    render={({ field }) => (
                      <Checkbox
                        tabIndex={-1}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          setIsChecked(!isChecked);
                        }}
                        checked={field.value}
                      />
                    )}
                    name={"isChecked"}
                    type="checkbox"
                    control={control}
                  />
                  <Typography variant="h5" className={classes.textDriver}>
                    O motorista vai continuar vinculado
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  {dates && <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => {
                      return (
                        <DateRangePicker
                          key={keyRef.current}
                          initialSettings={{
                            startDate: dates.finalDate,
                            singleDatePicker: true,
                            timePicker24Hour: true,
                            timePicker: true,
                            maxDate: new Date(),
                            minDate: parse(dates.initialDate, formatDate, new Date()),
                            locale: {
                              format: "DD/MM/YYYY HH:mm",
                              applyLabel: "Aplicar",
                              cancelLabel: "Cancelar",
                            },
                            opens: "center"
                          }}
                          autoApply={true}
                          onApply={(event, picker) => {
                            field.onChange(format(new Date(picker.startDate._d), formatDate));
                            handleFinalDate(picker.startDate._d);
                          }}
                        >
                          <div>
                            <Input
                              data-cy="inputCalendarEnd"
                              className={classes.inputCalendar}
                              value={!isChecked ? field.value : ''}
                              disabled={isChecked}
                            />
                          </div>
                        </DateRangePicker>
                      )
                    }}
                  />}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>**Ao desmarcar a opção “O motorista vai continuar vinculado”, é possível definir um período fixo em que o motorista selecionado dirigiu.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default AssignForm;
