import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pageToolbarContainer: {
    zIndex: 1200,
    textAlign: "center",
  },
  multiselect: {
    borderRadius: 20,
    maxWidth: "100%",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.backgroundButton}`,
  },
  spacingContainer: {},
  backButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  paperSelect: {
    borderRadius: 20,
    width: "100%",
    maxWidth: "100%",
    padding: "4px 4px 4px 4px",
    marginBottom: 10,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    zIndex: 110,
  },
}));
