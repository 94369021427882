import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  itemIcon: {
    fontSize: "1rem",
    borderRadius: "50%",
  },
  fontBold: {
    fontWeight: "bold",
  },
}));
