import api from "./api";

export const getVehicleProfiles = async () => {
  try {
    const response = await api.get(`/profiles`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicleProfile = async (profileId) => {
  try {
    const response = await api.get(`/profiles/${profileId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicleProfileHistory = async (modelId) => {
  try {
    const response = await api.get(`/modelprofile-histories/${modelId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createVehicleProfile = async (data) => {
  try {
    const response = await api.post(`/profiles`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateVehicleProfile = async (profileId, data) => {
  try {
    const response = await api.put(`/profiles/${profileId}`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteVehicleProfile = async (profileID) => {
  try {
    const response = await api.delete(`/profiles/${profileID}`);
    return response;
  } catch (err) {
    return err;
  }
};
