export default [
  "birthDate",
  "customer",
  "customerOption",
  "documentNumber",
  "email",
  "name",
  "phone",
  "obs",
  "canLink",
  "role"
];
