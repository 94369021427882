import React from "react";
import { Typography } from "@material-ui/core";
import RecalculationStatus from "components/RecalculationStatus";

import makeStyles from "./styles";

export const makeColumns = () => {
  const classes = makeStyles();

  const getValue = (value, withoutValue) => {
    return value ? (
      <Typography variant="body2">
        {value}
      </Typography>
    ) : (
      <Typography variant="body2" className={classes.textInfo}>
        {withoutValue}
      </Typography>
    );
  };

  return [
    {
      name: "vehicle",
      label: "Frota/placa",
      options: {
        customBodyRender: (value) => getValue(value, "Sem dados"),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => <RecalculationStatus value={value} />,
      },
    },
  ];
};
