import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { format } from "date-fns";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit ,size}) => {
  let columns = [
    {
      name: "name",
      label: size.mobile? ' ' :"Perfil",
    },
    {
      name: "creationDate",
      label: "Data de criação",
      options: {
        customBodyRender: (value) => format(new Date(value), "dd/MM/yyyy HH:mm"),
      },
    },
    {
      name: "updateDate",
      label: "Data da última atualização",
      options: {
        customBodyRender: (value) => format(new Date(value), "dd/MM/yyyy HH:mm"),
      },
    },
    {
      name: "permissions",
      label: "Permissões",
      options: {
        customBodyRender: (value) => {
          return value
        },
      },
    }
  ];

  if (!size.mobile && hasPermission({scopes: ['can_edit_user_role', 'can_remove_user_role']})) {
    columns.push({
      name: "role",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_user_role']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_user_role']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
