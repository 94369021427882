import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowSize } from "hooks/useWindowsSize";

const RewardSkeleton = () => {
  const classes = useStyles();
  const size = useWindowSize();
  return (
    <Aux>
      <Grid
        container
        spacing={1}
        className={classes.container}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid3}
          xs={size.grid3}
        >
          <Skeleton
            variant="rect"
            width={"100%"}
            height={50}
            className={classes.bordered}
          />
        </Grid>
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid3}
          xs={size.grid3}
        >
          <Skeleton
            variant="rect"
            width={"100%"}
            height={50}
            className={classes.bordered}
          />
        </Grid>
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid3}
          xs={size.grid3}
        >
          <Skeleton
            variant="rect"
            width={"100%"}
            height={50}
            className={classes.bordered}
          />
        </Grid>
        <Grid
          item
          xl={size.grid3}
          lg={size.grid3}
          md={size.grid3}
          sm={size.grid3}
          xs={size.grid3}
        >
          <Skeleton
            variant="rect"
            width={"100%"}
            height={50}
            className={classes.bordered}
          />
        </Grid>
      </Grid>
      
      <Grid
        container
        direction="row"
        className={classes.container}
        justifyContent="space-between"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={80}
                    className={classes.bordered}
                  />
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: 5 }}
                >
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={80}
                    className={classes.bordered}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid container alignItems="center">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={180}
                    className={classes.bordered}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={550}
            className={classes.bordered}
          />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  bordered: {
    borderRadius: "22px",
  },
  title: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  tableButtons: {
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginBottom: "10px",
    display: "flex",
    justifySelf: "flex-end",
    borderRadius: "10px",
  },
}));

export default RewardSkeleton;
