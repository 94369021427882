import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  table: {},
  textBold: {
    fontWeight: "bold",
  },
  textInfo: {
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  actionContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  actionButton: {
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
      fontSize: 28,
    },
  },
}));
