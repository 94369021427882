export default [
  "name",
  "penalty_score",
  "customControll",
  "penalizingLowControll",
  "penalizingMediumControll",
  "penalizingHighControll",
  "highRequirementList",
  "highPenalizingRequirementList",
  "mediumRequirementList",
  "mediumPenalizingRequirementList",
  "lowRequirementList",
  "lowPenalizingRequirementList",
  "highControll",
  "mediumControll",
  "lowControll",
];