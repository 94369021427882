import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  TextField, InputAdornment, IconButton,
} from "@material-ui/core";
import Copyright from '../../../components/Copyright'
import useStyles from "./styles";
import GoBraxLogo from "../../../images/gobrax.svg";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import api, {apiExt} from "../../../services/api";
import {toast} from "react-toastify";
import PasswordChecklist from "../../../components/PasswordChecklist";
import {useWindowSize} from "../../../hooks/useWindowsSize";
import { handleAmplitudeEvent } from "services/amplitude";

function PassRecovery(props) {
  
  const [isLoading, setIsLoading] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false)
  const [ready, setReady] = useState(false)
  const [token, setToken] = useState(null)
  const history = useHistory()
  const size = useWindowSize()
  const classes = useStyles(size.mobile);

  useEffect(()=>{
    if(window.location.search && token === null){
      // cadastrando token
      setToken(window.location.search.replace('?token=',''))
      // Criando um objeto URL a partir da URL fornecida
      const urlObj = new URL(window.location.href);
      // Removendo os parâmetros de pesquisa da URL
      urlObj.search = '';
      // Atualizando a URL atual no histórico do navegador
      window.history.replaceState({}, '', urlObj.toString());
    }else if(token === null){
      //Redirecionando para o login quando não tem o token na URL
      history.push('/login')
    }
  },[])
  const ChangePassword = async () => {
    try{
      setIsLoading(true)
      const response = await apiExt.post(`/web/v2/authenticator/execute/changePass`,{
        pass:passwordValue
      }, {headers: { 'Credentials':token }});
      if(response.data && response.data.success){
        handleAmplitudeEvent('Password Recovery Updated')
        setIsLoading(false)
        toast.success('Senha cadastrada com sucesso')
        setTimeout(()=>{
          history.push('/login')
        },[1000])
      }else{
        setIsLoading(false)
        toast.error('Tempo de redefinição esgotado, solicite novamente o e-mail.')
      }
    }
    catch (e) {
      setIsLoading(false)
      toast.error('Erro ao cadastrar senha, por favor tente novamente mais tarde ou entre em contato com o suporte.')
    }
  }
  
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <video autoPlay loop muted className={classes.bgVideo}>
        <source src={require("../../../images/1134952923.mp4")} type="video/mp4" />
      </video>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        className={classes.image}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} component={Paper} elevation={3} className={classes.loginForm}>
          <div className={classes.paper}>
            <div className={classes.logotype}>
              <img className={classes.logotypeIcon} src={GoBraxLogo} alt="logo" />
            </div>
              <form
                className={classes.form}
              >
                <Typography className={classes.title1}>Criar uma nova senha</Typography>
                <Typography className={classes.title2} style={{marginBottom:'1.5rem'}}>Para criar uma nova senha com segurança, pedimos que siga com atenção os passos abaixo e lembre-se de NÃO utilizar o seu nome ou qualquer tipo de dados pessoais. A nova senha precisa ter pelo menos:</Typography>
                <PasswordChecklist
                  password={passwordValue}
                  passwordConfirm={confirmPassword}
                  setReady={setReady}
                />
                <TextField
                  name="password"
                  variant="outlined"
                  autoComplete="new-password"
                  style={{marginTop:'1.5rem'}}
                  fullWidth
                  onChange={(e) => setPasswordValue(e.target.value)}
                  label="Digite a nova senha"
                  id="password"
                  type={visible ? 'text' : 'password'}
                  value={passwordValue}
                  InputProps={{
                    style:{borderRadius:'1rem'},
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clipPath="url(#clip0_614_25887)">
                            <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" fill="black"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_614_25887">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setVisible(!visible)}
                      >
                        <IconButton>
                          {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>),
                  }}
                />
                <TextField
                  name="Confirmpassword"
                  variant="outlined"
                  autoComplete="new-password"
                  style={{marginTop:'1.5rem'}}
                  fullWidth
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  label="Digite novamente a nova senha"
                  id="Confirmpassword"
                  type={visible ? 'text' : 'password'}
                  value={confirmPassword}
                  InputProps={{
                    style:{borderRadius:'1rem'},
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clipPath="url(#clip0_614_25887)">
                            <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" fill="black"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_614_25887">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setVisible(!visible)}
                      >
                        <IconButton>
                          {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>),
                  }}
                />
                <Typography className={classes.title2}>Siga os critérios de aceitação acima para conseguir criar uma nova senha com sucesso. *Você tem até 20 minutos para criar a sua nova senha, caso ultrapasse esse tempo, solicite o envio de novo email para redefinir sua senha.</Typography>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!ready}
                    className={classes.submit}
                    variant="contained"
                    onClick={ChangePassword}
                  >
                    Criar senha
                  </Button>
                  <Button
                    onClick={() => {
                      history.push('/login')
                    }}
                    fullWidth
                    color={'secondary'}
                    className={classes.submit2}
                    variant="outlined"
                  >
                    Voltar
                  </Button>
                </>
              )}
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PassRecovery;
