import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: 700,
  },
  statusText: {
    fontWeight: 700,
    marginTop: "-4px !important",
    marginLeft: "8px !important"
  },
  success: {
    color: theme.palette.success.main
  },
  danger: {
    color: theme.palette.error.main
  },
  btAction: {
    textTransform: "none",
    color: theme.palette.primary.main
  },
  titlePage: {
    fontWeight: 600,
    cursor: "default",
    fontSize: "24px",
    color: theme.palette.text.title2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginBottom: "8px"
    }
  }
}));
