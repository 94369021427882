import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import { useFormState, useFormContext, Controller } from "react-hook-form";
import Select from "../react-hook-form/select";
import DateFnsUtils from "@date-io/date-fns";

import useStyles from "./styles";
import { getDevices } from "../../services/device";
import { toast } from "react-toastify";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";

const LinkDeviceForm = (props) => {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const fetchDevices = async () => {
    try {
      const response = await getDevices();
      const filteredResponse = response.data.devices.filter((item) => item.status === 1)
      const formatedResponse = filteredResponse.map((item) => ({
      value: item.id,
      label: item.identification
      }));  
      setDevices(formatedResponse); 
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de unidades eletrônicas. Entre em contato com o suporte.",
      );
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);
  
  return ( 
    <Grid container spacing={2}>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <Controller
            render={(props) => {
              const { ref, ...fieldProps } = props.field;
              return (
                <KeyboardDatePicker
                  {...fieldProps}
                  fullWidth
                  autoOk
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Data Inicial"
                  helperText={errors.initialDate?.message}
                  error={errors.initialDate && true}
                  InputProps={{
                    classes: {
                      root: classes.input,
                    }
                  }}
                />
              );
            }}
            control={control}
            name="initialDate"
            placeholder="Data Inicial"
          />
        </MuiPickersUtilsProvider>
      </Grid> 
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Select
          options={devices}
          name="devices"
          placeholder="Selecione a unidade eletrônica"
        />
        <FormHelperText className={classes.formHelperText} error={true}>
          {errors.devices?.message}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default LinkDeviceForm;
