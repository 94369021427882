import {
  mdiMapMarkerDistance,
  mdiGasStation,
  mdiChartBar,
  mdiCarBrakeAlert,
  mdiTruckFastOutline,
  mdiCounter,
} from "@mdi/js";

export default {
  cards: [
    {
      name: "totalMileage",
      title: "Km total",
      label: 0,
      unit: "km",
      icon: mdiMapMarkerDistance,
    },
    {
      name: "averageSpeed",
      title: "Velocidade média",
      label: 0,
      unit: "km/h",
      icon: mdiTruckFastOutline,
    },
    {
      name: "totalConsumption",
      title: "Consumo total",
      label: 0,
      unit: "L",
      icon: mdiGasStation,
    },
    {
      name: "consumptionAverage",
      title: "Média do computador de bordo",
      label: 0,
      unit: "Km/L",
      icon: mdiChartBar,
    },
    {
      name: "odometer",
      title: "Odômetro",
      label: 0,
      unit: "Km",
      icon: mdiCounter,
    },
    {
      name: "totalBreakingOnHighSpeed",
      title: "Freadas em alta veloc.",
      label: 0,
      unit: "",
      icon: mdiCarBrakeAlert,
    },
    {
      name: "totalBreakin",
      title: "Freadas totais",
      label: 0,
      unit: "",
      icon: mdiCarBrakeAlert,
    },
    {
      name: "totalBruteBrake",
      title: "Freadas bruscas",
      label: 0,
      unit: "",
      icon: mdiCarBrakeAlert,
    },
  ]
};
