import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  linkRoot: {
    maxHeight: "43px"
  },
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
    paddingLeft: theme.spacing(1)
  },
  linkActive: {
    backgroundColor: theme.palette.background.background2,
    borderLeft: `6px solid ${theme.palette.secondary.main}`,
    boxShadow: theme.customShadows.menu
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#FFFFFF",
    },
  },
  badgeMini: {
    width:12,
    height:12,
    borderRadius:'50%',
    backgroundColor: '#F7DF36',
    position: 'absolute',
    top:4,
    right:18
  },
  linkIcon: {
    color: theme.palette.gray.grayExtraDark,
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    color: theme.palette.black,
  },
  badgeNew: {
    padding: '4px 8px !important',
    paddingRight: '8px !important',
    marginLeft: '2px !important',
    marginRight: '2px !important',
    borderRadius: '32px !important',
    background: `#F7DF36 !important`,
    color: theme.palette.black,
    fontWeight: 'bold',
  },
  linkIconChildren: {
    color: theme.palette.text.primaryLight,
    width: 20
  },
  linkTextChildren: {
    padding: 0,
    color: theme.palette.black,
    transition: theme.transitions.create(["opacity", "color"]),
    fontWeight: 'normal',
    fontSize: 13,
  },
  linkTextTitle: {
    padding: 0,
    color: theme.palette.text.grayExtraDark,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 13,
    whiteSpace: 'normal'
  },
  linkTextParent: {
    fontWeight: 'bold',
  },
  linkTextActive: {
    color: theme.palette.black,
    fontWeight: 'bold',
   
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(3),
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
}));
