import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {Box, Button, Grid} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Swal from "sweetalert2";

// components
import Aux from "hoc/auxiliar";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import { phoneMask, removeCountryCode } from "helpers/masks";
import { convertDateBr }  from "helpers/dates";

// services
import { deleteDevice, getDevices } from "services/device";
import { getDeviceStatus } from "helpers/deviceStatus";

import useStyles from "./styles";
import { makeColumns } from "./tableColumns";
import FormRoleSkeleton from "components/Skeletons/FormRoleSkeleton";
import TableResponsive from "../../../components/TableResponsive";
import {useWindowSize} from "../../../hooks/useWindowsSize";
import {brandListOptions} from "../../../domain/selectOptions";

const ListDevices = () => {
  var theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [dataTable, setDataTable] = useState([]);
  const [download,] = useState({
    link: '/devices/export',
    fileName: `unidades-eletronicas_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`
  });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loading, setLoading] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();

  const handleClickAddDevice = () => history.push("/gofurther/device/form-device");
  const handleClickMassAddDevice = () => history.push("/gofurther/device/mass-add-device");
  const handleEdit = (deviceId) => {
    history.push(`/gofurther/device/form-device/${deviceId}`);
  };
  const handleDelete = (row) => {
    const textAlert = row.currentVehicle
      ? "Existe um veículo vinculado com essa Unidade Eletrônica. "
      : "";
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: `${textAlert}Essa ação é irreversível!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteDevice(row.id);
          if (response.status === 200) {
            Swal.fire(
              "Excluído!",
              "O registro foi excluído com sucesso",
              "success",
            );
            fetchDevices();
          } else {
            throw new Error();
          }
        } catch (error) {
          toast.error(
            "Erro ao excluir Unidade Eletrônica. Entre em contato com o suporte.",
          );
        }
      }
    });
  };

  const handleHistory = (deviceId) => {
    history.push(`/gofurther/device/historic-devices/${deviceId}`);
  }
  
  const size = useWindowSize()

  const columns = makeColumns({ onEdit: handleEdit, onDelete: handleDelete, onHistory: handleHistory ,size});

  const fetchDevices = async () => {
    try {
      setLoading(true)
      const response = await getDevices();
      const formatedResponse = response.data.devices.map((item) => {
        const brand = brandListOptions.filter(i => i.id === item.brand);
        return {
          ...item,
          customerName: item.currentVehicle ? item.currentVehicle.customerName : '',
          brand: brand.length > 0 ? brand[0].name : item.brand.charAt(0).toUpperCase() + item.brand.slice(1),
          simcard: item.simcard
            ? phoneMask(removeCountryCode(item.simcard))
            : " - ",
          status: getDeviceStatus(item.status),
          created_at: convertDateBr(item.created_at),
        }
      });
      setDataTable(formatedResponse);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de unidades eletrônicas. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_devices'] })) {
      fetchDevices();
    }
  }, []);

  const handleSelectRow = (data,data2) => {
    setId(data2.dataIndex);
    const id = dataTable[data2.dataIndex].id;
    setSelectedRowData(id)
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_devices']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end">
              <PermissionsGate scopes={['can_import_devices']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12} className={classes.btItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickMassAddDevice}
                  >
                    Importar em Massa
                  </Button>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_create_devices']}>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddDevice}
                  >
                    Nova Unidade Eletrônica
                  </Button>
                </Grid>
              </PermissionsGate>
            </Grid>
            <Widget disableWidgetMenu title="Gerenciamento de unidades eletrônicas">
              {loading ? (
                <FormRoleSkeleton />
              ) : (
                size.mobile?
                <>
                <TableResponsive
                  options={{
                    onRowClick: handleSelectRow,
                    rowsPerPage:rowsPerPage,
                    setRowProps: (row, index) => {
                      return index===id? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
                  }}
                  pointer={true}
                  columns={columns}
                  data={dataTable}
                  download={download}
                  tableName="list-devices"
                  setRowsPerPage={setRowsPerPage}
                />
                {/* botoes */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <PermissionsGate scopes={['can_edit_devices']}>
                      <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                        <Box >
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={()=>{handleEdit(selectedRowData)}}
                            disabled={!selectedRowData}
                            className={classes.buttonDetail}
                          >
                            Editar
                          </Button>
                        </Box>
                      </Grid>
                    </PermissionsGate>
                    <PermissionsGate scopes={['can_remove_devices']}>
                      <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                        <Box >
                          <Button
                            fullWidth
                            variant="outlined"
                            style={{color:selectedRowData ?'red':'auto',borderColor:selectedRowData?'red':'auto'}}
                            onClick={()=>{handleDelete(selectedRowData)}}
                            disabled={!selectedRowData}
                            className={classes.buttonDetail}
                          >
                            Excluir
                          </Button>
                        </Box>
                      </Grid>
                    </PermissionsGate>
                    <PermissionsGate scopes={['can_view_history_devices']}>
                      <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                        <Box >
                          <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={()=>{handleHistory(selectedRowData)}}
                            disabled={!selectedRowData}
                            className={classes.buttonDetail}
                          >
                            Histórico
                          </Button>
                        </Box>
                      </Grid>
                    </PermissionsGate>
                  </Grid>
                </Grid>
                </>
                    : <Table
                      columns={columns}
                      data={dataTable}
                      download={download}
                      tableName="list-devices"
                      options={{ rowsPerPage }}
                      setRowsPerPage={setRowsPerPage}
                    />
              )}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListDevices;
