import React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// components
import Aux from 'hoc/auxiliar';
import RoleForm from 'components/RoleForm';
import BackButton from 'components/BackButton';
import PermissionsGate from "components/PermissionsGate";

// helpers
import makeDefaultValues from 'helpers/makeDefaultValues';

// domain
import fields from 'domain/forms/formDriverRoleInitialValues';
import schemaValidation from 'domain/validation/yup/formRoleDriverValidation';

// services
import { createPermissionRoles } from 'services/roles';

import useStyles from './styles';

const AddRole = () => {
  const classes = useStyles();
  const history = useHistory();

  const defaultValues = makeDefaultValues({ fields });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const { isSubmitting } = useFormState({ control: methods.control });

 const onSubmit = async (data) => {
    const { name, login_redirect, ...payload } = data;
    try {
      const response = await createPermissionRoles(payload);
      if (response.status === 200 && !data.err) {
        toast.success('Perfil criado com sucesso')
        methods.reset(defaultValues);
        history.push('/gofurther/role/list-roles-app')
      } else {
        toast.error('Erro ao criar perfil. Verifique com o suporte');
      }
    } catch (error) {
      toast.error('Erro ao criar perfil. Verifique com o suporte');
    }
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_user_role']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant='h1' className={classes.title}>Cadastro de perfil (aplicativo)</Typography>
                    <Typography variant='h5' className={classes.subtitle}>Cadastre ou selecione perfis criados, configure permissões e acessos dos motoristas no aplicativo</Typography>
                    <RoleForm />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent='flex-end' spacing={2}>
                        <Grid item container justifyContent='flex-end' xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant='contained'
                                type='submit'
                                disabled={isSubmitting}
                                className={classes.btConfirm}
                              >
                                Criar novo perfil
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddRole;
