import React from "react";
import "./title.css";
import PropTypes from "prop-types";

const Title = ({ value }) => {
  return (
    <div>
      <span className={`Title-container`}>{value}</span>
    </div>
  );
};

export default Title;

Title.propTypes = {
  /**
   * Props descrição
   */
  value: PropTypes.string.isRequired,
};

Title.defaultProps = {
  value: "Gobrax Title",
};
