import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Grid} from "@material-ui/core";

export default function ModalConfirm({description,
  title,
  onClickAgree,
  setOpen,
  open
}) {

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div>
      <Dialog 
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{zIndex:9999}}
        PaperProps={{
          style: { borderRadius: 10 }
        }}
        maxWidth={'xs'}
      >
      <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{color: '#181814',
            fontSize: '16px', fontFamily:'Roboto',lineHeight:'140%'}}>
            {title}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" style={{color: '#181814',
            fontSize: '20px', fontFamily:'Roboto',lineHeight:'140%',fontWeight:500,marginTop:18}}>
            {description}
          </DialogContentText>
        <Grid container spacing={2} style={{marginTop:32,marginBottom:32}}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Button style={{borderRadius:10,height:44}} variant={'contained'} fullWidth onClick={handleClose} color="secondary">
              Voltar
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Button style={{borderRadius:10,height:46,borderColor:'#FFD31C',backgroundColor:'white'}} fullWidth onClick={() => {
              onClickAgree();
              handleClose();
            }} variant={'outlined'} autoFocus>
              Confirmar
            </Button>
          </Grid>
        </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
