import React, { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {CloudDownload as Download, EmailOutlined} from '@material-ui/icons';
import { toast } from "react-toastify";
import { getDownload, getDownloadWithParams } from '../../../../services/files';
import { downloadFile, getIdListUrl } from '../../../../helpers/functions';
import { handleAmplitudeEvent } from 'services/amplitude';

const CustomToolbar = (props) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const { download, email, tableName } = props;

  const getDownloadFile = async (response) => {
    if(email){
      toast.success(response.data.data);
    }else{
      let responseData = response.data?.XLSX || response.data?.XLS || response.data?.data?.XLSX || response.data?.data?.XLS;
      downloadFile(responseData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", download.fileName);
    }
   
  };

  const handleDownload = async () => {
    try {
      setLoading(true)
      const response = await getDownload(download.link);
      getDownloadFile(response);
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte.");
    } finally {
      setLoading(false)
    }
  };

  const handledownloadWithParams = async () => {
    let params = {};
    for (let key in download.params) {
      if (Array.isArray(download.params[key])) {
        let itens = getIdListUrl(download.params[key]);
        params[key] = itens;
      } else {
        params[key] = download.params[key];
      }
    }
    try {
      setLoading(true)
      const response = await getDownloadWithParams(download.link, params);
      getDownloadFile(response);
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte.");
    } finally {
      setLoading(false)
    }
  };

  const handleClick = async () => {
    handleAmplitudeEvent('Table Excel Download Clicked', { table_name: tableName })
    if (!download.params) {
      handleDownload();
    } else {
      handledownloadWithParams();
    }
  };

  return (
    <>
      {(download.link && download.fileName) && (
        <>
          {loading ? (
            <CircularProgress
              color="inherit"
              size={20}
              thickness={6}
            />
          ) : (
            <Tooltip title={"Download excel"}>
              <IconButton className={classes.iconButton} onClick={handleClick}>
                {email ? <EmailOutlined className={classes.deleteIcon} /> :
                <Download className={classes.deleteIcon} />}
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    width: "34px",
    height: "34px",
  },
}));

export default CustomToolbar;