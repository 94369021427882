/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef, useCallback } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { colourStyles } from './styles'
import { useDebouncedCallback } from 'use-debounce';
import { Paper, Grid } from '@material-ui/core';
import useStyles from "../useStyles";
import colors from '../../../themes/gobrax'
import { clearDuplicated } from '../../../helpers/functions';
import MenuDTO from '../../../dto/menuDTO'
import Icon from '@mdi/react';

const MultiSelectFilters = ({
  customStyles,
  isDetail,
  isSingleMode = false,
  handleSelectedListData,
  listData,
  selectedData,
  icon,
  placeholder,
  prefix
}) => {

  const selectAllOption = {
    value: 'select-all',
    label: 'Todos',
    color: colors.palette.text.primary,
    id: 'select-all',
    isChild: false,
    isHidden: false,
    isSelected: false
  }

  const classes = useStyles();

  let initialOptions = useRef([]);
  let parentOptions = useRef([]);
  let childOptions = useRef([]);
  const [selectedOptions, setSelectedOption] = useState([])
  const [selected, setSelected] = useState(isSingleMode ? [] : [selectAllOption])
  const [selectedSingleMode, setSelectedSingleMode] = useState([])

  const formatData = useCallback((data = []) => {
    let itens = [];
    let options = [];
    let childFiltered = [];
    let initialSelectedOptions = [];
    let parentFiltered = [];

    options.push(selectAllOption);

    data.forEach(item => {
      const localItem = {
        ...item,
        value: item.id,
        label: item.name,
        color: colors.palette.text.secondary,
        isChild: true,
        isHidden: true,
        isSelected: isSingleMode ? false : true,
        id: item.id,
        prefix: prefix,
      }
      options.push(localItem)
      initialSelectedOptions.push(localItem);
    })

    options.filter(isChild => isChild.isChild && childFiltered.push(isChild))

    options.filter(isChild => !isChild.isChild && parentFiltered.push(isChild))

    initialOptions.current = options;
    childOptions.current = childFiltered;
    parentOptions.current = parentFiltered;

    if (!isSingleMode) {
      handleClearAll();
      setSelectedOption([])
      debounced([])
    }
    if (isSingleMode) {
      childOptions.current = MenuDTO.fromChild(itens)
    }
  }, [])

  const debounced = useDebouncedCallback((value) => {
    if (isSingleMode) {
      const selected = [{ ...value }]
      handleSelectedListData(selected)
    } else {
      const selected = clearDuplicated(value)
      handleSelectedListData(selected)
    }
  }, 500
  );

  useEffect(() => {
    if (listData) {
      if (!selectedData) {
        formatData(listData)
        return;
      }

      const op = selectedData.map(item => {
        return (
          {
            ...item, value: item.id,
            label: item.name,
            color: colors.palette.text.hint,
            isChild: true,
            isHidden: false,
            isSelected: isSingleMode ? false : true,
            id: item.id,
          }
        )
      })
      setSelectedSingleMode(op)
      debounced(op)
      formatData(listData)
    }
  }, [formatData, listData])

  const handleAddedOption = (element, list, options) => {
    // check if it is parent and hide children
    let initialOptionsLocal = [...initialOptions.current];

    if (element.value === 'select-all') {

      initialOptionsLocal.forEach((item, index) => {
        const updatedElement = { ...item, isSelected: true, isHidden: true };
        initialOptionsLocal[index] = updatedElement;

        if (item.isChild) {
          options.push(updatedElement);
        }
      });

      const localList = [element];

      initialOptions.current = initialOptionsLocal;
      return [localList, options];

    }

    if (!element.isChild) {
      const id = element.id;
      initialOptionsLocal.forEach((item, index) => {
        if (item.parentId === id && item.isSelected) {
          list = list.filter(i => (i.parentId !== item.parentId))
        }
        if (item.parentId === id) {
          const updatedElement = { ...item, isHidden: true, isSelected: true };
          initialOptionsLocal[index] = updatedElement;
          if (!item.isSelected) {
            options.push(updatedElement);
          }
        }
      })

      initialOptions.current = initialOptionsLocal;
      return [list, options];
    }

    // check if all child is selected: select parent and hide children
    const parentId = element.parentId;
    const id = element.id;

    let allSelected = true;
    initialOptionsLocal.forEach((item, index) => {
      if (item.id === id) {
        const updatedElement = { ...item, isSelected: true };
        initialOptionsLocal[index] = updatedElement;
        options.push(updatedElement);
        list.push(element);
        return false;
      }

      if (item.parentId !== parentId) return false;

      if (item.parentId === parentId && !item.isSelected) allSelected = false;
    })

    if (allSelected) {
      initialOptionsLocal.forEach((item, index) => {
        if (!item.isChild && item.id === element.parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: false };
          list.push(item);
        }
        if (item.parentId === parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: true };
          list = list.filter(i => (i.parentId !== parentId))
        }
      })
    }

    initialOptions.current = initialOptionsLocal;
    return [list, options];
  }

  const handleRemovedOption = (element, list, options) => {

    if (element.value === 'select-all') {
      handleClearAll();
      setSelectedOption([])
      debounced([]);
      return [[], []];
    }

    if (element.isChild) {
      list = list.filter(item => (item.id !== element.id))

      const updatedSelectedOptions = options.filter(el => (element.id !== el.id))
      return [list, updatedSelectedOptions];
    }

    let updatedSelectedOptions = options;
    let initialOptionsLocal = [...initialOptions.current];
    initialOptionsLocal.forEach((item, index) => {

      if (item.parentId === element.id || item.id === element.id) {
        list = list.filter(el => (el.id !== element.id));
        initialOptionsLocal[index] = { ...item, isHidden: false, isSelected: false };
        updatedSelectedOptions = updatedSelectedOptions.filter(el => (item.id !== el.parentId))
      }
    })

    initialOptions.current = initialOptionsLocal;

    return [list, updatedSelectedOptions];
  }

  const handleClearAll = () => {
    let initialOptionsLocal = [...initialOptions.current]
    initialOptionsLocal.forEach((item, index) => {
      initialOptionsLocal[index] = { ...item, isHidden: false, isSelected: false };
    });

    initialOptions.current = initialOptionsLocal;
    setSelected([])
  }

  const handleChange = (list, element) => {
    switch (element.action) {
      case 'select-option':
        let [addedItemList, selectOptions] = handleAddedOption(element.option, list, selectedOptions);
        setSelected(addedItemList);
        setSelectedOption(selectOptions);
        debounced(selectOptions);
        break;
      case 'remove-value':
        let [removedItemList, removeOptions] = handleRemovedOption(element.removedValue, list, selectedOptions);
        setSelected(removedItemList);
        setSelectedOption(removeOptions);
        debounced(removeOptions);
        break;
      case 'clear':
        handleClearAll();
        setSelectedOption([])
        debounced([]);
        break;
      default:
        break;
    }
  }

  const animatedComponents = makeAnimated();
  const handleChangeSingleMode = (list) => {
    setSelectedSingleMode(list)
    debounced(list)
  }

  const formatGroupLabel = (data) => (
    <div className={classes.groupStyles}>
      <span className={classes.labelStyles}>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );


  return (
    <>
      {!isDetail && (
        <Paper elevation={0} className={classes.paper}>
          <Grid container style={{ justifyContent: 'space-around', alignItems: 'center', padding: '0 4px' }}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Icon path={icon} width={26} />
            </Grid>
            <Grid item xl={11} lg={11} md={10} sm={10} xs={10}>
              {isSingleMode ? (
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder={placeholder}
                  options={childOptions.current}
                  onChange={(list, index) => handleChangeSingleMode(list)}
                  value={selectedSingleMode}
                  formatGroupLabel={formatGroupLabel}
                />
              ) : (
                <Select
                  id="select-vehicles"
                  placeholder={placeholder}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={initialOptions.current}
                  styles={customStyles ? customStyles : colourStyles}
                  onChange={(list, index) => handleChange(list, index)}
                  value={selected}
                  noOptionsMessage={() => "Nenhum resultado encontrado"}
                />

              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}

export default MultiSelectFilters
