import React from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { History, LinkOff } from "@material-ui/icons";
import { convertDateBr }  from "helpers/dates";
import makeStyles from "./styles";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ handleUnlink, handleHistory, size }) => {
  const classes = makeStyles();
  let columns = [
    {
      name: "identification",
      label: size.mobile? ' ':"Veículo",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.textBold}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "currentDriver",
      label: "Motorista atual vinculado",
      options: {
        customBodyRender: (value) => {
          return value?.driverName ? (
            <Typography variant="body2" className={classes.textBold}>
              {value.driverName}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Sem motorista vinculado
            </Typography>
          );
        },
      },
    },
    {
      name: "plate",
      label: "Placa",
    },
    {
      name: "currentDriver",
      label: "Data do último vínculo",
      options: {
        customBodyRender: (value) => {
          return value?.startDate ? (
            convertDateBr(value.startDate)
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Sem vínculo atual
            </Typography>
          );
        },
      },
    }
  ];
    if (!size.mobile && hasPermission({scopes: ['can_view_linkdrivershistory', 'can_edit_linkdrivers']})) {
      columns.push({
        name: "id",
        label: "Ações",
        options: {
          sort: false,
          filter: false,
          viewColumns: false,
          customBodyRender: (vehicleId, metaData) => {
            return (
              <div className={classes.actionContent}>
                <PermissionsGate scopes={['can_view_linkdrivershistory']}>
                  <Tooltip title="Histórico">
                    <History size={22}
                      onClick={() => handleHistory(metaData.rowData)}
                      className={classes.actionButton}
                    />
                  </Tooltip>
                </PermissionsGate>
                <PermissionsGate scopes={['can_edit_linkdrivers']}>
                  {metaData.rowData[1] && metaData.rowData[1].driverName && (
                    <Tooltip title="Desvincular motorista">
                      <LinkOff size={22}
                        onClick={() => handleUnlink(metaData.rowData)}
                        className={classes.actionButton}
                      />
                    </Tooltip>
                  )}
                </PermissionsGate>
              </div>
            );
          },
        },
      });
    }

    return columns;
};
