import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import globalSlice, { logout } from "./globalSlice";
import bonusFollowSlice, { setBonusFilters } from "./features/bonusFollowSlice";
import driversMonitoringSlice, {
  setFilters,
} from "./features/driversMonitoringSlice";
import customerProfileSlice from "./features/customerProfileSlice";
import consolidatedSlice from "./features/consolidatedSlice";
import comparisonSlice from "./features/comparisonSlice";
import vehicleDashboardSlice from "./features/vehicleDashboardSlice";
import liveMapSlice from "./features/liveMapSlice";

const rootReducer = combineReducers({
  global: globalSlice,
  bonusFollow: bonusFollowSlice,
  driversMonitoring: driversMonitoringSlice,
  customerProfile: customerProfileSlice,
  consolidated: consolidatedSlice,
  comparison: comparisonSlice,
  vehicleDashboard: vehicleDashboardSlice,
  liveMap: liveMapSlice
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          setFilters.type,
          setBonusFilters.type,
        ],
        warnAfter: 128,
      },
    }),
});

export const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export const resetStore = async () => {
  await persistor.purge();
  store.dispatch(logout());
  await persistor.flush();
};
