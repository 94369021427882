import React from 'react'
import { Grid, List, ListItem, Typography } from '@material-ui/core'

import useStyles from './styles'
import {NumberFormatBR} from "../../../../helpers/numberFormat";

const ConsolidatedProductivity = ({data}) => {
  const classes = useStyles()
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography variant="h4" className={classes.cardTitle}>Baixa produtividade</Typography>
      <Grid container spacing={2} className={classes.contentBox}>
        <List dense={true} className={classes.list}>
          {data?.data.length ? data?.data.slice(0, 5).map((item, index) => {
            return (
              <ListItem key={index}>
                <Grid container justifyContent='space-between'>
                  <Grid item>
                    <Typography className={classes.itemName}>{item.identification || '-'} - {item.plate || '-'}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.itemText}>{NumberFormatBR(item.statistics.totalMileage)} Km</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            )
          }) : (
            <ListItem>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography className={classes.itemName}>Não foram encontrados dados para esse período.</Typography>
              </Grid>
            </Grid>
          </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  )
}

export default ConsolidatedProductivity;
