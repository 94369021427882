import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import { makeValueStars, valueToPositiveFloat } from "helpers/functions";
import { convertDateBr }  from "helpers/dates";
import { Tooltip } from "@material-ui/core";
import makeStyles from "./styles";

export const makeColumns = (size, isPenalizer) => {
  const classes = makeStyles();
  const returnOptions = [
    {
      name: "vehicleIdentification",
      label: "Frota",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thFrota">{value}</Typography>;
        },
      },
    },
    {
      name: "achievedMileage",
      label: "Dist. percorrida",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thDistanciaPercorrida">{valueToPositiveFloat(value, 2)}</Typography>;
        },
      },
    },
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thNotaGeral">{valueToPositiveFloat(value, 2)}</Typography>;
        },
      },
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thInicioFaixaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAproveitamentoEmbalo">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thMotorLigadoParado">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAcelerandoAcimaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thPilotoAutomatico">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thExcessoDeVelocidade">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "engineBrakeScore",
      label: "Freio motor",
      options: {
        filter: isPenalizer.engineBrakeScore,
        customBodyRender: (value) => {
          return isPenalizer.engineBrakeScore ? (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2" data-cy="thFreioMotor">Não avaliado</Typography>
          );
        },
      },
    },
    {
      name: "throttlePressureScore",
      label: "Pressão do acelerador",
      options: {
        filter: isPenalizer.throttlePressureScore,
        customBodyRender: (value) => {
          return isPenalizer.throttlePressureScore ? (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2" data-cy="thPressaoDoAcelerador">Não avaliado</Typography>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    }
  ];
  return [
    {
      name: "origin",
      label: size.mobile ? ' ' : "Origem",
      options: {
        customBodyRender: (value) => {
          if (value.date) {
            return (
              <Typography
                variant="body2"
                className={classes.textBold}
                noWrap={true}
                data-cy="thOrigem" 
              >
                {convertDateBr(value.date)}
                {value.name ? ' - ' + value.name : ''}
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
              data-cy="thDestino"
            >
              {convertDateBr(value.date)}
              {value.name ? ' - ' + value.name : ''}
            </Typography>
          );
        },
      },
    },
    ...returnOptions,
  ];
};
