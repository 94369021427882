import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const PerformanceSkeleton = () => {  
  return (
    <Grid 
      container 
      spacing={3} 
      alignItems="center" 
      justifyContent="center"
      style={{
        flexDirection: "column",
        padding: "45px 0px 40px 0px",
      }}
    >
      <Grid item xl={12} lg={12} style={{
        paddingBottom: "5px"
      }}>
        <Skeleton variant="circle" width={145} height={145} />
      </Grid>
      <Grid item xl={12} lg={12} style={{paddingTop: "30px", paddingBottom: "15px"}}>
        <Skeleton variant="rect" width={130} height={25} />
      </Grid>
    </Grid>
  );
};

export default PerformanceSkeleton;
