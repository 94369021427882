import React, { useEffect, useState } from "react";
import {
  Button,
  Grid, IconButton, InputAdornment, Paper, TextField,
  Typography,
} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import Aux from "hoc/auxiliar";
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import PasswordChecklist from "../../../components/PasswordChecklist";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {kratos} from "../../../infra/KratosClient";
import api, {apiExt} from "../../../services/api";
import {toast} from "react-toastify";
import Backdrop from "../../../components/backdrop";
import Countdown from "react-countdown";
import classNames from "classnames";
import { handleAmplitudeEvent } from "services/amplitude";

const ChangePassword = () => {
  const classes = useStyles();
  const history = useHistory()
  const location = useLocation();
  const [passwordOld, setPasswordOld] = useState('')
  const [password, setPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = useState('')
  const [ready, setReady] = useState(false)
  const [visible, setVisible] = useState(false)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [identity, setIdentity] = useState(null)
  const [time,setTime] = useState(Date.now() + 1200000)
  
  //Buscando o token e enviando o id kratos
  useEffect(() => {
    try{
      kratos.toSession()
        .then(async ({ data }) => {
          const response = await api.get(`/web/v2/authenticator/auth/requestChangePass/${data.identity.id}`);
          setToken(response.data.data)
          setIdentity(data.identity.id)
          setLoading(false)
          setTime(Date.now() + 1200000)
        })
    }catch (e) {
      setLoading(false)
      toast.error('Erro ao iniciar o serviço de alterar senha, por favor tente novamente mais tarde ou entre em contato com o suporte.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history, location])

  const ChangePassword = async () => {
    try{
      setLoading(true)
      const response = await apiExt.post(`/web/v2/authenticator/execute/changePass`,{
        pass:password
      }, {headers: { 'Credentials':token }});
      if(response.data.success){
        setLoading(false)
        handleAmplitudeEvent('Password Updated')
        toast.success('Senha alterada com sucesso')
      }else{
        setLoading(false)
        toast.error('Erro ao alterar senha, por favor tente novamente mais tarde ou entre em contato com o suporte.')
      }
    }
    catch (e) {
      setLoading(false)
      toast.error('Erro ao alterar senha, por favor tente novamente mais tarde ou entre em contato com o suporte.')
    }
    
  }

  const clock = ({  minutes, seconds, completed }) => {
    if (completed) {
      return  <Typography variant="h1" color={'error'} className={classNames(classes.title3,classes.timePassword)}>Tempo expirado, por favor recarregue a página.</Typography>;
    } else {
      const minutesNow = minutes < 10 ? '0' + minutes.toString() : minutes
      const secondsNow = seconds < 10 ? '0' + seconds.toString() : seconds
      return  <Typography variant="h1" className={classNames(classes.title2,classes.timePassword)}>Tempo para trocar a senha: {minutesNow}:{secondsNow}</Typography>;
    }
  };
  
  return (
    <Aux>
      <Backdrop open={loading}/>
        <Grid container spacing={4}>
          <Grid container lg={12} md={12} sm={12} xs={12} justifyContent={'center'}>
            <Paper className={classes.container}>
              <Grid item xl={12} xs={12}>
                <Typography variant="h1" className={classes.title}>Alterar meus dados de acesso</Typography>
                <Typography variant="h1" className={classNames(classes.title2,classes.passwordTips)}>
                  Para alterar sua senha com segurança pedimos que siga com atenção os passos abaixo, lembre-se de NÃO utilizar o seu nome ou qualquer tipo de dados pessoais. A nova senha precisa ter pelo menos:
                </Typography>
                  <PasswordChecklist
                    passwordOld={passwordOld}
                    passwordOldForm={true}
                    password={password}
                    passwordConfirm={passwordAgain}
                    setReady={setReady}
                    identity={identity}
                    setLoading={setLoading}
                  />
                <Typography variant="h1" className={classNames(classes.title2,classes.acceptanceCriteria)}>Siga os critérios de aceitação acima para conseguir criar uma nova senha com sucesso.</Typography>
                    <TextField
                      name="passwordOld"
                      variant="outlined"
                      autoComplete="new-password"
                      className={classes.stylesTextField}
                      fullWidth
                      onChange={(e) => setPasswordOld(e.target.value)}
                      label="Digite sua senha atual *"
                      type={visible ? 'text' : 'password'}
                      InputProps={{
                        className:classes.stylesInputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g clipPath="url(#clip0_614_25887)">
                                <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" fill="black"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_614_25887">
                                  <rect width="24" height="24" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setVisible(!visible)}
                          >
                            <IconButton>
                              {visible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>),
                      }}
                    />
                    <TextField
                      name="password"
                      autoComplete="new-password"
                      variant="outlined"
                      className={classes.stylesTextField}
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                      label="Digite uma nova senha *"
                      type={visible ? 'text' : 'password'}
                      InputProps={{
                        className:classes.stylesInputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g clipPath="url(#clip0_614_25887)">
                                <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" fill="black"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_614_25887">
                                  <rect width="24" height="24" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setVisible(!visible)}
                          >
                            <IconButton>
                              {visible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>),
                      }}
                    />
                    <TextField
                      name="confirmPassword"
                      autoComplete="new-password"
                      variant="outlined"
                      className={classes.stylesTextField}
                      fullWidth
                      onChange={(e) => setPasswordAgain(e.target.value)}
                      label="Confirme sua nova senha *"
                      type={visible ? 'text' : 'password'}
                      InputProps={{
                        className:classes.stylesInputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g clipPath="url(#clip0_614_25887)">
                                <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" fill="black"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_614_25887">
                                  <rect width="24" height="24" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setVisible(!visible)}
                          >
                            <IconButton>
                              {visible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>),
                      }}
                    />
                    <Countdown date={time} renderer={clock} />
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={!ready}
                      className={classes.btConfirm}
                      onClick={ChangePassword}
                    >
                      Salvar
                    </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classes.btBack}
                    onClick={()=>history.goBack()}
                  >
                    Voltar
                  </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
    </Aux>
  );
};

export default ChangePassword;
