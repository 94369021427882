import React from 'react';
import Lottie from 'react-lottie';

function LottieComponent({animationData, width, height, loop = true, autoplay = true}) {
    const defaultOptions = {
        loop,
        autoplay,
        animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div>
        <Lottie
          options={defaultOptions}
          width={width || 40}
          height={height || 40}
          controls={false}
          isClickToPauseDisabled={true}
        />
      </div>
    );
}

export default LottieComponent;
