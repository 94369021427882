import React, { useState, useMemo, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "hooks/useWindowsSize";
import useCluster from "hooks/useCluster";
import ClusterSingle from "componentsNew/atoms/clusterSingle/clusterSingle";
import ClusterGroup from "componentsNew/atoms/clusterGroup/clusterGroup";
import Lottie from "componentsNew/atoms/lottie/lottie";
import LoadingLottie from "assets/lottieFiles/mapLoading.json";
import "./map.css";

const Map = ({
  points,
  initLocationCenter,
  initZoom,
  loading,
  height,
  width,
  closeConnection,
  modalDetails,
  handleModalDetails
}) => {
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(initZoom);
  const mapRef = useRef();
  const dataPoints = useRef([]);
  const size = useWindowSize();
  const uuid = uuidv4();

  //criando objetos do cluster
  useEffect(() => {
    if (JSON.stringify(dataPoints.current) !== JSON.stringify(points)) {
      dataPoints.current = points.map((elem) => {
        return {
          vehicleId: elem.vehicleId,
          type: "Feature",
          ...elem,
          properties: {
            cluster: false,
          },
          geometry: {
            type: "Point",
            ...elem,
            coordinates: [elem.lng, elem.lat],
          },
        };
      });
    }
  }, [points])

  const { clusters, supercluster } = useCluster({
    points: dataPoints.current,
    bounds,
    zoom,
    options: { radius: 200, maxZoom: 50 },
  });

  return (
    <div className={"Map-container"}>
      {loading ? (
        <Lottie
          animationData={LoadingLottie}
          height={size.mobile ? 80 : 300}
          width={size.mobile ? 80 : 300}
        />
      ) : (
        <div
          className={"Map-container-item"}
          style={{
            height: height || "50vh",
            width: width || "auto",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyApnbkideYXxPEwwRnWPEZoIciQynUABJI",
              language: "pt-BR",
              libraries:['places']
            }}
            defaultCenter={initLocationCenter}
            defaultZoom={initZoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ]);
            }}
          >
            {/* mapeando os pontos */}
            {clusters &&
              clusters.map((cluster, index) => {
                // pegando latitude e longitude do ponto
                const [longitude, latitude] = cluster.geometry.coordinates;
                // pegando variavel se for group e o total do grupo
                const { cluster: isCluster, point_count: pointCount } =
                  cluster.properties;
                if (pointCount === undefined) {
                  clusters.splice(index, 1);
                }
                // se for grupo
                if (isCluster && cluster.id) {
                  // convertendo o cluster para pegar os pontos filhos
                  const clusterTrue = supercluster.getChildren(cluster?.id);
                  // fazendo um loop para achar todos os pontos dentro daquele grupo
                  for (let i = 0; i < pointCount; i++) {
                    clusterTrue.map((point, i) => {
                      // validando se tem um grupo dentro do grupo pai e buscando os filhos
                      if (point && point.id) {
                        try {
                          // convertendo o cluster para pegar os pontos filhos
                          const clusterSecond = supercluster.getChildren(
                            point.id,
                          )
                          if (clusterSecond) {
                            // //removendo o cluster convertido para não ter dados duplicados
                            clusterTrue.splice(i, 1);
                            // //adicionando os pontos no grupo pai
                            clusterTrue.push(...clusterSecond);
                          }
                        } catch {
                          console.log('Not found ID cluster')
                        }
                      }
                    });
                  }

                  //cadastrando objeto inicial do ClusterGroup
                  const colors = {
                    blue: 0,
                    green: 0,
                    yellow: 0,
                    gray: 0,
                    white: 0,
                    red: 0,
                  };
                  // cadastrando as cores de cada ponto
                  clusterTrue.map((op) => {
                    colors[op?.color] = colors[op?.color] + 1;
                  });

                  return (
                    <ClusterGroup
                      lat={latitude}
                      lng={longitude}
                      key={cluster?.id + "-" + uuid + "group"}
                      {...colors}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster?.id),
                          20,
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({
                          lat: latitude,
                          lng: longitude,
                        });
                      }}
                    />
                  );
                } else {
                  //se for apenas o ponto
                  return (
                    <ClusterSingle
                      key={cluster?.title + "-" + uuid + "single"}
                      closeConnection={closeConnection}
                      vehicle={cluster}
                      modalDetails={modalDetails}
                      handleModalDetails={handleModalDetails}
                      {...cluster}
                    />
                  );
                }
              })}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default Map;

Map.propTypes = {
  /**
   * Zoom inicial
   */
  initZoom: PropTypes.number.isRequired,
  /**
   * Altura do mapa
   */
  height: PropTypes.any,
  /**
   * Pontos no mapa
   */
  width: PropTypes.any,
  /**
   * Pontos no mapa
   */
  points: PropTypes.array,
  /**
   * Carregamento
   */
  loading: PropTypes.bool,
  /**
   * Ponto central inicial
   */
  initLocationCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

Map.defaultProps = {
  points: [
    {
      color: "blue",
      title: "GOBRAX",
      lat: -25.43291049648609,
      lng: -49.279102471163874,
    },
  ],
  initLocationCenter: {
    lat: -25.43291049648609,
    lng: -49.279102471163874,
  },
  initZoom: 15,
  loading: true,
};
