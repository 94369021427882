import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/styles";

export function TitlePage({ title }) {
  const theme = useTheme();
  return (
    <Grid>
      <Typography
        variant="h2"
        style={{
          fontWeight: 600,
          cursor: "default",
          fontSize: "24px",
          color: theme.palette.text.title2
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
}
