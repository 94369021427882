import { useState, useEffect } from "react";
import { store } from "redux/store";

const URL = process.env.REACT_APP_LINK_WSS + "/web/v2/location/ws/vehicle/status?refresh=5&session_id=";

export const useWebSocketLocationGroupVehicleStatus = (setLoading, id) => {
  const [vehicle, setVehicle] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [endpoint, setEndpoint] = useState("");
  const [currentSocket, setCurrentSocket] = useState();
  const { token } = store.getState().global.user;

  const closeConnection = () => {
    currentSocket.close(1000, 'Fechando conexão normalmente')
  }
  
  //carregar o sessionID
  useEffect(() => {
    setEndpoint(URL + token );
  }, [token, id]);

  //Chamar WS sempre atualizando a lista
  useEffect(() => {
    setLoading(true);
    if (!!token && !!endpoint && refresh && !!id) {
      const websocket = new WebSocket(endpoint + "&vehicle_id=" + id);
      setCurrentSocket(websocket)
      websocket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if(response.data){
          setLoading(false);
        }
        setVehicle(() => response.data ?? {});
      };
      websocket.onerror = (error) => {
        setRefresh(false)
        setTimeout(()=>{
          setRefresh(true)
        },2000)
      };
      return () => {
        websocket.close(1000, 'Fechando conexão normalmente');
      };
    }
  }, [endpoint,refresh]);

  return {
    vehicle,
    closeConnection
  };
};
