import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    textBold: {
        fontWeight: "bold",
        fontSize: 36,
        color: 'black',
        marginBottom: 16,
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 18
        },
    },
    dialogBase: {
        overflow: 'clip',
        borderRadius: 32,
        [theme.breakpoints.down('sm')]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            bottom: 0,
            position: 'absolute'
        },
    },
    description: {
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 16
        },
    },
    titleRoot: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '2px',
        color: theme.palette.grey[500],
    },
    gridRoot: {
        flexGrow: 1,
        paddingTop: 12,
    },
    contentBox: {
        overflow: 'auto',
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
            paddingTop: 32
        },
    },
    listItem: {
        fontSize: 16,
        fontWeight: 500,
        color: 'black',
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        },
    },
    listIcon: { color: '#40D81B', marginRight: 8, height: 32, width: 32 }
}));
