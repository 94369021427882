import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  wrapperContent: {
    padding: 5,
  },
  dropzone: {
    flex: 1,
    height: "auto",
  },
  container: {
    display: "flex",
    touchAction: "none",
    flex: 1,
    height: "100vh",
  },
  chartTitle: {
    fontWeight: 500,

    '&::first-letter': {
     textTransform: 'uppercase'
    },
  },
  paper: {
    borderRadius: 20,
    maxWidth: "100%",
    padding: "4px 4px 4px 4px",
    margin: 10,
    marginBottom: 10,
    boxShadow: `3px 5px 4px ${theme.palette.background.backgroundButton}`,
  },
  multiselect: {
    borderRadius: 20,
    maxWidth: "100%",
    margin: 10,
    marginBottom: 10,
    boxShadow: `3px 5px 4px ${theme.palette.background.backgroundButton}`,
  },
}));
