import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: 28,
    padding: 20
  },
  print: {
    padding: 0,
    backgroundColor: "inherit !important"
  },
  splide: {
    "& .splide__track": {
      padding: "5px !important",
      
    },
  },
  chartButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  chartButtonText: {
    textTransform: 'initial',
    fontWeight: 700,
    fontSize: 14,
    paddingRight: 5
  },
  mapButton: {
    marginRight: theme.spacing(3),
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  containerBtChart: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  itemToPrint: {
    marginBottom: theme.spacing(1)
  }
}));
