import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  pageTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  pageTitle: {
    fontWeight: "bold",
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  btnNewDriver: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  amountVehicleTitle: {
    marginTop: theme.spacing(2)
  }
}));
