import React from "react";
import { Grid } from "@material-ui/core";
import StatisticsCard from "components/StatisticsCard";
import { valueToPositiveFloat } from "helpers/functions";
import statisticsItens from "./statisticsItens";
import { useWindowSize } from "hooks/useWindowsSize";
import { hasPermission } from "components/PermissionsGate";

const StatisticsCards = (props) => {
  const { print, statistics, indicatorsPressure } = props;
  const size = useWindowSize();
  const canViewBruteBreak = hasPermission({ scopes: ['can_view_dashboard_brute_break']});

  let statisticsCardsArray = [];
  let cloneStatisticsCards = statisticsItens.cards.slice();
  while (cloneStatisticsCards.length > 0) {
    statisticsCardsArray.push(cloneStatisticsCards.splice(0, 4));
  }

  const showStatistics = (item) => {
    if (statistics[item.name]) {
      if (typeof statistics[item.name] === "string") {
        return statistics[item.name];
      } else {
        return valueToPositiveFloat(statistics[item.name], 2);
      }
    } else {
      return 0;
    }
  };

  return !print ? (
    <Grid container spacing={1}>
      {statisticsCardsArray.map((statisticsArray, index) =>
        statisticsArray.map((item, i) => {
          if ((item.name === 'totalBruteBrake' && !canViewBruteBreak) || (item.name === 'totalBreakin' && canViewBruteBreak)) {
            return;
          }
          return (
            <Grid
              item
              xl={indicatorsPressure && !size.mobile ? 3 : 3}
              lg={indicatorsPressure && !size.mobile ? 3 : 3}
              md={indicatorsPressure && !size.mobile ? 3 : 4}
              sm={indicatorsPressure && !size.mobile ? 3 : 6}
              xs={indicatorsPressure && !size.mobile ? 3 : 6}
              key={`statistics-${i}`}
              data-cy="statistics"
              style={{ height: 120, marginBottom: 10 }}
            >
              <StatisticsCard
                key={`statisticsItem-${index}`}
                title={item.title}
                label={showStatistics(item)}
                unit={item.unit}
                icon={item.icon}
                name={item.name}
              />
            </Grid>
          );
        }),
      )}
    </Grid>
  ) : (
    statisticsCardsArray.map((statisticsArray, index) =>
      statisticsArray.map((item, i) => {
        return (
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            key={`statistics-${i}`}
            data-cy="statistics"
          >
            <StatisticsCard
              key={`statisticsItem-${index}`}
              title={item.title}
              label={showStatistics(item)}
              unit={item.unit}
              icon={item.icon}
            />
          </Grid>
        );
      }),
    )
  );
};

export default StatisticsCards;
