import React from "react";
import Select from 'react-select';
import { useController, useFormContext, useFormState } from "react-hook-form";
import { useTheme } from "@material-ui/styles";

const CustomSelect = ({ name, scroll, zIndex, control, styles, noData, ...rest }) => {
  const theme = useTheme();
  const { control: controlContext } = useFormContext();
  const { errors } = useFormState({ control: control ?? controlContext });
  const { field } = useController({
    name,
    control: control ?? controlContext,
  });

  return (
    <Select
      {...field}
      {...rest}
      noOptionsMessage={() => noData || "Sem dados"}
      styles={{
        control: (provided) => ({
          ...provided,
          height: "auto",
          minHeight: 55,
          borderColor: errors[name]
            ? theme.palette.error.main
            : theme.palette.text.hint,
          fontFamily: theme.typography.fontFamily,
          borderRadius: 20
        }),
        container: (provided) => ({
          ...provided,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body1.fontSize,
        }),
        placeholder: (provided) => ({
          ...provided,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body1.fontSize,
        }),
        menu: (provided) => ({ ...provided, zIndex: zIndex ? zIndex : 9999 }),
        valueContainer: (provided, props) => {
          return scroll ? { 
            ...provided, 
            maxHeight: 100,
            overflowY: props.getValue().length > 3 ? "scroll" : "auto",
            overscrollBehaviorY: "contain",
            scrollSnapType: "y proximity",
            scrollSnapAlign: "end",
            marginBottom: "5px",
            zIndex: 9999
          } : { ...provided, zIndex: zIndex ? zIndex : 9999 }
        },
        ...styles
      }}
    />
  );
};

export default CustomSelect;
