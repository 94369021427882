import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { getColor } from "../../helpers/fleetChart";

import useStyle from "./styles";

export default function CheckboxFullChart({ handleClick }) {
  const theme = useTheme();
  const classes = useStyle();
  const seriesColors = getColor(theme);

  const [rpm, setRpm] = useState(true);
  const [fuel, setFuel] = useState(true);
  const [speed, setSpeed] = useState(true);
  const [altitude, setAltitude] = useState(true);

  const RPMCheckbox = withStyles({
    root: {
      color: seriesColors.rpm,
      "&$checked": {
        color: seriesColors.rpm,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const AltitudeCheckbox = withStyles({
    root: {
      color: seriesColors.altitude,
      "&$checked": {
        color: seriesColors.altitude,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const FuelCheckbox = withStyles({
    root: {
      color: seriesColors.fuel,
      "&$checked": {
        color: seriesColors.fuel,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const SpeedCheckbox = withStyles({
    root: {
      color: seriesColors.speed,
      "&$checked": {
        color: seriesColors.speed,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <FormControl focused={false} key={"checkbox-graphic"} component="fieldset">
      <FormLabel key="title-graphic" className={classes.legendTitle}>
        Clique em uma das legendas para ativar/desativar os filtros do gráfico
        <FormGroup key="checkbox-four" aria-label="position" row>
          <FormControlLabel
            name="Combustível"
            control={
              <FuelCheckbox
                checked={fuel}
                onChange={(e) => {
                  setFuel(e.target.checked);
                  handleClick("Combustível", e.target.checked);
                }}
                color="primary"
              />
            }
            label="Combustível"
          />
          <FormControlLabel
            name="RPM"
            control={
              <RPMCheckbox
                checked={rpm}
                onChange={(e) => {
                  setRpm(e.target.checked);
                  handleClick("RPM", e.target.checked);
                }}
              />
            }
            label="RPM"
          />
          <FormControlLabel
            name="Altitude"
            control={
              <AltitudeCheckbox
                checked={altitude}
                onChange={(e) => {
                  setAltitude(e.target.checked);
                  handleClick("Altitude", e.target.checked);
                }}
                color="primary"
              />
            }
            label="Altitude"
          />
          <FormControlLabel
            name={"Velocidade"}
            control={
              <SpeedCheckbox
                checked={speed}
                onChange={(e) => {
                  setSpeed(e.target.checked);
                  handleClick("Velocidade", e.target.checked);
                }}
                color="primary"
              />
            }
            label="Velocidade"
          />
        </FormGroup>
      </FormLabel>
    </FormControl>
  );
}
