import React, { useRef, useEffect } from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ShowChart from "@material-ui/icons/ShowChart";
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import classNames from "classnames";
import IndicatorSkeleton from "components/Skeletons/IndicatorSkeleton";
import Indicator from "components/Indicator";
import CustomIndicator from "components/CustomIndicator";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import useStyles from "./styles";
import "@splidejs/splide/dist/css/splide.min.css";
import { useWindowSize } from "hooks/useWindowsSize";
import LoadingComponent from "components/LoadingComponent";

const FleetPerformance = ({
  indicators,
  loading,
  handleFleetChart,
  indicatorsByOperation,
  handleDieselControl,
  print,
}) => {
  const classes = useStyles();
  const size = useWindowSize();
  const splideRef = useRef(null)
  let indicatorList = [...indicators];

  useEffect(() => {
    if (splideRef.current) {
      const { list } = splideRef.current?.splide?.Components?.Elements;
      list.classList.add('ulIndicadores');
    }
  }, [splideRef.current]);

  return (
    <Paper
      elevation={0}
      className={classNames(classes.root, { [classes.print]: print })}
    >
      <Grid container alignItems="center" data-cy="infoFrotaPorcentagens">
        {loading.indicators ? (
          <IndicatorSkeleton />
        ) : !indicators || indicatorList.length === 0 ? (
          <Alert severity="info">
            <AlertTitle>Atenção!</AlertTitle>
            Não foram encontrados indicadores para a frota selecionada,{" "}
            <strong>verifique os filtros.</strong>
          </Alert>
        ) : (
          <Grid container spacing={1}>
            {!print && (!size.mobile || hasPermission({ scopes: ['can_view_dashboard_chart_bt_mobile'] })) && (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                className={classes.containerBtChart}
              >
                <PermissionsGate scopes={["can_view_dashboard_chart"]}>
                  <Button
                    onClick={() => handleFleetChart(true)}
                    variant="contained"
                    className={classes.chartButton}
                    data-cy="buttonGrafico"
                  >
                    <Typography className={classes.chartButtonText}>Gráfico</Typography> <ShowChart fontSize="small" />
                  </Button>
                </PermissionsGate>
                <PermissionsGate scopes={["can_view_diesel_control"]}>
                  <Button
                    onClick={handleDieselControl}
                    variant="contained"
                    className={classes.chartButton}
                    style={{ marginRight: 15 }}
                    data-cy="buttonControleDiesel"
                  >
                    <Typography className={classes.chartButtonText}>Gestão do nível de combustível</Typography> <LocalGasStationIcon fontSize="small" />
                  </Button>
                </PermissionsGate>
              </Grid>
            )}
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {loading.statistics ? (
                <LoadingComponent />
              ) : !print ? (
                <Splide
                  options={{
                    type: "slide",
                    perPage: size.mobile
                      ? Math.trunc(size.width / 200)
                      : Math.trunc(size.width / 300),
                    rewind: true,
                    pagination: false,
                    classes: {
                      prev: 'splide__arrow--prev buttonLeft',
                      next: 'splide__arrow--next buttonRight',
                    },
                    label: {
                      prev: 'Slide anterior',
                      next: 'Próximo slide',
                    }
                  }}
                  className={classes.splide}
                  ariaLabel="Indicadores"
                  ref={splideRef}
                >
                  {indicatorList.map((ind, index) => {
                    const hasBonus = indicatorsByOperation.find(
                      (o) => o === ind.name,
                    );
                    return (
                      <SplideSlide key={index} >
                        {ind.name === "throttlePressureScore" ? (
                          <CustomIndicator
                            indicator={ind}
                            hasBonus={hasBonus}
                          />
                        ) : (
                          <Indicator indicator={ind} hasBonus={hasBonus} />
                        )}
                      </SplideSlide>
                    );
                  })}
                </Splide>
              ) : (
                <Grid container spacing={0}>
                  {indicatorList.map((ind, index) => {
                    const hasBonus = indicatorsByOperation.find(
                      (o) => o === ind.name,
                    );
                    return (
                      <Grid
                        item
                        key={index}
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className={classes.itemToPrint}
                      >
                        {ind.name === "throttlePressureScore" ? (
                          <CustomIndicator
                            indicator={ind}
                            hasBonus={hasBonus}
                          />
                        ) : (
                          <Indicator
                            indicator={ind}
                            hasBonus={hasBonus}
                            print={print}
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default FleetPerformance;
