import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import useStyles from './styles';
import Aux from 'hoc/auxiliar';
import TableResponsive from 'components/TableResponsive';
import { makeColumns } from './tableColumns';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { setStepAdd, setStepDeductBonus } from "redux/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";
import { handleAmplitudeEvent } from 'services/amplitude';

const RewardDetails = () => {
  const { filters: { calendar, cutoffDate } } = useAppSelector((state) => state.bonusFollow);
  const dispatch = useAppDispatch()
  const { state } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowSize();
  
  const [disabledButton, setDisabledButton] = useState(false);
  const download = {
    link: "/web/v2/performance/xls/singledriver",
    fileName: `relatorio-viagens_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
    params: {
      driverId: state.driverId,
      startDate: format(new Date((!!state.isCalendarFilter.id) ? JSON.parse(calendar).startDate : JSON.parse(cutoffDate).startDate), "yyyy-MM-dd'T'00:00:00XX"),
      endDate: format(new Date((!!state.isCalendarFilter.id) ? JSON.parse(calendar).finishDate : JSON.parse(cutoffDate).finishDate), "yyyy-MM-dd'T'00:00:00XX"),
      limit: 10000
    }
  }

  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();
  const [isPenalizer, setIsPenalizer] = useState({
    engineBrakeScore: false,
    throttlePressureScore: false
  });
  const columns = makeColumns(size, isPenalizer);

  const handleClickDetail = () => {
    handleAmplitudeEvent('Bonus Details View Trip Details Clicked', { driverId: state.driverId, driverName: state.name, vehicleName: selectedRowData.vehicleIdentification, startDate: selectedRowData.startDate, endDate: selectedRowData.endDate, totalMileage: selectedRowData.achievedMileage })
    dispatch(setStepAdd())
    history.push({
      pathname: "/gofurther/dashboard/general/bonus",
      state: {
        vehicleId: selectedRowData,
        screen: 'bonus',
        detail: state,
        startDate: selectedRowData.origin.date,
        finishDate: selectedRowData.destination.date,
      },
    });
  };

  const handleBack = (e) => {
    handleAmplitudeEvent('Backward Button Clicked')
    setDisabledButton(true)
    history.goBack()
    dispatch(setStepDeductBonus())
    setTimeout(() => {
      setDisabledButton(false)
    }, 5000)
  }

  const handleRowClick = (data, data2) => {
    const newSelectedRowData = state.data.find(
      (_info, index) => index === data2.dataIndex,
    );
    setId(data2.dataIndex);
    setSelectedRowData(newSelectedRowData);
    handleAmplitudeEvent('Bonus Details Trip Selected', { driverId: state.driverId, driverName: state.name, vehicleName: newSelectedRowData.vehicleIdentification, startDate: newSelectedRowData.startDate, endDate: newSelectedRowData.endDate, totalMileage: newSelectedRowData.achievedMileage })
  }

  useEffect(() => {
    if (state?.data) {
      setIsPenalizer({
        engineBrakeScore: state?.data[0]?.hasEngineBrake,        
        throttlePressureScore: state?.data[0]?.hasThrottlePenalizer
      })
      handleAmplitudeEvent('Bonus Details Screen Viewed', { driverId: state.driverId, driverName: state.name, trips: state.data.length });
    }
  }, [state])

  return (
    <Aux>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Box>
            {/* title */}
            <Grid item xl={12} xs={12} style={{ marginBottom: 15 }}>
              <Typography variant="h3" data-cy="h3RelatorioViagens">Relatório de viagens</Typography>
            </Grid>
            {/* motorista */}
            <Grid item xl={12} xs={12}>
              <Grid container>
                <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                  <Grid
                    container
                    component={Paper}
                    spacing={3}
                    className={classes.infoDriverContainer}
                  >
                    <Grid
                      className={classes.contentDriver}
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      data-cy="divMotorista"
                    >
                      <Typography className={classes.label}>
                        Motorista:{" "}
                      </Typography>
                      <strong className={classes.driverName}>
                        {state.name}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xl={12} xs={12} className={classes.marginTop} data-cy="tableViagens">
              {state?.data && <TableResponsive
                tableBodyMaxHeight={size.mobile ? size.height - 430 + 'px' : size.height - 330 + 'px'}
                data={state.data}
                columns={columns}
                download={download}
                options={{
                  rowsPerPage: 100,
                  setRowProps: (row, index) => {
                    return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                  },
                  onRowClick: (data, data2) => handleRowClick(data, data2),
                }}
                pointer={true}
                tableName="bonus-details"
              />}
            </Grid>
            {/* botoes */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box>
                    <Button
                      fullWidth
                      disabled={disabledButton}
                      variant="contained"
                      className={classes.buttonBack}
                      onClick={(e) => (disabledButton ? null : handleBack(e))}
                      data-cy="buttonVoltar"
                    >
                      {disabledButton ? "Aguarde..." : "Voltar"}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box marginTop={size.mobile ? "25px" : 0}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleClickDetail}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                      data-cy="buttonVerDetalhes"
                    >
                      Ver detalhes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default RewardDetails;
