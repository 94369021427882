import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "./styles";
import classnames from "classnames";
import { timeConvert, valueToPositiveFloat } from "helpers/functions";
import { hasPermission } from "components/PermissionsGate";

export const makeColumns = (size) => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap
      >
        {data}
      </Typography>
    );
  };

  const getCellIndicator = (value) => {
    const data = `${value?.duration
      ? `${valueToPositiveFloat(value?.percentage, 2)}% (${timeConvert(value.duration)})`
      : "0% (00:00:00)"
      }`;
    return getCell(data);
  };

  const columns = [
    {
      name: "customerName",
      label: size.mobile ? " " : "Empresa",
      options: {
        customBodyRender: (value) => getCell(value, true),
        filter: false,
        sort: false,
      },
    },
    {
      name: "identification",
      label: "Frota",
      options: {
        customBodyRender: (value) => getCell(value, true),
        filter: false,
        sort: false,
      },
    },
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) => getCell(value, true),
        filter: false,
        sort: false,
      },
    },
    {
      name: "brand",
      label: "Marca",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "truckModel",
      label: "Modelo do veículo",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "fabricationYear",
      label: "Ano de fabricação",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "motor",
      label: "Motorização",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "wheelDriver",
      label: "Tração",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "operationName",
      label: "Grupo de veículos",
      options: {
        customBodyRender: (value) => getCell(value, true),
        filter: false,
        sort: false,
      },
    },
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => getCell(value, true),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalMileage",
      label: "Km total",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} Km`),
        filter: false,
        sort: false,
      },
    },
    {
      name: "averageSpeed",
      label: "Velocidade média",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} Km/h`),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalConsumption",
      label: "Consumo total",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} L`),
        filter: false,
        sort: false,
      },
    },
    {
      name: "consumptionAverage",
      label: "Média computador de bordo",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value, 2)} Km/L`),
        filter: false,
        sort: false,
      },
    },
    {
      name: "odometer",
      label: "Odômetro",
      options: {
        customBodyRender: (value) => getCell(`${valueToPositiveFloat(value)} Km`),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalBreakingOnHighSpeed",
      label: "Freadas em alta veloc.",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalBreaking",
      label: "Freadas totais",
      options: {
        customBodyRender: (value) => getCell(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.extraEconomicRange",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.leverage",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.idle",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.powerRange",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.speeding",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.cruiseControl",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.greenRange",
      label: "Faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.economicRange",
      label: "Faixa econômica",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.engineBrake",
      label: "Freio motor",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.ecoRoll",
      label: "Eco-Roll/I-Roll",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.movement",
      label: "Tempo em movimento",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.lowPressure",
      label: "Uso do pedal ideal",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.medPressure",
      label: "Uso do pedal atenção",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.higPressure",
      label: "Uso do pedal crítico",
      options: {
        customBodyRender: (value) => getCellIndicator(value),
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];

  const filteredColumns = columns.filter((column) => {
    if (column.name === "indicators.ecoRoll") {
      return hasPermission({ scopes: ["can_view_eco_roll_indicator"] });
    }
    return true;
  });

  return filteredColumns;
};
