import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { Grid, Box, Typography, Paper } from "@material-ui/core";
import { toast } from "react-toastify";

// components
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Aux from 'hoc/auxiliar'

// helpers
import { findValueByKey } from "helpers/functions";
import { getRewardGroupSelecteds, periodTypeOptions } from 'helpers/functions';

// services
import { getOperationTypes } from 'services/operationType';
import { getCustomerDetails } from 'services/customers';

import useStyles from './styles';

const ViewCustomer = () => {
  const classes = useStyles()
  const { id } = useParams();
  const [ customer, setCustomer ] = useState(null);

  const fetchCustomerById = async () => {
    try {
      const response = await getCustomerDetails(id);
      const data = response.data;
      const period = getRewardGroupSelecteds(periodTypeOptions, [{ id: data.period }]);

      const responseOperationTypes = await getOperationTypes(id);
      const oprationTypesData = findValueByKey(responseOperationTypes.data, 'operationTypes');
      const oprationTypes = data?.operations?.map(elm => elm.id);
      const operations = oprationTypesData?.filter(elm => oprationTypes?.includes(elm.id));
      
      setCustomer({
        name: data.name || "",
        document: data.document || "",
        period: period[0]?.label || "",
        startingDay: data.starting_day || {},
        finishingDay: data.finishing_day || {},
        operations: operations?.map(elm => elm.name).join(", ") || "",
        note: data.note || "",
      });
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do cliente. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_customers_without_edit'] })) {
      fetchCustomerById();
    }
  }, []);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_customers_without_edit']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant="h1" className={classes.titleMain}>Visualizar cliente</Typography>
                <Paper elevation={0} className={classes.container}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container>
                        <Typography className={classes.title}>Dados do cliente</Typography>
                        <Grid container>
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Nome: </strong> {customer?.name || ' - '}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>CNPJ: </strong> {customer?.document || ' - '}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
          
                      <Grid container>
                        <Typography className={classes.title}>Data de corte/pagamento</Typography>
                        <Grid container>
                          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Tipo de período: </strong> {customer?.period || ' - '}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Data inicial: </strong> {customer?.startingDay || ' - '}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Data final: </strong> {customer?.finishingDay || ' - '}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Typography className={classes.title}>Tipos de bonificação liberadas</Typography>
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Operações: </strong> {customer?.operations || ' - '}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Typography className={classes.title}>Anotações sobre o cliente</Typography>
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Observações: </strong> {customer?.note || ' - '}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Box sx={{ m: 2 }}>
                          <BackButton className={classes.backButton} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ViewCustomer;
