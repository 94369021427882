export default [
  "name",
  "customIndicatorsList",
  "penalizingCustomIndicatorsList",
  "customControll",
  "penalizingCustomControll",
  "penalizingLowControll",
  "penalizingMediumControll",
  "penalizingHighControll",
  "lowControll",
  "mediumControll",
  "highControll",
  "penalty_score",
];