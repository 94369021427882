import React from "react";
import { valueToPositiveFloat } from "helpers/functions";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const makeColumns = (handleExportByDriver, size) => {
  const classes = makeStyles((theme) => ({
    textBold: {
      fontWeight: "bold",
    },
    primary: {
      color: theme.palette.primary.main,
    },
  }));

  const defaultColumns = [
    {
      name: "driverName",
      label: size.mobile ? " " : "Motorista",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.textBold} data-cy="Motoristas">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "achievedMileage",
      label: "Km rodado",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" data-cy="KmRodado">
              {valueToPositiveFloat(value, 0)}
            </Typography>
          );
        },
      },
    },
    {
      name: "monthScore",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2" data-cy="NotaGeral">{valueToPositiveFloat(value)}</Typography>
        ),
      },
    },

    {
      name: "totalTrips",
      label: "Qtde. viagens",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2" data-cy="QtdeViagens">{(value)}</Typography>
        ),
      },
    },
    {
      name: "totalReward",
      label: "Valor premiação",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classNames(classes.textBold, classes.primary)}
              data-cy="ValorPremiacao"
            >
              {value &&
                value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
            </Typography>
          );
        },
      },
    },
    {
      name: "id",
      label: "driverId",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
    /* {
      name: "id",
      label: "Download",
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      options: {
        customBodyRender: (value, meta) => {
          return (
            <IconButton
              color="primary"
              variant="contained"
              aria-label="Download"
              component="span"
              style={{ alignItems: "center" }}
              onClick={() => {
                handleExportByDriver(value, meta.rowData[0]);
              }}
            >
              <GetAppIcon />
            </IconButton>
          );
        },
      },
    } */
  ];

  return defaultColumns;
};

export default makeColumns;
