import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { convertDateBr }  from "helpers/dates";

import EventIcon from '@material-ui/icons/Event';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';
import { setStepDeduct } from 'redux/features/driversMonitoringSlice';
import { setStepDeductBonus } from 'redux/features/bonusFollowSlice';
import { useAppDispatch } from 'redux/store';
import { useWindowSize } from "../../hooks/useWindowsSize";
import { handleAmplitudeEvent } from 'services/amplitude';

function DriverPerformanceDetailComponent({ vehicleProps }) {
  const dispatch = useAppDispatch()
  const { detail, vehicleId, startDate, finishDate, screen } = vehicleProps
  const classes = useStyles()
  const history = useHistory()
  const size = useWindowSize()
  const origin = vehicleId.origin.name ? convertDateBr(startDate) + ' (' + vehicleId.origin.name + ')' : convertDateBr(startDate);
  const destination = vehicleId.destination.name ? convertDateBr(finishDate) + ' (' + vehicleId.destination.name + ')' : convertDateBr(finishDate);
  return (
    <Box sx={{ mb: 2 }}>
      <Grid container>
        <Grid item>
          <Typography className={classes.title} data-cy="pTitulo">Performance do motorista selecionado</Typography>
        </Grid>
      </Grid>
      <Paper className={classes.paper} data-cy="divGrupoSelects">
        <Grid container>
          <Grid className={classes.wrapperContent} container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid className={classes.flex} data-cy="divSelectOperacao">
              <LocalShippingIcon className={classes.icon} />
              <Typography>{vehicleId.vehicleIdentification} / {vehicleId.plate}</Typography>
            </Grid>
            <Grid className={classes.flex} data-cy="divSelectMotorista">
              <PersonIcon className={classes.icon} />
              <Typography>{detail.name}</Typography>
            </Grid>
            <Grid className={classes.flex} data-cy="divSelectCalendario">
              <EventIcon className={classes.icon}/>
              <Typography className={classes.icon}>{origin} - </Typography>
              <Typography className={classes.icon}>{destination}</Typography>
            </Grid>
            {size.mobile ? null :
              <Grid className={classes.flex}>
                <Button
                  onClick={() => {
                    handleAmplitudeEvent('Backward Button Clicked');
                    if (screen !== 'bonus') {
                      dispatch(setStepDeduct())
                    } else {
                      dispatch(setStepDeductBonus())
                    }
                    history.goBack();

                  }}
                  variant="contained"
                  className={classes.backButton}
                  data-cy="buttonVoltar"
                >
                  <ArrowBackIcon fontSize="small" />{" "}Voltar
                </Button>
              </Grid>}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default DriverPerformanceDetailComponent;
