import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ZoomIn } from "@material-ui/icons";
import RecalculationStatus from "components/RecalculationStatus";

import makeStyles from "./styles";

export const makeColumns = ({ showRecalculation }) => {
  const classes = makeStyles();

  const getValue = (value, withoutValue) => {
    return value ? (
      <Typography variant="body2">
        {value}
      </Typography>
    ) : (
      <Typography variant="body2" className={classes.textInfo}>
        {withoutValue}
      </Typography>
    );
  };

  return [
    {
      name: "process_date",
      label: "Data/hora",
      options: {
        customBodyRender: (value) => getValue(value, "Sem dados"),
      },
    },
    {
      name: "user",
      label: "Feito por usuário",
    },
    {
      name: "period",
      label: "Período do recálculo",
      options: {
        customBodyRender: (value) => getValue(value, "Sem período"),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => <RecalculationStatus value={value} />,
      },
    },
    {
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid container direction="column" alignItems="center">
              <Tooltip title="Visualizar Recálculo" aria-label="visualizar-recalculo">
                <IconButton
                  onClick={() => showRecalculation(value)}
                  aria-label="visualizar-recalculo"
                >
                  <ZoomIn />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
  ];
};
