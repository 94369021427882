import React from 'react';
import './cardDescription.css';

export const CardDescription = ({ description, cardDescriptionStyle, props }) => {
  return (
    <div
      className={`CardDescription-container`}
      style={cardDescriptionStyle}
      {...props}
    >
      {description}
    </div>
  );
};

CardDescription.defaultProps = {
  description: 'Descrição',
};
