import React from 'react'
import { Grid, List, ListItem, Typography } from '@material-ui/core'
import BorderLinearProgress from 'components/BorderLinearProgress'

import useStyles from './styles'
import {NumberFormatBR} from "../../../../helpers/numberFormat";

const ConsolidatedBrand = ({data}) => {

  const classes = useStyles()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography variant="h4" className={classes.cardTitle}>Média por marca</Typography>
      <Grid container spacing={2} className={classes.contentBox}>
        <List dense={true} className={classes.list}>
       
          {data?.data.length ? data?.data.map(item => {
            return (
              <ListItem key={item.brand}>
                <Grid container justifyContent='space-between'>
                  <Grid item>
                    <Typography className={classes.itemText}>{item.brand}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.itemText}>{item.score} de 100 ({NumberFormatBR(item.statistics.consumptionAverage)} Km/L)</Typography>
                  </Grid>   
                  <Grid item xs={12}>
                    <BorderLinearProgress variant="determinate" value={item.score} />
                  </Grid>                
                </Grid>
              </ListItem>
            )
          }): (
            <ListItem>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography className={classes.itemName}>Não foram encontrados dados para esse período.</Typography>
              </Grid>
            </Grid>
          </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  )
}

export default ConsolidatedBrand;
