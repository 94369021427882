import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Close as CloseIcon } from "@material-ui/icons";
import fields from "../../domain/forms/formLinkDeviceInitialvalues";
import Select from "../react-hook-form/select";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { CalendarToday as CalendarIcon } from "@material-ui/icons";

// styles
import useStyles from "./styles";
import { Controller, FormProvider, useForm } from "react-hook-form";
import makeDefaultValues from "../../helpers/makeDefaultValues";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { LinkDeviceToVehicle, unLinkDevice } from "../../services/device";
import { Input } from "@material-ui/core";

const LinkDeviceToVehicleModal = ({ isUnlink, deviceId, handleLinkDevices, fetchDevices, vehicleId, devices, handleClose, open, loading }) => {
  const classes = useStyles();
  const [isConflict, setIsConflict] = useState(null)
  const [msgConflict, setMsgConflict] = useState(null)
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
  });

  const formatDate = 'dd/MM/yyyy HH:mm';

  const [dates, setDates] = useState({
    initialDate: format(new Date(), formatDate)
  });

  const handleConfirmConflict = async () => {
    try {
      const responseBody = {
        deviceId: isConflict,
        accepted: true
      }
      const response = await LinkDeviceToVehicle(vehicleId, responseBody);
      if (response.status === 200 || response.status === 201) {
        toast.success("Vínculo realizado com sucesso!");
        setIsConflict(null)
        setMsgConflict(null)
        handleClose()
        handleLinkDevices()
        fetchDevices()
      }
    } catch (error) {
      toast.error("Algo inexperado aconteceu, contate o suporte!");
    }
  }

  const handleUnlinkDevice = async () => {

    try {
      const response = await unLinkDevice(vehicleId, deviceId);
      if (response.status === 200 || response.status === 201) {
        toast.success("Vínculo realizado com sucesso!");
        setIsConflict(null)
        setMsgConflict(null)
        handleClose()
        handleLinkDevices()
        fetchDevices()
      }
    } catch (error) {
      toast.error("Algo inexperado aconteceu, contate o suporte!");
    }
  }

  const handleInitialDate = (startDate) => {
    const finalDateFormatted = moment(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate()
    if (startDate) {
      if (startDate > finalDateFormatted) {
        setDates({
          finalDate: format(startDate, formatDate),
          initialDate: format(startDate, formatDate)
        });
      } else {
        setDates({
          ...dates,
          initialDate: format(startDate, formatDate)
        });
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      if (!data.devices) {
        return toast.warning("Selecione uma unidade eletrônica para vincular.");
      }

      const deviceId = {
        deviceId: data.devices.value
      }
      const response = await LinkDeviceToVehicle(vehicleId, deviceId);
      if (response.status === 200 || response.status === 201) {
        toast.success("Vínculo realizado com sucesso!");
        handleClose();
        handleLinkDevices()
        fetchDevices()
      }
      if (response.status === 500) {
        setIsConflict(data.devices.value)
        setMsgConflict(response.data.error_description)
      } else {
        toast.error(
          "Não foi possível vincular unidade eletrônica. Contate o suporte",
        );
      }

    } catch (error) {
      toast.error(
        "Não foi possível vincular unidade eletrônica. Contate o suporte",
      );
    }
  };

  const onClose = () => {
    handleClose()
    setIsConflict(null)
  }

  const renderLinkDevice = () => {

    if (!!isConflict) {
      return (
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.titleUnlink}>{msgConflict}</Typography>
            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        onClick={onClose}
                        className={classes.buttonClose}
                      >
                        Cancelar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        className={classes.btConfirm}
                        onClick={() => handleConfirmConflict()}
                      >
                        Confirmar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Controller
                  control={methods.control}
                  name="test"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <>
                      <DateRangePicker
                        initialSettings={{
                          startDate: new Date(),
                          singleDatePicker: true,
                          timePicker24Hour: true,
                          timePicker: true,
                          minDate: new Date(),
                          maxDate: new Date(),
                          locale: {
                            format: 'DD/MM/YYYY HH:mm',
                            applyLabel: 'Aplicar',
                            cancelLabel: 'Cancelar',
                          },
                          opens: 'center'
                        }}
                        autoApply={true}
                        onApply={(event, picker) => {
                          handleInitialDate(picker.startDate._d);
                        }}
                      >
                        <div className={classes.containerInputCalendar}>
                          <Input data-cy='inputCalendar'
                            className={classes.inputCalendar}
                            value={dates.initialDate}
                          />
                          <CalendarIcon
                            fontSize="medium"
                            data-cy="svgButtonCalendar"
                          />
                        </div>
                      </DateRangePicker>


                    </>
                  )}
                />

              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Select
                  isLoading={loading}
                  options={devices}
                  name="devices"
                  placeholder="Selecione a unidade eletrônica"
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className={classes.btnBack}
                        onClick={() => {
                          handleClose();
                          methods.reset()
                        }}
                      >
                        Voltar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        className={classes.btConfirm}
                      >
                        Vincular
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      )
    }
  }

  const renderUnlinkDevice = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.titleUnlink}>Deseja realmente desvincular a unidade eletrônica?</Typography>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      onClick={handleClose}
                      className={classes.backButton}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className={classes.btConfirm}
                      onClick={() => handleUnlinkDevice()}
                    >
                      Confirmar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={() => onClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { borderRadius: 22, maxHeight: 500 }
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => onClose()} disableTypography>
        {isUnlink ? (
          <Typography variant="h6" className={classes.title}>Desvincular unidade eletrônica</Typography>
        ) : (
          <Typography variant="h6" className={classes.title}>Vincular unidade eletrônica</Typography>
        )}

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!isUnlink &&
        <DialogContent className={classes.wrapperContent}>
          {renderLinkDevice()}
        </DialogContent >
      }
      {isUnlink && (
        <DialogContent>
          {renderUnlinkDevice()}
        </DialogContent >
      )}

    </Dialog >
  );
};

export default LinkDeviceToVehicleModal;
