import React, { useState, useEffect } from "react";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getMenuStructure } from "./menuStructure";
import { getMenuStructureAdmin } from "./menuStructureAdmin";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import PermissionsGate from "components/PermissionsGate";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  closeSidebar,
  openSidebar,
} from "../../context/LayoutContext";
import auth from '../../services/auth'
import { store, useAppSelector } from "redux/store";
import { clearFilters } from "redux/features/driversMonitoringSlice";
import { clearComparisonPagination } from "redux/features/comparisonSlice";
import { clearBonusFilters, clearPrintData } from "redux/features/bonusFollowSlice";
import { clearVehicleFilters } from "redux/features/vehicleDashboardSlice";
import { clearConsolidatedFilters } from "redux/features/consolidatedSlice";
import { clearLiveMapMenuSelected } from "redux/features/liveMapSlice";
import { useWindowSize } from "../../hooks/useWindowsSize";

auth.me()

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  const pathArray = window.location.pathname.split('/');

  useEffect(function () {
    const bonusFollowSteps = store.getState().bonusFollow.fields.step;
    const driversMonitoringSteps = store.getState().driversMonitoring.fields.step;

    window.addEventListener("resize", handleWindowWidthChange);

    if (pathArray.includes('dashboard') && (bonusFollowSteps === 2 || driversMonitoringSteps === 2)) {
      store.dispatch(clearVehicleFilters())
    }
    if (!pathArray.includes('driversMonitoring')) {
      store.dispatch(clearFilters())
    }
    if (!pathArray.includes('comparison')) {
      store.dispatch(clearComparisonPagination())
    }
    if (!pathArray.includes('bonus') && bonusFollowSteps !== 3 && bonusFollowSteps !== 2) {
      store.dispatch(clearBonusFilters())
    }
    if (!pathArray.includes('dashboard')) {
      store.dispatch(clearVehicleFilters())
    }
    if (!pathArray.includes('consolidated')) {
      store.dispatch(clearConsolidatedFilters())
    }
    if (!pathArray.includes('print')) {
      store.dispatch(clearPrintData())
    }
    if (!pathArray.includes('liveMap') && !pathArray.includes('liveMapDetails')) {
      store.dispatch(clearLiveMapMenuSelected())
    }

    handleWindowWidthChange();

    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const size = useWindowSize()

  return (
    <Drawer
      onMouseEnter={size.mobile ? () => { } : () => openSidebar(layoutDispatch)}
      onMouseLeave={size.mobile ? () => { } : () => closeSidebar(layoutDispatch)}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={size.mobile ? () => toggleSidebar(layoutDispatch) : () => { }}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
      >
        <List data-cy="sideBarItens" className={classes.listMenu}>
          {getMenuStructure().map((link, index) => (
            <SidebarLink
              onClose={size.mobile ? () => toggleSidebar(layoutDispatch) : () => { }}
              key={`sidebarLink${index}`}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
        <PermissionsGate scopes={["can_view_admin_area"]}>
          <>
            <Divider />
            <Grid container alignItems="center">
              <Grid item xs style={{
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(2),
              }}>
                {isSidebarOpened && <Typography
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    color: theme.palette.text.secondary
                  }}>
                  Área Administrativa
                </Typography>}
              </Grid>
            </Grid>
            <List data-cy='sideBarItens' className={classes.listMenu}>
              {getMenuStructureAdmin().map((link, index) => (
                <SidebarLink
                  onClose={size.mobile ? () => toggleSidebar(layoutDispatch) : () => { }}
                  key={`sidebarLink${index}`}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              ))}
            </List>
          </>
        </PermissionsGate>
      </Grid>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
