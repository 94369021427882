import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  FormControlLabel
} from "@material-ui/core";
import { toast } from "react-toastify";
import { format } from "date-fns";
import TableResponsive from "../../components/TableResponsive";

//services
import { getVehiclesByOperation } from "services/vehicle";
import { getTelemetryData } from "services/telemetry";

// components
import Aux from "hoc/auxiliar";
import TelemetryDataSkeleton from "components/Skeletons/TelemetryDataSkeleton";
import PageToolbar from "components/PageToolbar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import { tableColumns } from "./config/tableColumns";

// styles
import useStyles from "./styles";
import { useAppSelector } from "redux/store";
import { useWindowSize } from "../../hooks/useWindowsSize";

export default function TelemetryData() {
  const { currentCustomer, listCustomers } = useAppSelector((state) => state.global.user);
  const { state } = useLocation();
  const classes = useStyles();
  const size = useWindowSize()
  const columns = tableColumns(size);
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";

  const [vehicles, setVehicles] = useState([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [loading, setLoading] = useState({
    vehicles: true,
    data: true,
  });
  const [vehiclesId, setVehiclesId] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: format(new Date(), "yyyy-MM-dd'T'00:00:00XX"),
    finalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });
  const [data, setData] = useState([]);
  const [infoBox, setInfoBox] = useState(false);

  // Responsavel por pegar as datas no componente pageToolbar.
  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: format(initialDate, dateFormatTemplate),
      finalDate: format(finalDate, dateFormatTemplate),
    });
  };

  const getVehicleIdFilter = (selectedVehicle) => {
    let selected = [];
    vehicles.forEach((operation) => {
      operation.vehicles.forEach((ident) => {
        if (ident.id === selectedVehicle[0].id) {
          selected = [{
            identification: ident.currentDevice?.identification,
            id: ident.id,
          }];
        }
      });
    });
    setVehiclesId(selected);
  };

  //Função esta colocada no useEffect. Toda vez que a lista de veiculos ou data forem alterados, irar fazer uma nova busca na api conforme dados selecionados.
  const fetchData = async (vehicleList, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, data: true }));
    try {
      const devices = vehicleList.map((vehicle) => vehicle.identification);
      const response = await getTelemetryData(devices[0], initialDate, finalDate);
      if (response.status !== 200) { 
        throw new Error();
      }
      if (response.data.data) {
        const mappedData = response.data.data.map((row, idx) => {
          return {
            id: idx,
            ...row,
          };
        });
        mappedData.splice(-1)
        setData(mappedData);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar dados de conectividade. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, data: false }));
    }
  };

  const fetchVehicles = useCallback(async () => {
    setLoading((oldState) => ({ ...oldState, vehicles: true }));
    try {
      const canViewAllVehicles = hasPermission({ scopes: ['can_view_telemetrydata_all'] });
      const response = !canViewAllVehicles ? await getVehiclesByOperation(currentCustomer) : await getVehiclesByOperation(listCustomers);

      if (state && response.data.customers) {
        const returnData = response.data.customers;
        const foundIdVehicle = returnData
          .map((o) =>
            o.vehicles.filter((truck) => truck.id === state.vehicleId.id),
          )
          .reduce((last, now) => last.concat(now))
          .reduce((_last, now) => now);
        setVehiclesId([
          {
            identification: foundIdVehicle.currentDevice.identification,
            driverName: foundIdVehicle.currentDriver.driverName,
            id: foundIdVehicle.id,
          },
        ]);
      } else {
        setVehiclesId([]);
      }
      setVehicles(response.data.customers);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, vehicles: false }));
    }
  }, [state, currentCustomer]);

  const handleChangeVisibleInfoBox = () => {
    if (!infoBox) return setInfoBox(true);
  };

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_telemetrydata'] })) {
      fetchVehicles();
    }
  }, [currentCustomer, fetchVehicles]);

  useEffect(() => {
    setLoadingVehicles(vehiclesId.length > 0);
    if (vehiclesId.length > 0) {
      fetchData(vehiclesId, selectedDates.initialDate, selectedDates.finalDate);
    }
  }, [vehiclesId, selectedDates]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_telemetrydata']}>
        <PageToolbar
          handleSelectDate={handleSelectDate}
          selectedDates={selectedDates}
          listData={vehicles}
          selectedData={vehiclesId}
          handleSelectedData={getVehicleIdFilter}
          menuCalendar
          vehiclesMenuButton
          isSingleMode
          initialDate={selectedDates.initialDate}
          finalDate={selectedDates.finalDate}
          detailedSelect
          loading={loading.vehicles}
        />
        {!loadingVehicles && !infoBox ? (
          <Grid container className={classes.containerInfobox}>
            <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    fontFamily="fontFamily"
                    justifyContent="center"
                    fontSize="h3.fontSize"
                    textAlign="center"
                    lineHeight={2}
                    className={classes.infoBox}
                    p={4}
                  >
                    Para gerar as informações da sua frota, selecione um veículo{" "}
                    <strong>
                      clicando no filtro localizado no menu acima.
                    </strong>
                  </Box>
                  <Grid item className={classes.containerButton}>
                    <Button
                      onClick={() => handleChangeVisibleInfoBox()}
                      variant="contained"
                      className={classes.okButton}
                    >
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <Grid container spacing={1} className={classes.container}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Grid item xl={12} xs={12} className={classes.table}>
                  {loading?.data ? 
                    <TelemetryDataSkeleton /> : 
                    <TableResponsive
                      data={data}
                      columns={columns}
                      tableName="list-telemetry"
                      pointer={true}
                      options={{
                        setRowProps: (row, index) => {
                          return { style: { backgroundColor: "white", cursor: 'default', width: '100%' } }
                        },
                      }}
                    />
                  }
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </PermissionsGate>
    </Aux>
  );
}
