import { StatusVehicle } from "helpers/statusVehicle";
import { useState, useEffect, useRef } from "react";
import { store, useAppSelector } from "redux/store";

const URL = process.env.REACT_APP_LINK_WSS + "/web/v2/location/ws/group/vehicle?refresh=5&session_id=";

export const useWebSocketLocationGroupVehicleId = (setLoading, setStatus) => {
  const [points, setPoints] = useState([]);
  const [endpoint, setEndpoint] = useState("");
  const [currentSocket, setCurrentSocket] = useState();
  const { token } = store.getState().global.user;
  const { menuSelected } = useAppSelector((state) => state.liveMap);
  const currentData = useRef([]);

  const closeConnection = () => {
    currentSocket && currentSocket.close(1000, 'Fechando conexão normalmente')
  }
  
  //carregar o sessionID
  useEffect(() => {
    setEndpoint(URL + token);
  }, [token, menuSelected]);

  //Chamar WS sempre atualizando a lista
  useEffect(() => {
    if (menuSelected?.vehicles?.length > 0 && !!token && !!endpoint) {
      setLoading(true);
      const websocket = new WebSocket(endpoint + "&vehicle_ids=" + menuSelected?.vehicles?.map(item => item.id).join());
      setCurrentSocket(websocket)

      websocket.onopen = () => {
        setStatus(null);
        setLoading(true);
      };
      websocket.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          if (response?.data) {
            const orderedPoints = response.data.sort((point1, point2)=>{
              return point1.vehicleId - point2.vehicleId;
            })
            if (JSON.stringify(currentData.current) !== JSON.stringify(orderedPoints)) {
              currentData.current = orderedPoints;
              const pointsNew = StatusVehicle(orderedPoints);
              setPoints(() => pointsNew);
              if (pointsNew.length === 0) {
                setStatus("empty");
              }else{
                setStatus(null)
              }
            }
          } else {
            setStatus("empty");
            websocket.close(1000, 'Fechando conexão normalmente');
          }
          setLoading(false);
        } catch (err) {
          console.log('err', err)
        }
      };
      websocket.onerror = (error) => {
        console.log("WS error: ", error);
        //setStatus("error");
        setLoading(false);
        setTimeout(() => {
          setLoading(true);
        }, 2000)
      };

      return () => {
        websocket.close(1000, 'Fechando conexão normalmente');
        websocket.onmessage = null;
      };
    }
  }, [menuSelected]);

  return {
    points,
    closeConnection
  };
};
