import React from 'react';
import './cardTitle.css';

export const CardTitle = ({ title, titleStyle, ...props }) => {
  return (
    <div
      className={`CardTitle-container`}
      style={titleStyle}
      {...props}
    >
      {title}
    </div>
  );
};

CardTitle.defaultProps = {
  title: 'Título',
};
