import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    borderRadius: "20px",
  },
  legend: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    borderRadius: "20px",
    fontSize: "16px",
    fontWeight: 600,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    lineHeight: "40px",
  },
  listItem: {
    padding: theme.spacing(0.3, 1, 0.3, 1),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  listItemTitle: {
    paddingLeft: theme.spacing(1),
    fontWeight: "bold",
    minWidth: "120px",
  },
  listItemSubTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: "16px",
    fontWeight: 600,
  },
  listItemSubTitle2: {
    paddingLeft: "5px",
    fontSize: "16px",
  },
  logoIconButton: {
    padding: theme.spacing(1),
    borderRadius: "16px",
  },
  logoTooltip: {
    width: "16px",
  },
  listItemIcon: {
    fontSize: "1rem",
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "50%",
  },
  legendTitle: {
    fontWeight: "bold",
    fontSize: 14,
  },
}));
