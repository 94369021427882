import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "450px",
    borderRadius: 28,
    padding: 0,
    /* fix to print */
    "& div > img": {
      position: "absolute",
    }
  },
  header: {
    fontSize: "13px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  subheader: {
    height: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  containerMap: {
    height: "380px",
    padding: 0,
  },
}));
