import React from "react";
import PropTypes from "prop-types";
import "./clusterGroup.css";
import ReactApexChart from "react-apexcharts";
import { optionsClusterGroup } from "./variables";

const ClusterGroup = ({
  latitude,
  longitude,
  height,
  width,
  blue,
  green,
  yellow,
  gray,
  white,
  red,
  onClick,
}) => {
  const series = [blue,green,yellow,gray,white,red];

  return (
    <div className={"ClusterGroup-container"}>
      <ReactApexChart
        lat={latitude}
        lng={longitude}
        width={width ?? 100}
        height={height ?? 100}
        options={optionsClusterGroup}
        series={series}
        onClick={onClick}
        type="donut"
      />
    </div>
  );
};

export default ClusterGroup;

ClusterGroup.propTypes = {
  /**
   * Valor da latitude
   */
  latitude: PropTypes.number,
  /**
   * Valor da longitude
   */
  longitude: PropTypes.number,
  /**
   * onClick
   */
  onClick: () => {},
};

ClusterGroup.defaultProps = {
  blue: 10,
  green: 53,
  yellow: 90,
  gray: 2,
  white: 100,
  red: 34,
};
