import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { EditOutlined, NotInterested, DeleteOutline } from "@material-ui/icons";
import { cpfMask } from "helpers/masks";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onEdit, onDisable, onDelete, size}) => {
  let columns = [
    {
      name: "customerName",
      label: size.mobile ? ' ' : "Empresa",
    },
    {
      name: "name",
      label: "Motorista",
    },
    {
      name: "documentNumber",
      label: "CPF",
      options: {
        customBodyRender: (value) => cpfMask(value),
      },
    },
    {
      name: "activationCode",
      label: "Último código de ativação enviado",
    },
    {
      name: "activatedAt",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          if (!value) {
            return 'Cadastrado'
          }
          return 'Ativo'
        },
      },
    },
    {
      name: "canLink",
      label: "Pode vincular",
      options: {
        customBodyRender: (value) => {
          return value ? "SIM" : "NÃO"
        },
      },
    },
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_edit_drivers', 'can_remove_drivers'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container style={{ minWidth: "130px" }}>
              <PermissionsGate scopes={['can_edit_drivers']}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditOutlined />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_disable_drivers']}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <IconButton
                    onClick={() => onDisable(value)}
                    aria-label="disable"
                    title="Desabilitar"
                  >
                    <NotInterested />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_drivers']}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="disable"
                    title="Excluir"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
