import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import LinkDriversSkeleton from "components/Skeletons/LinkDriversSkeleton";
import TableResponsive from "components/TableResponsive";
import Widget from "components/Widget/Widget";
import { getDriversHistory } from "services/driver";
import { getAssignType } from "helpers/drivers";
import {useWindowSize} from "hooks/useWindowsSize";
import Aux from "hoc/auxiliar";
import { makeColumns } from "./tableColumns";
import useStyles from "./styles";
import { handleAmplitudeEvent } from 'services/amplitude';

function HistoricLinkDrivers() {
  const classes = useStyles();
  const size = useWindowSize();
  const history = useHistory();
  const { state } = useLocation();
  const [ loading, setLoading ] = useState(true);
  const [ id, setId ] = useState();
  const [ selectedRowData, setSelectedRowData ] = useState();
  const [ driversHistory, setDriversHistory ] = useState([]);
  const [ download, setDownload ] = useState({
    link: "",
    fileName: "",
    params: {
      vehicleId: null,
    }
  });

  const mergeFieldsAssignUnassign = (registers) => {
    let list = registers
    list.forEach((vehicle, index) => {
      vehicle.header = {
        init: vehicle.startDate,
        final: vehicle.endDate
      }
      vehicle.operations.forEach((item) => {
        if (!item.operation) {
          return false;
        }
        if (item.operation === 'ASSIGN') {
          list[index].assignBy = `${getAssignType(item.sourceId)} | ${item.userName}`;
        }
        if (item.operation === 'UNASSIGN') {
          list[index].unassignBy = `${getAssignType(item.sourceId)} | ${item.userName}`
        }
      })
    })
    return list
  };

  const fetchDriversHistory = useCallback(async (param) => {
    try {
      setLoading(true)
      const response = await getDriversHistory(param)
      if (response.status !== 200) {
        throw new Error();
      }
      const { registers } = response.data
      if (registers) {
        const model = mergeFieldsAssignUnassign(registers)
        setDriversHistory(model)
      }
      setDownload({
        link: `/vehicles/exportDriverHistory`,
        fileName: `historico-vinculos-motoristas_${format(new Date(), "dd-MM-yyyy_HH-mm")}.xlsx`,
        params: {
          vehicleId: param,
        }
      });
    } catch (error) {
      toast.error("Erro ao buscar histórico de vínculos. Contate o suporte.")
    } finally {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    state && fetchDriversHistory(state.params[4])
  }, [state])

  const editHandler = (params) => {
    handleAmplitudeEvent('Edit Button Clicked');
    history.push({
      pathname: "/gofurther/maintenance/assignDriver",
      state: {
        ...state,
        isEdit: true,
        editData: {
          startDate: params[0] || null,
          endDate: params[1] || null,
          driverName: params[2] || '',
          driverId: params[6] || '',
        }
      }
    });
  };

  const columns = makeColumns({
    handleEdit: editHandler,
    size
  });

  const handleSelectRow = (data, data2) => {
    const rowIndex = data2.dataIndex;
    setId(rowIndex);
    const formatData = [
      driversHistory[rowIndex].startDate,
      driversHistory[rowIndex]?.endDate || null,
      driversHistory[rowIndex].driverName,
      data[3].props.children,
      data[4].props.children,
      driversHistory[rowIndex].status,
      driversHistory[rowIndex].driverId
    ]
    setSelectedRowData(formatData)
  }

  useEffect(() => {
    handleAmplitudeEvent('Link Driver/vehicle Logs Screen Viewed');
  }, [])

  if (loading) return <LinkDriversSkeleton />

  return (
    <Aux>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Grid container item xl={12} xs={12} className={classes.table}>
              <Grid container item lg={10} md={12} sm={12} xs={12}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    <Typography className={classes.titleText}>Veículo: </Typography>
                    <Typography>{state.params[0]}</Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    <Typography className={classes.titleText}>Placa: </Typography>
                    <Typography>{state.params[2]} </Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    {state.params[3]?.driverName ? (
                      <>
                        <Typography className={classes.titleText}>Motorista atual: </Typography>
                        <Typography>{state.params[3].driverName}</Typography>
                      </>
                    ) : <Typography>Sem motorista vinculado.</Typography>}
                  </Paper>
                </Grid>
              </Grid>
              <Grid container alignItems='center' item lg={2} md={3} sm={6} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color={'secondary'}
                  style={{ marginTop: 5 }}
                  className={classes.buttonDetail}
                  onClick={() => {
                    handleAmplitudeEvent('New Link Clicked');
                    history.push({
                      pathname: "/gofurther/maintenance/assignDriver",
                      state,
                    });
                  }}
                >
                  Novo vínculo
                </Button>
              </Grid>
            </Grid>
            <Widget disableWidgetMenu title="Histórico de vínculos de motorista">
              <Grid item xl={12} xs={12} className={classes.table}>
                <TableResponsive
                  pointer={true}
                  columns={columns}
                  data={driversHistory}
                  download={download}
                  tableName="historic-link-drivers"
                  options={{
                    onRowClick: (row, index) => { handleSelectRow(row, index) },
                    rowsPerPage: size.mobile ? 10 : 100,
                    setRowProps: (row, index) => { return index === id ? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
                  }}
                />
              </Grid>
            </Widget>
          </Box>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box >
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.buttonDetail}
                    onClick={(e) => {
                      handleAmplitudeEvent('Backward Button Clicked')
                      history.goBack()
                    }}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
              {size.mobile &&
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => editHandler(selectedRowData)}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Editar
                    </Button>
                  </Box>
                </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Aux>
  )
}

export default HistoricLinkDrivers;
