import React, { useState, useEffect } from "react";
import {
  Grid, 
  Paper, 
  Checkbox, 
  Button, 
  FormControlLabel, 
  Typography
} from "@material-ui/core";
import Icon from "@mdi/react";
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";

// Styles
import colors from '../../themes/gobrax'
import useStyles from "./style";

export default function TransferList({
  handleSelectedItems,
  availableVehicles,
  allUnavailableVehicles,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);

  const [selectable, setSelectable] = useState([]);
  const [selected, setSelected] = useState(allUnavailableVehicles);

  const selectableChecked = intersection(checked, selectable);
  const selectedChecked = intersection(checked, selected);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllSelected = () => {
    setSelected(selected.concat(selectable));
    setSelectable([]);
  };

  const handleCheckedSelected = () => {
    setSelected(selected.concat(selectableChecked));
    setSelectable(not(selectable, selectableChecked));
    setChecked(not(checked, selectableChecked));
  };

  const handleCheckedSelectable = () => {
    setSelectable(selectable.concat(selectedChecked));
    setSelected(not(selected, selectedChecked));
    setChecked(not(checked, selectedChecked));
  };

  const handleAllSelectable = () => {
    setSelectable(selectable.concat(selected));
    setSelected([]);
  };

  useEffect(() => {
    handleSelectedItems(selected);
  }, [handleSelectedItems, selected]);

  useEffect(() => {
    setSelected(allUnavailableVehicles);
  }, [allUnavailableVehicles]);

  useEffect(() => {
    availableVehicles.length && setSelectable(availableVehicles)
  }, [availableVehicles])

  const customList = (items) => {
    return (
    <Paper className={classes.paper}>
        {items.map((value, idx) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <div style={{
              display: 'flex',
              margin: 10}} key={idx}>
              <FormControlLabel
              control={
                <Checkbox
                    name={`checkbox-${idx}`}
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    onClick={handleToggle(value)}
                    inputProps={{ "aria-labelledby": labelId }}
                    style={{color: colors.palette.secondary.main }}
                  />
              }
              label={`${value.plate} - ${value.identification}`}
            />
            </div>
          );
        })}
    </Paper>
  )};

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid>
        <Typography variant="h6" className={classes.labelTitle}>
          Disponíveis
        </Typography>
      <Grid item>{customList(selectable)}</Grid>
      </Grid>
      <Grid item className={classes.buttonList}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Button
            size="small"
            className={classes.button}
            onClick={handleAllSelected}
            disabled={selectable && selectable.length === 0}
            aria-label="selecionar todos"
          >
            <Icon
              path={mdiChevronDoubleRight}
              className={classes.icon} color={colors.palette.black}
              size={1.5}
            />
          </Button>
          <Button
            size="small"
            className={classes.button}
            onClick={handleCheckedSelected}
            disabled={selectableChecked.length === 0}
            aria-label="selecionar"
          >
            <Icon path={mdiChevronRight} className={classes.icon} color={colors.palette.black} size={1.5} />
          </Button>
          <Button
            size="small"
            className={classes.button}
            onClick={handleCheckedSelectable}
            disabled={selectedChecked.length === 0}
            aria-label="deselecionar"
            
          >
            <Icon path={mdiChevronLeft} className={classes.icon} color={colors.palette.black} size={1.5} />
          </Button>
          <Button
            size="small"
            className={classes.button}
            onClick={handleAllSelectable}
            disabled={selectable && selected.length === 0}
            aria-label="deselecionar todos"
          >
            <Icon
              path={mdiChevronDoubleLeft}
              className={classes.icon} color={colors.palette.black}
              size={1.5}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Typography variant="h6" className={classes.labelTitle}>
          Veículos selecionados
        </Typography>
        <Grid item>{customList(selected)}</Grid>
      </Grid>
    </Grid>
  );
}

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
