import React, { useState } from "react";
import PropTypes from "prop-types";
import "./popoverInfo.css";
import { ArrowDropDown, ArrowDropUp, InfoOutlined } from "@material-ui/icons";
import { handleAmplitudeEvent } from "services/amplitude";

const PopoverInfo = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="PopoverInfo-container">
      <div
        onClick={() => {
          handleAmplitudeEvent("Chart Info Clicked");
          setOpen(!open);
        }}
        className={[`PopoverInfo-button`, `PopoverInfo-button-${open}`].join(
          " ",
        )}
      >
        <span className={`PopoverInfo-button-item`}>
          <InfoOutlined className={`PopoverInfo-button-icon-info`} />
        </span>
        <span className={`PopoverInfo-button-item`}>{title}</span>
        <span className={`PopoverInfo-button-item`}>
          {!open ? <ArrowDropDown /> : <ArrowDropUp />}
        </span>
      </div>
      <div className={[`PopoverInfo-modal-${open}`].join(" ")}>{children}</div>
    </div>
  );
};

export default PopoverInfo;

PopoverInfo.propTypes = {
  /**
   * Titulo do popover
   */
  title: PropTypes.string.isRequired,
  /**
   * Conteúdo do popover
   */
  title: PropTypes.any.isRequired,
};

PopoverInfo.defaultProps = {
  title: "Clique e saiba como utilizar o gráfico",
  children: null,
};
