import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Aux from "../../hoc/auxiliar";
import { makeStyles } from "@material-ui/core/styles";
import {useWindowSize} from "../../hooks/useWindowsSize";

const ListVehiclesCompareSkeleton = () => {
  const classes = useStyles();
  const size = useWindowSize()
  return (
    <Aux>
      <Grid container spacing={1}>
        <Grid item xl={size.grid4} lg={size.grid4} md={size.grid4} sm={size.grid4} xs={size.grid4}>
            <Skeleton variant={'rect'} height={45} className={classes.bordered}  />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton 
            variant="rect" 
            height={650} 
            className={classes.bordered} 
          />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  bordered: {
    borderRadius: "22px",
  },
  tableButtons: {
    marginTop: theme.spacing(1),
  },
  buttons: { 
    marginBottom: "10px", 
    display: "flex", 
    justifySelf: "flex-end",
    borderRadius: "10px"
  }
}));

export default ListVehiclesCompareSkeleton;
