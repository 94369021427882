import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPineTree } from "@mdi/js";
import { toast } from "react-toastify";
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";

import { getCo2Tax } from "services/fleetPerformance";
import { getVehiclesByOperation } from "services/vehicle";

import Aux from "hoc/auxiliar";
import Co2Skeleton from "components/Skeletons/Co2Skeleton";
import PageToolbar from "components/PageToolbar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { orderVehiclesByCrescent } from "helpers/vehicles";

import useStyles from "./styles";
import { useTheme } from "@material-ui/core";
import { useAppSelector } from "redux/store";
import { makeInitialAndFinalDate } from "helpers/makeInitialAndFinalDate";
import { valueToPositiveFloat } from "helpers/functions";
import { handleAmplitudeEvent } from "services/amplitude";

const data = [{ name: "Group A", value: 400 }];

const Co2Emission = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const theme = useTheme();
  const classes = useStyles();
  const today = new Date();
  const currentMonth = today.getMonth();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gasEmission, setGasEmission] = useState({
    maxTrees: 0,
    maxCo2TonTotal: 0,
  });
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [calendarValue, setCalendarValue] = useState(currentMonth);
  const [selectedDates, setSelectedDates] = useState(
    makeInitialAndFinalDate(currentMonth),
  );
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [currentYear, setCurrentYear] = useState({ value: today.getFullYear(), label: today.getFullYear() })

  const fetchVehicles = async () => {
    setIsLoading(true);
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      const orderedVehicles = orderVehiclesByCrescent(response.data?.customers);
      setVehicles(orderedVehicles);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    }
    setIsLoading(false);
  };

  const handleSelectDate = (option) => {
    const indexMonth = option.value;
    setCalendarValue(indexMonth);
    const dates = makeInitialAndFinalDate(indexMonth);
    setSelectedDates(dates);
    handleAmplitudeEvent('Filter Updated', { label: "Periodo", value: `${dates.initialDate} - ${dates.finalDate}` })
  };

  const handleSelectedVehicles = (selected) => {
    handleAmplitudeEvent('Filter Updated', { label: "Frota", value: selected.map(item => item?.identification + ' - ' + item?.plate).join(", ") })
    setSelectedVehicles(selected);
  };

  const handleYear = (selected) => {
    setCurrentYear(selected)
    handleAmplitudeEvent('Filter Updated', { label: "Ano", value: selected.value })
  }

  const fetchGasEmissionData = async (vehicleList, initialDate, finalDate) => {
    const startDate = new Date(initialDate);
    const finishDate = new Date(finalDate);
    const year = currentYear.value;
    const monthStartDate = (startDate.getMonth()).toString().padStart(2, "0");
    const dayStartDate = startDate.getDate().toString().padStart(2, "0");
    const monthFinishDate = (finishDate.getMonth()).toString().padStart(2, "0");
    const dayFinishDate = finishDate.getDate().toString().padStart(2, "0");
    try {
      //TMP calc
      setLoading(true)
      const response = await getCo2Tax(
        vehicleList,
        format(
          new Date(year, monthStartDate, dayStartDate),
          "yyyy-MM-dd'T'HH:mm:ssXX"
        ),
        format(
          new Date(year, monthFinishDate, dayFinishDate),
          "yyyy-MM-dd'T'23:59:ssXX"
        ),
      );
      const { compensation } = response.data.data
      const co2Tax = {
        maxCo2TonTotal: compensation.co2,
        maxTrees: compensation.trees,
      };
      setGasEmission(co2Tax);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  };

  const RenderLabelChart = ({ viewBox, value }) => {
    const { cx, cy } = viewBox;
    return (
      <text
        x={cx}
        y={cy}
        fill="#3d405c"
        textAnchor="middle"
        dominantBaseline="central"
        data-cy="textValorGrafico"
      >
        <tspan
          alignmentBaseline="middle"
          fontSize="26"
          fill={theme.palette.primary.contrastText}
        >
          {value}
        </tspan>
      </text>
    );
  };

  const setOperationVehicles = () => {
    if (selectedOperations) {
      const allVehiclesOperationArray = [];
      vehicles.map(
        (operation) =>
          selectedOperations.includes(operation.id) &&
          operation.vehicles.map((vehicle) => {
            allVehiclesOperationArray.push({
              identification: vehicle.currentDevice.identification,
              id: vehicle.id,
            });
          }),
      );
      setSelectedVehicles(allVehiclesOperationArray);
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_co2'] })) {
      fetchVehicles();
    }
  }, []);

  useEffect(() => {
    if (selectedVehicles.length === 0) {
      setGasEmission(null);
    }
    if (selectedVehicles.length > 0) {
      const vehicleIds = selectedVehicles.map(
        (vehicle) => vehicle.id,
      );
      fetchGasEmissionData(
        vehicleIds,
        selectedDates.initialDate,
        selectedDates.finalDate,
      );
    }
  }, [selectedVehicles, selectedDates, currentYear]);

  useEffect(() => {
    handleAmplitudeEvent('Co2 Emission Screen Viewed');
  }, [])

  useEffect(() => {
    setOperationVehicles();
  }, [selectedOperations]);

  if (isLoading) {
    return <Co2Skeleton />;
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_co2']}>
        <PageToolbar
          handleSelectDate={handleSelectDate}
          selectedDates={selectedDates}
          listData={vehicles}
          selectedData={selectedVehicles}
          handleSelectedData={handleSelectedVehicles}
          isEmission
          vehiclesMenuButton
          calendarMonthValue={calendarValue}
          isCalendarMonthDisabled={selectedVehicles.length === 0}
          handleYear={handleYear}
          currentYear={currentYear}
        />
        <Grid container spacing={2} data-cy="divContent">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography
              component="h1"
              variant="h5"
              style={{ fontWeight: "bold" }}
              data-cy="h1EmissaoCO2"
            >
              Emissão de CO²
            </Typography>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography data-cy="pSelecioneFrotaMes">
              Selecione a frota e o mês para gerar informações.
            </Typography>
          </Grid>
          {loading && (
            <Grid className={classes.wrapperLoading}>
              <Typography className={classes.textLoading}>
                Carregando informações...
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: "100%", position: "relative", zIndex: -1 }}
            data-cy="divImagem"
          >
            <Paper className={classes.paperContainer}>
              <div className={classes.chartContainer} data-cy="divComponenteInformacao" >
                <div className={classes.chart}>
                  <div className={classes.iconChart} data-cy="divPineIcon">
                    <Icon
                      path={mdiPineTree}
                      title="Vehicle"
                      color="white"
                      size={2}
                    />
                  </div>
                  <div className={classes.responsiveContainer} data-cy="divGrafico">
                    <ResponsiveContainer>
                      <PieChart height={300}>
                        <Pie
                          data={data}
                          innerRadius={70}
                          outerRadius={90}
                          paddingAngle={5}
                          startAngle={90}
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={theme.palette.primary.contrastText}
                            />
                          ))}
                          <Label
                            content={
                              <RenderLabelChart
                                value={valueToPositiveFloat(gasEmission?.maxTrees, 2)}
                              />
                            }
                            offset={0}
                            position="center"
                          />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className={classes.legendContainer} data-cy="divCO2Toneladas">
                  <Typography className={classes.labelCo2}>
                    {valueToPositiveFloat(gasEmission?.maxCo2TonTotal, 2)} CO2
                  </Typography>
                  <Typography>TONELADAS</Typography>
                </div>
                <div data-cy="divTextoDescricao">
                  <Typography align="justify">
                    Sua frota emitiu no periodo selecionado aproximadamente{" "}
                    {valueToPositiveFloat(gasEmission?.maxCo2TonTotal, 2)} toneladas de CO²
                    no meio ambiente.
                  </Typography>
                  <Typography align="justify">
                    Para compensar essa emissão de carbono será necessário o plantio
                    de aproximadante {valueToPositiveFloat(gasEmission?.maxTrees, 2)}{" "}
                    árvores.
                  </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default Co2Emission;
