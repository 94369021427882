import { alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import colors from '../../themes/gobrax'

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.h4.fontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px'
  },
  notificationCollapseSubTitle: {
    fontSize: 16,
    color: colors.palette.text.title,
    display: 'flex',
    maxWidth: '220px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    flexWrap: 'inherit',
    margin: '15px 0 10px'
  },
  logoHeader: {
    width: '40px',
    marginLeft: '3px'
  },
}));
