import api from "./api";
import { convertStringDate } from "helpers/functions";

const vehiclesUrl = (vehicles = "") => {
  return vehicles ? 
    Array.isArray(vehicles) ? vehicles.filter(x => x).join() : vehicles
  : "";
};

export const getFuelCheckSingle = async (vehicleId, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/web/v2/telemetry/fuelCheck/consultSingleVehicle/${vehicleId}?startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFuelCheckMultiple = async (vehicles, startDate, endDate) => {
  try {
    const partialUrl = vehiclesUrl(vehicles);
    const convertedDates = convertStringDate(startDate, endDate);
    let params = `vehicles=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`;
    const response = await api.get(`/web/v2/telemetry/fuelCheck/consultMultipleVehicles/?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};