import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Grid } from "@material-ui/core";
import { DeleteOutline as DeleteIcon, EditOutlined as EditIcon, RemoveRedEyeOutlined as EyeIcon } from "@material-ui/icons";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit ,size}) => {
  let columns = [
    {
      name: "name",
      label: size.mobile ? " " : "Empresa",
    },
    {
      name: "document",
      label: "CNPJ",
    },
  ];
  if (!size.mobile && hasPermission({scopes: ['can_edit_customers', 'can_remove_customers', 'can_view_customers_without_edit']})) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        setCellProps: () => ({
          style: {
            width: 200
          }
        }),
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_customers']}>
                <Grid item xs={4}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_customers']}>
                <Grid item xs={4}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_customers_without_edit']}>
                <Grid container xs={4}>
                  <IconButton
                    aria-label="view-customer"
                    title="Visualizar cliente"
                    component={Link}
                    to={`/gofurther/customers/view-customer/${value}`}
                  >
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
