import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Skeleton } from "@material-ui/lab";
import Map from "components/Map";
import FleetChart from "components/FleetChart";
import { ModalTitle } from '../../atoms/modalTitle/modalTitle';
import './modalDieselControl.css';
import { handleAmplitudeEvent } from 'services/amplitude';

export const ModalDieselControl = ({
  chartData,
  handleModal,
  locations,
  loadingModalChart,
  loadingModalMap,
  showModal,
}) => {
  const handleClose = () => {
    handleAmplitudeEvent('Chart Modal Closed');
    handleModal(false);
  };
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      className={`ModalDieselControl-container`}
      PaperProps={isMobile ? {
        style: {
          padding: 4,
          maxWidth: "100%"
        }
      } : {
        style: {
          borderRadius: "50px",
          overflowY: "unset",
        }
      }}
      open={showModal}
      onClose={handleModal}
      fullWidth={true}
      fullScreen={isMobile}
      scroll={"body"}
      maxWidth="lg"
      aria-labelledby="Gráfico"
      aria-describedby="Combustível, RPM, Altitude e Velocidade"
    >
      <IconButton
        className={`ModalDieselControl-closeButton`}
        onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={`ModalDieselControl-content`} >
        <Grid
          container
          spacing={3}
          data-cy="allElements"
        >
          {!loadingModalMap && !loadingModalChart && <ModalTitle title="Detalhes sobre o abastecimento " />}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {loadingModalChart || !chartData ? (
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={`ModalDieselControl-loadingText`}>
                    Carregando...
                  </Typography>
                  <Skeleton width="100%" height={250} />
                </Grid>
              </Grid>
            ) : (
              <FleetChart
                loading={loadingModalChart}
                data={chartData}
                tooltip={true}
                margin={20}
                chartId="fuel-chart"
              />
            )}
            {loadingModalMap || !locations ?
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={`ModalDieselControl-loadingText`}>
                    Carregando...
                  </Typography>
                  <Skeleton width="100%" height={250} />
                </Grid>
              </Grid> : <Map data={locations} singlePoint={true} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ModalDieselControl.propTypes = {
  /**
    * Props descrição
    */
  props: PropTypes.object,
};

ModalDieselControl.defaultProps = {
  props: null,
};
