import React, { useState } from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import classnames from "classnames";
import { useTheme, withStyles } from "@material-ui/styles";
import Rating from "@material-ui/lab/Rating";

// context
import { useLayoutState } from "../../context/LayoutContext";

// styles
import useStyles from "./styles";
import { makeValueStars } from "helpers/functions";

const Pressure = (props) => {
  const { data, print, throttlePressureScore, penalty, pressureGasPedal, hasThrottlePressureScore } = props;
  const { lowPressure, medPressure, higPressure } = data;

  const hasPressureGasPedalConfig = pressureGasPedal && !!pressureGasPedal[0].low_pressure && !!pressureGasPedal[0].mid_pressure && !!pressureGasPedal[0].high_pressure
  let theme = useTheme();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [open, setOpen] = useState(false)

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.gray.extraLight,
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid ${theme.palette.gray.default}`,
      fontFamily: theme.typography.fontFamily,
    },
  }))(Tooltip);

  return (
    <Paper onClick={() => {
      if (open) setOpen(false)
    }} elevation={0} className={classnames(classes.root, props.className)}>
      <Grid
        container
        spacing={2}
        className={classes.containerRelative}
        alignItems={!print ? "flex-start" : "center"}
      >
        {/* tooltip */}
        {!print && (
          <Box
            className={classnames(classes.containerAbsolute, {
              [classes.containerShift]: layoutState.isSidebarOpened,
            })}
          >
            <HtmlTooltip
              open={open}
              onClick={() => setOpen(!open)}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <Typography color="inherit">Ranges</Typography>
                  <Brightness1Icon
                    style={{
                      color: theme.palette.success.main,
                      fontSize: "1.2rem",
                    }}
                  />
                  <b>{hasPressureGasPedalConfig ? ("de 0% a " + pressureGasPedal[0].low_pressure + "%" ) : "de 0% a 60%"}</b>
                  <br />
                  <Brightness1Icon
                    style={{
                      color: theme.palette.warning.main,
                      fontSize: "1.2rem",
                    }}
                  />
                  <b>{hasPressureGasPedalConfig ? ("de " + (Number(pressureGasPedal[0].low_pressure) + 1) + "% a " + Number(pressureGasPedal[0].mid_pressure) + "%" ) : "de 61% a 70%"}</b>
                  <br />
                  <Brightness1Icon
                    style={{
                      color: theme.palette.error.main,
                      fontSize: "1.2rem",
                    }}
                  />
                  <b>{hasPressureGasPedalConfig ? ("de " + (Number(pressureGasPedal[0].mid_pressure) + 1) + "% a " + Number(pressureGasPedal[0].high_pressure) + "%" ) : "de 71% a 100%"}</b>
                  <br />
                </React.Fragment>
              }
            >
              <InfoOutlinedIcon style={{ color: theme.palette.gray.light }} />
            </HtmlTooltip>
          </Box>
        )}
        {/* lowPressure */}
        <Grid item xs={4} >
          {lowPressure && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.barContainer}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.success.main,
                  opacity: 0.2,
                  height: `${lowPressure.percentage > 0 && lowPressure.percentage < 15
                      ? 85
                      : 100 - lowPressure.percentage
                    }%`,
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.success.main,
                  height: `${lowPressure.percentage > 0 && lowPressure.percentage < 15
                      ? 15
                      : lowPressure.percentage
                    }%`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.legendText}
                >
                  <Typography variant="h5" className={classes.textBold}>
                    {lowPressure.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* medPressure */}
        <Grid item xs={4} >
          {medPressure && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.barContainer}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.warning.main,
                  opacity: 0.2,
                  height: `${medPressure.percentage > 0 && medPressure.percentage < 15
                      ? 85
                      : 100 - medPressure.percentage
                    }%`,
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.warning.main,
                  height: `${medPressure.percentage > 0 && medPressure.percentage < 15
                      ? 15
                      : medPressure.percentage
                    }%`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.legendText}
                >
                  <Typography variant="h5" className={classes.textBold}>
                    {medPressure.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* higPressure */}
        <Grid item xs={4}>
          {higPressure && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.barContainer}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.error.main,
                  opacity: 0.2,
                  height: `${higPressure.percentage > 0 && higPressure.percentage < 15
                      ? 85
                      : 100 - higPressure.percentage
                    }%`,
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.error.main,
                  height: `${higPressure.percentage > 0 && higPressure.percentage < 15
                      ? 15
                      : higPressure.percentage
                    }%`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.legendText}
                >
                  <Typography variant="h5" className={classes.textBold}>
                    {higPressure.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Legend */}
        <Grid item xs={12}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <Brightness1Icon
                style={{ color: theme.palette.success.main }}
                className={classes.listItemIcon}
              />
              <Typography variant="caption" className={classes.listItemTitle}>
                Ideal
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Brightness1Icon
                style={{ color: theme.palette.warning.main }}
                className={classes.listItemIcon}
              />
              <Typography variant="caption" className={classes.listItemTitle}>
                Atenção
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Brightness1Icon
                style={{ color: theme.palette.error.main }}
                className={classes.listItemIcon}
              />
              <Typography variant="caption" className={classes.listItemTitle}>
                Crítico
              </Typography>
            </ListItem>
          </List>
        </Grid>

        {/* Stars here */}
        {penalty && hasThrottlePressureScore && <Grid item xs={12}>
          <Box textAlign="center">
            <Tooltip title={`Nota: ${throttlePressureScore}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(throttlePressureScore)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>}
      </Grid>
    </Paper>
  );
};
export default Pressure;
