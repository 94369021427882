import React, { useEffect, useState } from "react"
import { Box, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import BackButton from "components/BackButton";
import FormRoleSkeleton from "components/Skeletons/FormRoleSkeleton";
import TableResponsive from "components/TableResponsive/Table";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Widget from "components/Widget/Widget";

import { useWindowSize } from "hooks/useWindowsSize";
import Aux from "hoc/auxiliar";

import { getVehicleProfileHistory } from "services/profiles";
import { getBrands, getModels } from "services/vehicle";

import { makeColumns } from "./tableColumns";

import useStyles from "./styles";

const HistoricProfiles = () => {
  const { modelId } = useParams()
  const classes = useStyles()
  const size = useWindowSize()
  const columns = makeColumns({size});

  const [groupHistories, setGroupHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ models, setModels ] = useState(null);
  const [ brands, setBrands ] = useState(null);

  const fetchData = async () => {
    try {
      const responseBrands = await getBrands();
      if (responseBrands.status !== 200) {
        throw new Error(responseBrands);
      }
      setBrands(responseBrands.data?.data?.brands || []);
      
      const responseModels = await getModels();
      if (responseModels.status !== 200) {
        throw new Error(responseModels);
      }
      setModels(responseModels.data?.data?.models || []);
    } catch (err) {
      console.log(err);
      toast.error(
        "Erro ao carregar lista de marcas e perfis. Entre em contato com o suporte!",
      );
    }
  };
  
  const fetchProfileHistory = async () => {
    try {
      setLoading(true);
      const response = await getVehicleProfileHistory(modelId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      const profileHistories = response.data?.data?.profileHistories;
      const rpmLabel = {
        whiteRangeStart: 'Branca',
        extraEconomicRangeStart: 'Início faixa verde',
        economicRangeStart: 'Final faixa verde',
        powerRangeStart: 'Acelerando acima do verde',
        engineBrakeRangeStart: 'Freio motor',
        redRangeStart: 'Vermelha',
        redRangeEnd: 'Final vermelha',
      };
      const onMapHistoriesData = profileHistories?.map((history) => {
        if (history?.historyType === 'CREATION') {
          return {
            modification_type: 'Criação do perfil',
            modificationData: '',
            user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
            datetime: moment(history.createdAt).format('DD/MM/yyyy HH:mm'),
          };
        }

        return history?.changes?.map((change) => {
          if (change.field.includes('End') && change.field !== 'redRangeEnd') {
            return null;
          }
          if (change.field === "modelId") {
            const fromModel = models.find(item => item.id === change.from);
            const toModel = models.find(item => item.id === change.to);
            return {
              modification_type: 'Tipo do perfil modificado',
              modification: `${fromModel?.name || ''} | Alterado para: ${toModel?.name || ''}`,
              user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
              datetime: moment(history.createdAt).format('DD/MM/yyyy HH:mm'),
            };
          }
  
          if (change.field === "brandId") {
            const fromBrand = brands.find(item => item.id === change.from);
            const toBrand = brands.find(item => item.id === change.to);
            return {
              modification_type: 'Marca modificada',
              modification: `${fromBrand?.name || ''} | Alterado para: ${toBrand?.name || ''}`,
              user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
              datetime: moment(history.createdAt).format('DD/MM/yyyy HH:mm'),
            };
          }
          return {
            modification_type: 'Faixa de RPM modificada',
            modification: `${rpmLabel[change.field]} ${change?.from || ''} | Alterado para: ${change?.to || ''}`,
            user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
            datetime: moment(history.createdAt).format('DD/MM/yyyy HH:mm'),
          };
        });
      }).flat().filter(item => item);
      
      setGroupHistories(onMapHistoriesData);

    } catch (err) {
      console.log('error', err)
      toast.error(
        "Erro ao carregar histórico."
      );
    } finally {
      setLoading(false);
    }
  };  
  
  useEffect(() => {
    modelId && hasPermission({scopes: ['can_view_history_profile']}) && fetchData();
  }, [modelId]);

  useEffect(() => {
    brands && models && fetchProfileHistory();
  }, [brands, models]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_history_profile']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
          <Widget disableWidgetMenu title="Histórico de perfis de veículos">
              {loading ? (
                <FormRoleSkeleton />
              ) : (
                <TableResponsive
                  columns={columns}
                  data={groupHistories}
                  tableName="historic-profiles"
                />
              )}
            </Widget>
            <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  )
}

export default HistoricProfiles