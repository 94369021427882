import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {Cancel, CheckCircle} from "@material-ui/icons";
import api from "../../services/api";

const PasswordChecklist = ({ password, passwordConfirm, setReady, passwordOld,passwordOldForm,identity,setLoading }) => {
  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false) // uppercase letter
  const [containsLL, setContainsLL] = useState(false) // lowercase letter
  const [containsN, setContainsN] = useState(false) // number
  const [containsSC, setContainsSC] = useState(false) // special character
  const [contains8C, setContains8C] = useState(false) // min 8 characters
  const [passwordMatch, setPasswordMatch] = useState(false) // passwords match
  const [passwordMatchOld, setPasswordMatchOld] = useState(false) // passwords match OLD
  const [passwordMatchOLDMatch, setPasswordMatchOLDMatch] = useState(false) // passwords match OLD
  const delay = 1

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validatePassword = () => {
    // has uppercase letter
    if (password.toLowerCase() != password) setContainsUL(true)
    else setContainsUL(false)

    // has lowercase letter
    if (password.toUpperCase() != password) setContainsLL(true)
    else setContainsLL(false)

    // has number
    if (/\d/.test(password)) setContainsN(true)
    else setContainsN(false)

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password)) setContainsSC(true)
    else setContainsSC(false)

    // has 8 characters
    if (password.length >= 8) setContains8C(true)
    else setContains8C(false)

    // passwords match
    if (password !== '' && password === passwordConfirm) setPasswordMatch(true)
    else setPasswordMatch(false)


    // passwords match Old
    if (passwordOldForm && password !== '' && password !== passwordOld) setPasswordMatchOld(true)
    else setPasswordMatchOld(false)
    
    // all validations passed
    if (
      containsUL &&
      containsLL &&
      containsN &&
      containsSC &&
      contains8C &&
      passwordMatch &&
      (passwordOldForm ? passwordMatchOld : true) &&
      (passwordOldForm ? passwordMatchOLDMatch : true)

    )
      setReady(true)
    else setReady(false)
  }

  useEffect(()=>{
    setReady(false)
    setPasswordMatchOLDMatch(false)
    let timer1 = setTimeout(async () => {
      if(passwordOld && passwordOld.length > 0){
        if(setLoading) setLoading(true)
        try{
          await api.post(`/web/v2/authenticator/auth/checkPass`, {
            pass: passwordOld,
            id: identity
          });
          if(setLoading) setLoading(false)
          setPasswordMatchOLDMatch(true)
        }catch (e) {
          if(setLoading) setLoading(false)
          setPasswordMatchOLDMatch(false)
        }
      }
    }, delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  },[passwordOld])

  useEffect(() => {
    validatePassword()
  }, [password, passwordConfirm, validatePassword,passwordOld])
  
  // labels and state boolean corresponding to each validation
  const mustContainData = passwordOldForm? [
    ['1 caractere maiúsculo.', containsUL],
    ['1 caractere minúsculo.', containsLL],
    ['1 numeral.', containsN],
    ['1 caractere especial. (! @ # $ % &*)', containsSC],
    ['Mínimo de 8 caracteres.', contains8C],
    ['“Senha” e “Confirmar senha” precisam ser iguais.', passwordMatch],
    ['“Senha Atual” precisa ser verdadeira.', passwordMatchOLDMatch],
    ['“Senha” e “Confirmar senha” não podem ser iguais a senha atual.', passwordMatchOld]
  ]:[
    ['1 caractere maiúsculo.', containsUL],
    ['1 caractere minúsculo.', containsLL],
    ['1 numeral.', containsN],
    ['1 caractere especial. (! @ # $ % &*)', containsSC],
    ['Mínimo de 8 caracteres.', contains8C],
    ['“Senha” e “Confirmar senha” precisam ser iguais.', passwordMatch],
  ]
  
  return (
    <Grid item>
      {mustContainData.map((o, i) => (
        <Grid container key={'checklist-' + i}>
          <Grid item xl={1} lg={1} md={1} sm={2} xs={2} >
            {o[1] ? <CheckCircle style={{color:'#2e7d32'}} /> : <Cancel color={'error'} />}
          </Grid>
          <Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
            <Typography marginLeft={1} align="left">
              {o[0]}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default PasswordChecklist
